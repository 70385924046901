import { NotificationKind } from 'carbon-components-react';
import { createStore } from 'effector';
import { loadNotificationsEV } from './events';

export interface Notification {
  code: string;
  content: string;
  level: NotificationKind;
  translations: NotificationTranslation[];
}

export interface NotificationTranslation {
  [key: string]: string;
}

interface PortalNotificationsStore {
  notifications?: Notification[];
}

const defaultState: PortalNotificationsStore = {
  notifications: [],
};

export const $portalNotificationsStore = createStore<PortalNotificationsStore>(defaultState).on(
  loadNotificationsEV,
  (state, payload) => ({ ...state, notifications: payload })
);
