import { Modal } from 'carbon-components-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PortalModal from '../PortalModal';

export const RenewalRequest = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const refSpan = useRef<HTMLSpanElement>(null);
  const [intervalTime, setIntervalTime] = useState<NodeJS.Timeout>();

  const countdownTimeStart = (timeIsOver: number) => {
    const countDownDate = timeIsOver * 1000;

    // Update the count down every 1 second
    const timerId = setInterval(function () {
      // Get todays date and time
      const now = new Date().getTime();

      // Find the distance between now an the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      // const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      if (refSpan.current) {
        refSpan.current.innerHTML = minutes + ' : ' + (seconds < 10 ? '0' + seconds : seconds);
      }

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(timerId);

        if (refSpan.current) {
          refSpan.current.innerHTML = t('Time is over');
        }
      }
    }, 1000);

    setIntervalTime(timerId);
  };

  const handleRenewalRequest = () => {};

  useEffect(() => {
    countdownTimeStart(1641823033);

    if (!openModal) {
      intervalTime && clearInterval(intervalTime);
    }
  }, []);

  return (
    <>
      {openModal && (
        <PortalModal>
          <Modal
            id="renewal-request"
            size="xs"
            modalHeading="RENEWAL REQUEST"
            primaryButtonText={t('YES')}
            onRequestSubmit={handleRenewalRequest}
            secondaryButtonText={t('NO')}
            onSecondarySubmit={() => setOpenModal(false)}
            open
            onRequestClose={() => setOpenModal(false)}
            className="web-epak__modal"
          >
            <div className="web-epak__modal-timer">
              {t('Logout through')}: <span ref={refSpan}></span>
            </div>
          </Modal>
        </PortalModal>
      )}
    </>
  );
};
