import { createEffect } from 'effector';
import api from 'services/api';

export const uploadFileFX = createEffect(
  async ({
    application_uuid,
    application_document,
    file,
  }: {
    application_uuid: string | null;
    application_document: string | undefined;
    file: File;
  }) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      application_document
        ? `/api-applications/api/v2/applications/${application_uuid}/document_file/${application_document}`
        : `/api-applications/api/v2/applications/${application_uuid}/document_file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    const data = { ...response.data.data };

    delete data.additional_options;

    return data;
  }
);
