import { Button, Loading, Modal } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Emitter } from 'utils/event-emitter';
import { SHOW_HELPDESK_MODAL } from 'utils/event-emitter/constants';
import { useStore } from 'effector-react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { RHFInput } from 'components/input/RHFInput';
import { AnyObject } from 'yup/lib/types';
import PortalModal from 'components/PortalModal';
import { FormFieldController, RadioButton } from '@epak/ui-components';
import {
  authHelpDeskNumberFX,
  fetchUserProfileFX,
  getHelpDeskReasonsFX,
} from '../../store/user/effects';
import styles from './HelpDeskModal.module.scss';

export const HelpDeskModal = () => {
  const { t } = useTranslation();
  const form = useForm();
  const [openModal, setOpenModal] = useState(false);
  const [descriptionList, setDesctiptionList] = useState<any[]>([]);
  const isAuthHDFetching = useStore(authHelpDeskNumberFX.pending);
  const isPersonFetching = useStore(fetchUserProfileFX.pending);
  const isHelpDeskReasonsFetching = useStore(getHelpDeskReasonsFX.pending);

  const isLoading = isPersonFetching || isAuthHDFetching || isHelpDeskReasonsFetching;

  const [user_serial_number, description] = form.watch(['user_serial_number', 'description']);

  const loginSubmitDisabled = !user_serial_number?.match(/^\d{11}$|^\d{6}-\d{5}$/) || !description;

  useEffect(() => {
    Emitter.on(SHOW_HELPDESK_MODAL, () => setOpenModal(true));
  }, []);

  useEffect(() => {
    if (openModal && descriptionList?.length === 0) {
      getHelpDeskReasonsFX().then((res) => {
        res.data.length && setDesctiptionList(res.data);
      });
    }
  }, [openModal]);

  if (!openModal) {
    return null;
  }

  const handleHelpDeskSet = async (values: AnyObject) => {
    authHelpDeskNumberFX({
      ...values,
      user_serial_number: values.user_serial_number.replace('-', ''),
    });

    localStorage.setItem('helpdesk_user_serial_number', values.user_serial_number.replace('-', ''));
  };

  if (isLoading) {
    return (
      <PortalModal>
        <Loading active />
      </PortalModal>
    );
  }

  return (
    <Modal
      size={'sm'}
      modalHeading={t('HelpDeskNumber')}
      passiveModal
      open={openModal}
      onRequestClose={() => setOpenModal(false)}
      className="web-epak__modal"
    >
      <div className="web-epak__modal-blok">
        <FormProvider {...form}>
          <form id="helpDesk-set" onSubmit={form.handleSubmit(handleHelpDeskSet)}>
            <div className={styles.fieldWrap}>
              <Controller
                control={form.control}
                name="user_serial_number"
                render={(props) => (
                  <RHFInput
                    field={props.field}
                    id="user_serial_number"
                    labelText={t('SERIAL_NUMBER')!}
                    pattern={'^\\d{0,11}$|^\\d{0,6}-\\d{0,5}$'}
                  />
                )}
              />
            </div>

            <div className={styles.fieldWrap}>
              <FormFieldController
                key={'form-field-description'}
                id="description"
                name="description"
                RenderComponent={RadioButton}
                classifiers={descriptionList}
                element={{
                  title: t('HelpDeskWhy'),
                  name: 'description',
                  ui_component_attributes: {
                    data_field_attributes: {
                      cla_schema_code: 'help_desk_user_login_reason',
                    },
                  },
                }}
                deleteComponentLabel=""
                addComponentLabel=""
                withOther={true}
                otherLabel={t('OtherReason')}
              />
            </div>
            <div className="bx--modal-footer bx--btn-set">
              <Button kind="secondary" onClick={() => setOpenModal(false)}>
                {t('CANCEL')}
              </Button>
              <Button kind="primary" type="submit" disabled={loginSubmitDisabled}>
                {t('login.login')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};
