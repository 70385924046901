import { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { routes } from './';

const Routes = () => (
  <Suspense fallback={<div></div>}>
    <Switch>
      {routes.map((route) => (
        <Route {...route} key={route.path} />
      ))}
      <Redirect to={'/'} />
    </Switch>
  </Suspense>
);

export default Routes;
