import { createEffect } from 'effector';
import { ContactsEnum } from 'pages/profile/components/contacts/store';
import api from 'services/api';
import { PatchContactPayload, VerifyContactPayload } from 'store/shared/types';

const PATH = '/api-profile/api/v1/legal_entities';

export const fetchLegalEntitiesFX = createEffect(async (params = {}) => {
  const response = await api.get(PATH, {
    params,
  });

  return response.data;
});

export const fetchEntityProfileFX = createEffect(async (uuid: string) => {
  const response = await api.get(`/api-profile/api/v1/legal_entities/${uuid}/profile`);

  return response.data;
});

export const deleteEntityContactFX = createEffect(
  async ({ entity_id, ...rest }: PatchContactPayload) => {
    const resp = await api.delete(`/api-profile/api/v1/legal_entities/${entity_id}/contacts`, {
      params: rest,
    });

    return resp.data;
  }
);

export const updateEntityContactFX = createEffect(
  async ({ entity_id, ...rest }: PatchContactPayload) => {
    const resp = await api.patch(`/api-profile/api/v1/legal_entities/${entity_id}/contacts`, rest);

    return resp.data;
  }
);

export const verifyEntityContactFX = createEffect(
  async ({ entity_id, type, value }: { entity_id: string; type: string; value: string }) => {
    const resp = await api.post(
      `/api-profile/api/v1/legal_entities/${entity_id}/contact_verification/${type}`,
      { value }
    );

    return resp.data;
  }
);

export const validateEntityContactFX = createEffect(
  async ({ entity_id, key, code, type }: VerifyContactPayload & { type: ContactsEnum }) => {
    const resp = await api.post(
      `/api-profile/api/v1/legal_entities/${entity_id}/contact_validate/${key}/${code}`
    );

    return { ...resp.data.data, type };
  }
);
