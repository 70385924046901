import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

/**
 *  createPortal
 */
const modalRoot = document.body as HTMLElement;

/**
 *
 */
interface PortalModalProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

/*
 *
 */
const PortalModal: React.FC<PortalModalProps> = ({ children }) => {
  // createPortal
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    // We assume `modalRoot` exists with '!'
    const temp = el.current;
    temp.className = 'port_modal';
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    modalRoot!.appendChild(temp);

    // eslint-disable-next-line no-void
    return () => void modalRoot?.removeChild(temp);
  }, []);

  useEffect(() => {
    const btn = document.querySelector('.port_modal .bx--modal-close');
    (btn as HTMLElement)?.focus();
  }, []);

  //
  return createPortal(children, el.current);
};

export default PortalModal;
