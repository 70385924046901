import { useStore } from 'effector-react';
import { $catalogServicesStore } from 'store/catalog';
import { $dependentsStore } from 'store/dependents';
import { $legalEntitiesStore } from 'store/entities';
import { fetchEntityProfileFX } from 'store/entities/effects';
import { ProfileTypeEnum } from 'store/main/interface';
import { $userStore } from 'store/user';
import { fetchUserProfileFX } from 'store/user/effects';

type userProfile = () => {
  data: any;
  type: ProfileTypeEnum;
  isDependent: boolean;
  isOnBehalf: boolean;
  isLegalEntity: boolean;
  isPerson: boolean;
  isDependentOrOnBehalf: boolean;
  isLoading: boolean;
};

export const useProfile: userProfile = () => {
  const user = useStore($userStore);
  const { required_entities } = useStore($catalogServicesStore);
  const { single: dependentProfile } = useStore($dependentsStore);
  const { profile: entityProfile } = useStore($legalEntitiesStore);
  const userProfileLoading = useStore(fetchUserProfileFX.pending);
  const entityProfileLoading = useStore(fetchEntityProfileFX.pending);
  const selectedUUID = user?.accounts?.selected_uuid;

  const getProfile = () => {
    const base = {
      isDependent: false,
      isOnBehalf: false,
      isLegalEntity: false,
      isPerson: false,
      isDependentOrOnBehalf: false,
    };

    if (entityProfile && selectedUUID === entityProfile.legal_entity_uuid) {
      // if legal entity with dependent
      if (dependentProfile) {
        return {
          ...base,
          data: { ...dependentProfile, legal_entity_uuid: entityProfile.legal_entity_uuid },
          type: ProfileTypeEnum.DEPENDENT,
          isDependent: true,
          isDependentOrOnBehalf: true,
        };
      }

      // if legal entity
      return {
        ...base,
        data: entityProfile,
        type: ProfileTypeEnum.LEGAL_ENTITY,
        isLegalEntity: true,
      };
    }

    // if entity selected from catalog
    if (required_entities?.legal_entity?.uuid) {
      return {
        ...base,
        data: required_entities.legal_entity,
        type: ProfileTypeEnum.ON_BEHALF,
        isOnBehalf: true,
        isDependentOrOnBehalf: true,
      };
    }

    // if person selected
    return {
      ...base,
      data: user,
      type: ProfileTypeEnum.PERSON,
      isPerson: true,
    };
  };

  const { data, ...rest } = getProfile();

  return {
    data: (data || {}) as any,
    isLoading: userProfileLoading || entityProfileLoading,
    ...rest,
  };
};
