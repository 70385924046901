import { createStore } from 'effector';
import { clearSelectedBehalfEV } from 'store/shared/events';
import { fetchCatalogFX } from './effects';
import { clearCatalogEV, resetModalServicesEV, updateCatalogStoreEV } from './events';
import { CatalogStore } from './interfaces';
import { normalizePayload } from './normalizers/normalizePayload';

const initialState = {
  groups: [],
  required_entities: null,
  servicesModal: { main: null, list: [], min: 0, type: null },
  overrideModal: false,
  overrideServiceUuid: null,
  quantityModal: null,
  serviceInfoModal: null,
  selectedServices: [],
  delegationFoundModal: false,
};

export const $catalogServicesStore = createStore<CatalogStore>(initialState);

$catalogServicesStore
  .on(fetchCatalogFX.doneData, normalizePayload)
  .on(clearCatalogEV, (state) => ({
    ...initialState,
    required_entities: state.required_entities,
  }))
  .on(clearSelectedBehalfEV, (state) => {
    if (!!state.required_entities) {
      return {
        ...state,
        required_entities: null,
      };
    }
  })
  .on(resetModalServicesEV, (state) => ({
    ...state,
    servicesModal: initialState.servicesModal,
    selectedServices: [],
  }))
  .on(updateCatalogStoreEV, (state, payload) => ({
    ...state,
    ...payload,
  }));
