import { createEffect } from 'effector';
import api from 'services/api';
import { RequestParams } from '../../../@types';

const PAYMENT_PATH = '/api-applications/api/v2';

export const fetchPaymentsFX = createEffect(async (params = {}) => {
  const response = await api.get(`${PAYMENT_PATH}/payments`, { params });

  return response.data;
});

export const fetchEntityPaymentFX = createEffect(
  async ({ entity_id, ...params }: RequestParams) => {
    const response = await api.get(`${PAYMENT_PATH}/legal_entities/${entity_id}/payments`, {
      params,
    });

    return response.data;
  }
);
