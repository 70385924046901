import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

export const Emitter = Object.freeze({
  on: (event: any, fn: (...args: any[]) => void) => eventEmitter.on(event, fn),
  once: (event: any, fn: (...args: any[]) => void) => eventEmitter.once(event, fn),
  off: (event: any, fn?: ((...args: any[]) => void) | undefined) => eventEmitter.off(event, fn),
  emit: (event: any, fn?: any) => eventEmitter.emit(event, fn),
});
