const en = {
  translation: {
    PUBLIC_SERVICE_PORTAL: 'Public service portal',
    footer: {
      text: 'In accordance with Section 4.11 of the Law On the Enterprise Register of the Republic of Latvia, information provided on the zebsite e.ur.gov.lv is true and reliable and corresponds to entries of the registers kept by the Enterprise Register and other registered information.',
      span: 'Data from the State Address Register Information System, {{year}} year',
    },
    cookies: {
      text: 'We use our own and third-party cookies to collect information about our visitors\' experience and to tailor operation of the website to our visitors\' needs.\n\nWe use technical (ensure the correct operation of the website) and personalized (responsible for the service directly selected by the visitor, e.g. language) cookies. To find out more about our cookie policy, please click "Read more".',
    },
    login: {
      text: 'Information on the processing of personal data by the Enterprise Register is available',
      fill_form: 'Fill out the registration form',
      form_helpdesk: 'Fill your username and password',
      first_name: 'First name',
      last_name: 'Last name',
      login: 'Login',
      password: 'Password',
      username: 'Username',
    },
    HelpDeskNumber: 'Set serial user number',
    HelpDeskWhy: 'Why are you connect?',
    OtherReason: 'Other',
    CODE_VALID_UNTIL: 'Valid for {{min}} more minutes',
    CHOOSE_AUTH:
      '<p>Welcome to the portal of services of the Register of Enterprises of the Republic of Latvia.\n</p><p>How do you want to log in?</p>',
    SAVE: 'Save',
    ASSIGN: 'Assign',
    NAME: 'Name',
    IDENTIFICATION_NUMBER: 'Identification number',
    LAST_NAME: 'Last name',
    PHONE_NUMBER: 'Phone number',
    EMAIL: 'Email',
    DEADLINE_EMAIL_TEXT:
      'If the term is complied with, the state fee does not have to be paid repeatedly.',
    EMAIL_TEXT:
      'E-mail of the natural person to whom the delegation is granted. The system will send an informative notice to the aforementioned e-mail on the rights to operate in an electronic environment',
    text_validate_code_email:
      'A verification code has been sent to your email {{value}}. Please enter validation code below and click on Validate code.',
    text_validate_code_phone:
      'A verification code has been sent to your phone {{value}}. Please enter validation code below and click on Validate code.',
    text_after_user_logout:
      'Thank you for using the Enterprise Register information system! For security reasons, please close your browser!',
    contact_already_exists: 'This {{contact}} already exists',
    create_profile: 'Create new profile',
    modal_refuse_confirmation: 'Do you really want refuse delegation invitation?',
    level_1: 'Restricted access',
    level_2: 'Limited access',
    level_3: 'Full access',
    administator: 'Administrator',
    employee: 'Employee',

    INDIVIDUALLY: 'INDIVIDUALLY',
    WITH_AT_LEAST: 'WITH_AT_LEAST',
    WITH_ALL: 'WITH_ALL',
    WITH_CHAIR: 'WITH_CHAIR',

    EMAIL_ADDRESS_TO_CONTACT:
      'The e-mail will be used to contact the Enterprise register and to receive answers to submitted applications if the e-address address is not activated.',
    receive_email_notifications:
      'The e-mail address is intended for communication with the Enterprises register. If the e-address of the addressee of the document is not activated, then the documents to be issued will be sent to the specified e-mail, as well as will be available on the portal (application report).',
    receive_sms_notifications:
      'Sending SMS messages is provided only to the customers of mobile communication operators of the Republic of Latvia.',
    FIND_A_SERVICE: 'Find a service',
    LOAD_MORE: 'Load more',
    ENTITIES: 'Represented entities',
    EDIT_DELEGATION: 'Edit delegation',
    NEW_DELEGATION: 'New delegation',
    PERMISSIONS: 'Permissions',
    PERMISSIONS_TEXT:
      'In the Delegations section, it is possible to add/remove users (delegated person) who will operate in the electronic environment on behalf of a legal person. Delegation is not the same as authorization. An authorized signatory may delegate a user with the following rights:',
    PERMISSIONS_TEXT_RESTRICTED:
      'Restricted access – the delegated person has access to all sections in the profile of the legal person. The delegated person sees all applications that have been created in the profile of the legal person (applications may also be edited, as well as applications may be submitted to the Register of Enterprises). Restrictions – may not delegate to another person.',
    PERMISSIONS_TEXT_FULL:
      'Full access – the delegated person has access to all sections in the profile of the legal person. The delegated person sees all applications that have been created in the profile of the legal person (applications may also be edited, as well as applications may be submitted to the Register of Enterprises). The delegated person may also delegate to another person.',
    REQUIRED: 'Required',
    ONE_OF_MARKED_IS_REQUIRED: 'One of the marked fields is required',
    PERMISSION_EXPIRY: 'Permission expiry',
    NEVER_EXPIRE: 'Never expire',
    REVOKE_ACCESS: 'Revoke access',
    CANCEL: 'Cancel',
    ACTIONS: 'Actions',
    STATUS: 'Status',
    AUTHORIZED_PERSON: 'Authorized person',
    DELEGATION_SEARCH: {
      INDENT_NUMBER: 'Identification number',
      REG_NUMBER: 'Registration number',
    },
    ASSIGN_DATE: 'Start date',
    EXPIRY_DATE: 'End date',
    SERVICE_BASKET: 'Service basket',
    CONTINUE_TO_DOCUMENTS: 'Continue to documents',
    CURRENT_COSTS: 'Current costs',
    OVERRIDE_MY_BASKET: 'Override my basket',
    OVERRIDE_THE_BASKET: 'Override the basket?',
    OVERRIDE_THE_BASKET_TEXT_ONE:
      'Services from different categories cannot be submitted together.',
    OVERRIDE_THE_BASKET_TEXT_TWO:
      'Selecting this service will clear your basket and add this service to it instead.',
    OVERRIDE_THE_BASKET_TEXT_THREE: 'Would you like to continue?',
    ENTER_REGISTRATION_NUMBER: 'Please enter the registration number',
    REGISTRATION_NUMBER_NOT_FOUND: 'Registration number not found',
    ERRORS: {
      REQUIRED: 'Required field, must not be empty',
      NUMBER: 'Please enter only digits',
      MAX_CHARS: 'Max {{number_of_characters}} chars',
      EMAIL: 'Please enter a valid e-mail',
      EMAIL_VALIDATE: 'Please "Verify" the e-mail',
    },
    REQUIREDS: '(Required)',
    SUMMARY: 'Summary',
    SUMMARY_FREE: 'Summary (state fee for payment)',
    E_MAIL: 'E-mail',
    COMPANY: 'Company',
    REQUIRED_INFORMATION: 'Required information',
    DOCUMENTS_OVERVIEW: 'Overview of documents to be submitted',
    ADDITIONAL_SUBMISSION_OPTIONS: 'Additional submission options',
    PAYMENT_INFORMATION: 'Payment information',
    PAYMENT_SUMMARY: 'Payment summary',
    ACTUAL_PAYMENT: 'Actual payment',
    PAYMENT_FORM: 'Payment form',
    PAYMENT_DETAILS: 'Payment details',
    PAYMENT_WAS_MADE_BY: 'Payment was made by',
    REAL_PERSON: 'Real person',
    PAYMENT_DETAILS_CONTACTS: {
      RECEIVER: 'Receiver',
      REG_NR: 'Registration nr.',
      SWIFT: 'SWIFT code',
      ACCOUNT: 'Account',
      PAYMENT_REFERENCE: 'Purpose of the payment order',
      STATE_FEE: 'State fee + Name',
      REG_NR_TEXT: 'Registration number (if any)',
    },
    REGISTRATION_NUMBER: 'Registration number',
    ORGANIZATION_CODE: 'Organization code',
    LEGAL_ADDRESS: 'Legal address',
    AMOUNT: 'Amount',
    DATE_OF_PAYMENT: 'Date of payment',
    PAYMENT_REFERENCE_NUMBER: 'Payment reference',
    PAYMENT_ORDER_NUMBER: ' Purpose of the payment order',
    CONTINUE: 'Continue',
    VIEW: 'View',
    APPLICATIONS: 'Applications',
    PERSONAL_IDN_NUMBER: 'Personal identification number',
    DATE_OF_BIRTH: 'Date of birth',
    ACTIVE: 'Active',
    REVOKED: 'Revoked (author)',
    RECEIVER_CANCELED: 'Revoked (user)',
    SUSPENDED: 'Suspended',
    EXPIRED: 'Expired',
    DRAFT: 'Draft',
    SENT: 'Sent',
    PENDING: 'Pending',
    DOCS_SUBMIT_SUCCESS: 'Documents submitted successfully!',
    ID_NUMBER: 'ID number',
    POTENTIAL_RESPONSE_DATE: 'Potential response date',
    DOCUMENT_TYPES: {
      documents: 'State fee',
      fast_processing: 'Expedited processing',
      discount: 'Discount applied',
      previous_payment: 'Previous payment',
    },
    DOCUMENT_TYPES_CUSTOMER: {
      documents: 'State fee (calculated by the system)',
      fast_processing: 'Expedited processing',
      discount: 'Discount applied',
      previous_payment: 'Previous payment',
    },
    SUBMIT_DOCUMENTS_DESCRIPTION:
      'It is allowed to attach electronic documents signed with a secure electronic signature and time stamp (recommended formats - EDOC, ASICE, PDF), which include the following file formats - TXT, ODF, DOCX, XLSX, PDF, JPEG, TIFF, PNG',
    SUBMIT_PAYMENT_DETAILS_DESCRIPTION:
      '<b>Please make the payment before completing your submission, if possible.</b>{{br}} Payments can take up to 3 business days to appear in our systems.',
    DEPENDENTS: 'Organizational units',
    CONTRACTS: 'Other legal entities, legal facts',
    NAME_ENTERPRISE: 'Name of an enterprise',
    FIND_A_DEPENDENT: 'Find a dependent',
    DEPENDENT_TYPES: {
      BRANCH_FIL: 'Branch',
    },
    FIND_A_CONTRACT: 'Search for an entity, a legal fact',
    FIND_AN_ENTITY: 'Search for an entity',
    FILTER_BY: 'Filter by',
    CATEGORY: 'Category',
    PROFILE: 'Contact information',
    SUBMISSIONS: 'Applications',
    SERVICES_SELECTED: 'Services selected',
    PERSON_SUBMISSIONS_HELP_TEXT: 'All your applications can be viewed here',
    LEGAL_SUBMISSIONS_HELP_TEXT: 'All your applications can be viewed here',
    CONTACT_PERSON: 'Contact person',
    ID: 'Number',
    TYPE: 'Type',
    DATE_SUBMITTED: 'Date submitted',
    DATE_SUBMITTED_FROM: 'Submitted from',
    DATE_SUBMITTED_TO: 'Submitted to',
    SUBMITTER: 'Submitter',
    SUBJECT: 'Subject',
    DATE_CREATED: 'Date created',
    DATE_CREATED_FROM: 'Created from',
    DATE_CREATED_TO: 'Created to',
    NOT_SUBMITTED: 'Not submitted',
    SUBMISSION_STATUSES: {
      draft: 'Initiated',
      initiated: 'Initiated',
      received: 'Submitted',
      completed: 'Registered',
      reinitiated: 'Reinitiated',
      resubmitted: 'Resubmitted',
      processing: 'Processing',
      rejected: 'Rejected',
      registered: 'Registered',
      postponed: 'Postponed',
      waiting_for_information: 'Submitted',
    },
    NEW_SUBMISSION: 'New application',
    VIEW_SUBMISSIONS: 'View applications',
    PERSON_DELEGATIONS_HELP_TEXT:
      'See companies who have given you access to operate on their behalf.',
    LEGAL_DELEGATIONS_HELP_TEXT:
      'Manage people who have access to operate on your companies behalf.',
    BACK: 'Back',
    SUBMIT: 'Submit',
    CONTINUE_SUBMIT_AN_APPLICATION: 'Continue to submit an application',
    SIGN: 'Sign',
    REVIEW_SIGNED: 'Please review if all documents are in order and signed with an e-signature.',
    SHARE_PAYMENT_LINK: 'Share payment link',
    SHARE_LINK_INFO: 'The payment link has been copied to the clipboard',
    SHARE_PAYMENT_LINK_INFO:
      'Use this option if you want another person to make the payment. To do this, press the text button "Share payment link". The copied link can be passed (outside the portal) to another person, who will be able to make the payment (including submitting) the application.',
    PAYMENT: 'Payment',
    PAYMENTS: 'Payments',
    PAYMENTS_TAB: {
      APP_NUMBER: 'Application number',
      PRICE: 'Application price',
      AMOUNT_PAID: 'Paid amount',
      DATE: 'Payment date',
      PAYER: 'Payer',
    },
    MAKE_PAYMENT: 'Make payment',
    REVIEW_SUBMISSION: 'Review application',
    CONFIRM: 'Confirm',
    NO_DATA: 'No data',
    OVERVIEW: 'Overview',
    ON_BEHALF_OF: 'On behalf of',
    REG_NUMBER: 'Registration number',
    IDENT_NUMBER: 'Identification number',
    SERVICE_CATALOGUE: 'Service Catalog',
    SERVICE_CATALOG: 'Service Catalog',
    SUBMITTED_SUCCESSFULLY: 'Submitted Successfully',
    'FILL_&_UPLOAD': 'Fill & Upload',
    SERIAL_NUMBER: 'Serial number',
    CONTRACT_TYPES: {
      spousal_property_relations: 'Spousal property relations contract',
      mass_media: 'Mass media',
    },
    ENTITIES_TYPES: {
      LIMITED_LIABILITY_COMPANY_SIA: 'Limited Liability Company',
      ASSOCIATION_BDR: 'Association',
    },
    HUSBAND: 'Husband',
    WIFE: 'Wife',
    REGISTRATION_DATE: 'Registration date',
    TITLE: 'Title',
    RESTRICTED_ACCESS: 'Restricted access',
    FULL_ACCESS: 'Full access',
    EMPLOYEE: 'Employee',
    ADMINISTRATOR: 'Administrator',
    SERVICES: 'Services',
    PLEASE_RATE_YOUR_EXPERIENCE: 'Please rate your experience',
    SEND_FEEDBACK: 'Send feedback',
    AVAILABLE_EXEMPTIONS: 'Available exemptions',
    NOT_AVAILABLE_EXEMPTIONS:
      'Select either "Accelerated review" or "Available exemptions". Selecting both options at the same time is not allowed.',
    APPLY_EXEMPTION: 'Apply exemption',
    DELEGATIONS: 'Delegations',
    SELECT_SERVICE_TO_CONTINUE: 'Select a service to continue',
    MORE_INFORMATION: 'More information',
    Changes: 'Changes',
    Register: 'Registration',
    Liquidation: 'Liquidation',
    Insolvency: 'Insolvency',
    TEMPLATE: 'Template',
    EXAMPLE: 'Sample',
    DOCUMENT_NAME: 'Document name',
    DOCUMENTS: 'Documents',
    CHANGE_SELECTED_SERVICES: 'Change selected services',
    ADD_OTHER_DOCUMENTS: 'Add other documents',
    ADD_COMPONENT: 'Add more',
    DELETE_COMPONENT: 'Delete component',
    PRICE: 'Price',
    FOR_LEGAL_ENTITIES: 'For legal entities',
    FOR_EVERYONE_ELSE: 'For everyone else',
    company_name: 'Company name',
    COMPANY_NAME: 'Company name',
    wife_name: 'Wife’s name and surname',
    WIFE_NAME: 'Wife’s name and surname',
    husband_name: 'Husband’s name and surname',
    HUSBAND_NAME: 'Husband’s name and surname',
    email: 'E-mail',
    FEEDBACK_SUBMIT_SUCCESS: 'Feedback submitted. Thank you!',
    FEEDBACK_SUCCESS_TEXT: 'Your rating helps to improve our services.',
    BASKET_NO_ITEMS: 'Services you select will appear here.',
    CHECK_AVAILABILITY: 'Check availability',
    SEND_YOUR_SUBMISSION: 'Send Your application',
    ON: 'On',
    OFF: 'Off',
    HISTORY: 'History',
    ENTER_AMOUNT: 'Please enter the amount',
    SELECT: 'Select',
    DATE_TIME: 'Date, time',
    DATA: 'Data',
    EVENT_ACTION: 'Action (event)',
    SECTION: 'Section',
    NAME_OF_USER: 'Name, surname of the user',
    COMPANY_NAME_AVAILABLE: 'Company name available',
    COMPANY_NAME_TAKEN: 'Company name is taken',
    CHECK_NAME: 'Check name',
    CHECK_NAME_LINK: 'https://info.ur.gov.lv/#/data-search',
    CONDITIONS_FOR_NAME: 'Conditions for selecting a name',
    CONDITIONS_FOR_NAME_LINK: 'https://www.ur.gov.lv/en/register/',
    CONDITIONS_FOR_NAME_DESC:
      'Choose a legal form: Founding - Registration with the Enterprise Register - Checking the name',
    ADDITIONAL_SUBMISSION_OPTIONS_NAME: 'Submission options',
    spousal_property_relations: 'Marriage contract',
    NO: 'No',
    APPLICATION_FORM: 'Application form',
    THEME: 'Theme',
    DESCRIPTION: 'Description',
    ATTACH_FILE: 'Add an attachment',
    CONTACT_INFO: 'Contact information',
    TOOLTIP_CONTACT_INFO:
      'If you agree, if necessary with you an employee of the Register of Enterprises may contact please provide email and / or phone number',
    YOUR_EMAIL_ADDRESS: 'Your email address',
    PHONE_NUMBER_INFO: 'Phone number',
    RESET_CODE: 'Reset code',
    ENTER_THE_CODE: 'Please enter the code below',
    SEND: 'Send',
    en: 'English',
    lv: 'Latvian',
    PROFILE_LANGUAGE_SWITCH: {
      en: 'English',
      lv: 'Latviešu',
    },
    HELPDESK: 'Report an error',
    VERIFY: 'Verify',
    'The person already has active delegation to legal entity.':
      'The person already has active delegation to legal entity.',
    PAGINATION: {
      ITEMS_PER_PAGE: 'Items per page',
      OF: 'of',
      PAGE: ' page',
      ITEMS: ' items',
    },
    SUBMIT_AN_ISSUE: 'Submit an issue',
    TYPE_OF_ISSUE: 'Type of issue',
    CONTINUE_SESSION: 'Continue session',
    END_SESSION: 'End session',
    SESSION_WILL_EXPIRE_IN: 'Your session will expire in',
    CONTINUE_ANYWAY: 'Continue anyway',
    CHECK_DOCUMENTS: 'Check documents',
    DOCS_MISS_SIGN_TITLE: 'Problem with document e-signature validation!',
    DOCS_MISS_SIGN_DESCRIPTION:
      '<p>The registration application and the documents attached to it must each be individually signed with a secure electronic signature that contains a time stamp!</p><p>You can check the e-signature <a target="_blank" href="https://www.ur.gov.lv/lv/kontakti/ka-iesniegt-dokumentus-elektroniski/e-dokumentu-parbaude/parbaudi-elektroniski-parakstitu-dokumentu/">here</a></p><p>If you are sure that the document is signed correctly, you can continue with the application submission process.</p>',
    DOC_MISSING_SIGNATURE: 'The document is not e-signed',
    DOC_SIGNATURE_VALID: 'Signature valid',
    APPLICATION_SUBMIT_MODAL_TEXT:
      '<p>You will not be able to make changes to it.</p><p>Information about the application and its status is available in the "Applications" section of the profile.</p>',
    HELPDESK_TYPE_PLACEHOLDER: 'Choose an option...',
    'Technical issue': 'Technical issue',
    'Grammar mistake': 'Grammar mistake',
    'Suggestion for improving the site': 'Suggestion for improving the site',
    PLEASE_SELECT_SERVICES: 'Please select at least {{count}} service',
    PLEASE_SELECT_SERVICES_plural: 'Please select at least {{count}} services',
    DOC_MISS_DIGIT_SIGN: 'Documents missing a digital signature',
    GO_BACK_AND_UPLOAD_DOCS_OR_SUBMIT:
      'Go back and upload signed documents or press “Submit” to continue.',
    REQUIRED_IF_APPLICABLE: 'Required if applicable',
    APPLICATION_DELETE_CONFIRMATION: 'Do you want to delete application?',
    THIS_PHONE_ALREADY_ADDED: 'This phone number has already been added.',
    THIS_EMAIL_ALREADY_ADDED: 'This email address has already been added.',
    CLOSE_MENU: 'Close menu',
    OPEN_MENU: 'Open menu',
    YES_BEFORE: 'Yes, before',
    NO_AFTER: 'No, after',
    AM_I_SUBMITING_BEFORE:
      'Am I submitting this <strong>before</strong> the deadline set by the notary?',
    TEXT_SEND_SUCCESS: 'Data send successfully',
    OTHERWISE: 'Otherwise',
    MAINTENANCE_HEADER: 'Website under maintenance',
    MAINTENANCE_TEXT:
      'This website is currently undergoing scheduled maintenance. We should be back shortly.',
    REVOKED_DELEGATION: 'Delegation {{status}}',
    DELEGATION: 'Delegation',
    OPTIONAL_REQUIRED: '(Required if applicable)',
    NO_SERVICES_SELECTED: 'No service selected',
    INITIATE_CHANGES: 'Initiate changes',
    DELEGATION_FOUND: 'Delegation found',
    YOU_HAVE_DELEGATION_IN_ENTITY: 'You have delegation in this entity.',
    WOULD_YOU_LIKE_SWITCH_OR_CONTINUE:
      'Would you like to switch to and continue as the entity or as yourself?',
    CONTINUE_AS_MYSELF: 'Continue as myself',
    CONTINUE_AS_ENTITY: 'Continue as the entity',
    SELECT_A_LEGAL_ENTITY: 'Select a legal entity',
    SELECT_A_LEGAL_ENTITY_OR_PERSON: 'Select a legal entity or a real person',
    ILLEGAL_CHARACTERS: 'The file name contains illegal characters',
    RENAME_FILE: 'Rename the file and try again',
    THERE_NO_DATA: 'There is no data',
    MANYAL: 'Manual',
    PROCESSING_PERSONAL_DATA: 'Processing of personal data',
    ACCESSIBILITY_STATEMENT: 'Accessibility statement',
    REPORT_ERROR: 'Report an error',
    EMAIL_PHONE_INFO_TO_HELPDESK:
      'If you agree that an employee of the Enterprises Register may contact you if necessary, please provide your e-mail and/or telephone number',
    SERVICE_UNAVAILABLE: "Sorry, the service isn't available right now. Please try again later.",
    LOADING: 'Loading...',
    SUBMISSION_OPTIONS_TEXT:
      '<p style="padding-right: 0%">The state fee shall be paid in a triple amount if the applicant wishes the registration application to be reviewed within one working day (term of the review of documents depends on the time of submission of the application and Latvian holidays as well).</p>\n' +
      '<p style="padding-right: 0%">The state fee shall be paid in the specified amount if the applicant wishes that the application for entry of an individual merchant or capital company of a single founder in the commercial register or exclusion from the commercial register on the basis of an application be reviewed within one working day (the application must be enclosed).</p>\n' +
      '<p style="padding-right: 0%">The state fee shall be paid in a triple amount for the service “Registration of a new procuration” when it is applied for together with the services "Registration of a new sole trader in 1 day", "1-day registration of a new LLC (1 founder)", "1-day registration of a new LLC with no minimum capital requirement (1 founder)", "1-day registration of a new joint-stock company (1 founder)".</p>',
    SUBMISSION_OPTIONS_TEXT_AVAILABLE:
      '<p style="padding-right: 0%">There are the following types of exemptions (depending on the services added to the cart):\n</p><p style="padding-right: 0%">100% Exemptions – based on such exemptions, a 100% discount is granted for all services added to the cart.\n</p><p style="padding-right: 0%">The following are exempt from the state fee:</p><ul style="list-style: disc; padding-left: 20px"><li>associations/trade unions bringing together disabled persons, orphans or large families.</li><li>associations/trade unions, the objective (or one of the objectives) of which is to organize sports events for children and pupils.</li></ul><p style="padding-right: 0%">Partial exemptions – 100% discount is granted only for the selected services. Other services placed in the cart will be charged according to the price list of the Register of Enterprises.</p>',
    ACTIVE_VERSION_DESCRIPTION: 'Active version {{version}}',
    VERSION: 'Version',
    RESOLUTION_FILE_AVAILABLE:
      'The decision has already been taken and will be available at the portal in a minute.',
    APPLICATION_HAS_PENDING:
      'Payment is in process. Further information is available to the applicant in the "Applications" section of the portal.\n',
    STATE_FREE_CUSTOMER: 'State fee (entered by the customer)',
    STATE_FREE: 'State fee',
    DOWNLOAD_FILE: 'Download File',
    DELETE_FILE: 'Delete File',
    DOWNLOAD: 'Download',
    MAKE_CHANGES: 'Make changes',
    ACCEPT: 'Accept',
    CLOSE: 'Close',
    READ_MORE: 'Read more',
    OK: 'OK',
    SEARCH: 'Search',
    CLEAR_SEARCH: 'Clear search criteria',
    DATE_FROM: 'Date from',
    DATE_TO: 'Date to',
    POSITION_MEMBER_BOARD: 'Position as a member of the Management',
    RIGHTS_REPRESENT: 'Rights to represent the company',
    PERSONAL_DATA: 'Personal data',
    NAME_BOARD: 'Name',
    SURNAME_BOARD: 'Surname',
    PERSON_NUMBER:
      'The person has been assigned a personal identity number of the Republic of Latvia',
    PERSONAL_IDENTITY_NUMBER: 'Personal identity number',
    TYPE_OF_DOCUMENT: 'Type of document',
    DOCUMENT_NUMBER: 'Document number',
    DATE_OF_ISSUE: 'Date of issue',
    ISSUING_STATE: 'Issuing State',
    INSTITUTION: 'Institution',
    ADDRESS: 'Address',
    COUNTRY: 'Country',
    ENTER_ADDRESS: 'Enter the address',
    ENTRYWITHFUTUREDATERADIO:
      'Is it necessary to make an entry on the person with the rights to represent with a future date?',
    YES: 'Yes',
    SUBMIT_AN_APPLICATION: 'Submit an application?',
    SUBMIT_AN_APPLICATION_TEXT_ONE:
      'You will no longer to be able to make changes to the application.',
    SUBMIT_AN_APPLICATION_TEXT_TWO:
      'If there is a fee for the service, then after successful payment, the application will be automatically submitted to the Register of Companies.',
    SUBMIT_AN_APPLICATION_TEXT_THREE:
      'Information about the application and its status is available in the "Applications" section of the profile.',
    DATE_MAKING_ENTRY: 'Date of making an entry',
    CONFIRMATION: 'Confirmation',
    LABEL_DOCUMENT: 'Personal identity document details',
    APP_PRICE: 'Application price',
    PAID_AMOUNT: 'Paid amount',
    WAIT_DOCUMENT: 'Wait, please. Document is generated.',
    GENERATE_INFO_DOCUMENT:
      'The document(s) were generated by the system and attached to the appropriate document type. Please check that the content of the document is appropriate. If necessary, replace the document generated by the system with your own.',
    AFTER_THE_PAYMENT: 'After the payment is made, your submission will be submitted',
    DATA_ENTRY: 'Data entry',
    SUCCESS: 'Success',
    ERROR_MAX_SIZE: 'The file size must not exceed 50 MB',
    GENERATE_DOCUMENTS_FORMS: 'Do generate the documents?',
    WE_CAN_GENERATE:
      'Currently, generating documents from filled data fields is only possible for some services, but we are working to improve this process.',
    UPLOAD_OWN_DOCUMENTS:
      'In the next step, the portal user has the opportunity to add his own documents (including replacing the document generated by the system with his own).',
    SELECT_AN_OPTION:
      '!!! Remember, generating documents again will create new documents that will have to be signed again.',
    HAVE_DOCUMENTS_PREPARED: 'Continue without generating documents',
    NEXT: 'Next',
    GENERATE_DOCUMENTS: 'Generate documents',
    SIGN_A_DOCUMENT: 'Sign a document?',
    SIGN_MODAL_HEADING: 'Choose the signing way',
    SIGN_SMARTPHONE: 'eSignature on your smartphone',
    SIGN_CARD: 'eId and eSignature card',
    SHARE_DOCUMENT_EXTERNALLY: 'Send link to sign documents',
    DOCUMENT_TO_BE_SIGNED: 'Documents to be signed online',
    DOCUMENT_FOR_SIGNING_ONLINE: 'Dokumenti parakstīšanai tiešsaistē',
    DOCUMENT_TO_BE_SIGNED_INFO:
      'Izmanto šo opciju, lai pārsūtītu dokumentus parakstīšanai citām personām.\n' +
      'Persona, kas saņem saiti, var parakstīt dokumentus:\n' +
      '1.Tiešsaistē. Izmantojot šo parakstīšanas opciju, paraksti automātiski parādīsies portālā (nav nepieciešams papildus pārsūtīt dokumentus pieteikuma autoram). Nepieciešamības gadījumā pieteikuma autors var pievienot vispārīgo komentāru dokumentu parakstītājiem.\n' +
      '2.Ārpus portāla. Ja personai nav rīku, lai parakstītu dokumentus tiešsaistē (piemēram, ārvalsts personām), tad dokumentus ir iespējams lejupielādēt savā datorā un parakstīt ar drošu elektronisko parakstu. Šādos gadījumos personai jāsūta dokumenti pieteikuma autoram, izmantojot citus saziņas līdzekļus, piemēram, e-pastu (tiešsaistē to nebūs iespējams izdarīt). Nepieciešamības gadījumā pieteikuma autors var pievienot komentāru pie katra dokumenta (piemēram, uzskaitot personas, kurām attiecīgais dokuments jāparaksta).',
    ADD_A_COMMENT_FOR_DOCUMENTS: 'Add a comment for documents to be signed online:',
    DOCUMENT_TO_BE_SIGNED_OUTSIDE: 'Documents to be signed outside the system',
    EACH_DOCUMENT:
      'Each document needs to be downloaded and signed with an e-signature, and sent to the responsible person. All documents include the latest signatures signed through the system.',
    COMMENTS: 'Comments',
    SHARE_THIS_LINK: 'Share this link to access the documents',
    SHARE_THIS_LINK_INFO:
      'Nokopē saiti un pārsūti to dokumentu parakstītājiem (saite jāpārsūta ārpus portāla, izmantojot citus saziņas līdzekļus, piemēram, e-pastu).\n',
    SAVE_COMMENTS: 'Save comments & Copy link',
    SIGN_DOCUMENT_WITH_YOUR_SIGNATURE: 'Please sign these documents with your e-signature',
    SIGN_DOCUMENT_WITH_YOUR_SIGNATURE_INFO:
      'Dokumentus ir iespējams parakstīt:\n' +
      '1.Tiešsaistē. Izmantojot šo parakstīšanas opciju, paraksti automātiski parādīsies portālā (nav nepieciešams papildus pārsūtīt dokumentus pieteikuma autoram).\n' +
      '2.Ārpus portāla. Ja personai nav rīku, lai parakstītu dokumentus tiešsaistē (piemēram, ārvalsts personām), tad dokumentus ir iespējams lejupielādēt savā datorā un parakstīt ar drošu elektronisko parakstu. Šādos gadījumos personai jāsūta dokumenti pieteikuma autoram, izmantojot citus saziņas līdzekļus, piemēram, e-pastu (tiešsaistē to nebūs iespējams izdarīt).',
    YOU_HAVE_BEEN:
      'You have been sent this link because your e-signature is required on 1 or more of the documents you see below. Please choose a way to sign these documents.',
    SIGN_DOCUMENT_SECURELY: 'Sign documents securely online\n',
    BY_ACCESSING_YOUR_PROFILE:
      'By accessing your profile you can sign documents within the portal.',
    SIGN_DOCUMENT_ONLINE: 'Sign documents online',
    SIGN_DOCUMENT_ON_YOUR_COMPUTER: 'Sign documents on your computer',
    DOWNLOAD_EACH_DOCUMENT:
      'Download each document to e-sign it on your computer. Afterwards, forward them to the person responsible for creating the submission.',
    SIGN_DOCUMENT_FORM_FORBIDDEN:
      'The link is not valid. The link remains invalid immediately after submitting the application to the Enterprise Register or after the link expires.',
    NO_ACCESS_PAY:
      'The application is in the process of being paid or has already been paid. It is not possible to start payment again. If you have any questions, please contact the author of the application',
    NATURAL_PERSON: {
      countryOfResidence: 'Country',
      cityOrRegionAbroad: 'City or region',
      otherAddressElementsAbroad: 'Other address elements',
      zipCodeAbroad: 'Postcode',
      enterAddress: 'Enter the address',
      address: 'Address',
      identityDocumentType: 'Type of document',
      identityDocumentCountry: 'Issuing State',
      otherDocumentType: 'Other type of document',
      identityDocumentNumber: 'Document number',
      identityDocumentIssuedBy: 'Institution',
      identityDocumentDate: 'Date of issue',
      labelDocument: 'Personal identity document details',
      forname: 'Name',
      surname: 'Surname',
      latvianIdentityNumber: 'Personal identity number',
      birthDate: 'Date of birth',
      personalData: 'Personal data',
      personNumber:
        'The person has been assigned a personal identity number of the Republic of Latvia',
      documentAddedPreviously:
        "A document confirming the right is previously attached to this subject's file",
      uploadedDocumentAttestingToRights: 'Attach a document attesting to rights',
      legalBasis: 'Indicate on what legal basis the person is signing the application',
      otherTypeOfLegalBasis: 'Other type of legal basis',
    },
    LEGAL_ENTITY: {
      defaultCheckboxTitle: 'Option 1',
      title: 'Legal entity',
      country: 'Country where the company is registered',
      registrationNumber: 'Registration number',
      name: 'Name',
      titleLegalAddress: 'Legal address',
      address: 'Enter the address',
      cityOrRegionAbroad: 'City or region',
      otherAddressElementsAbroad: 'Other address elements',
      zipCodeAbroad: 'Postcode',
    },
    SIGNER_PERSON: {
      leSigner: 'The person who will sign the documents',
      position: 'Position',
      otherPosition: 'Specify the position',
      leTtypeOfLegalJustification: 'Mark on which legal basis the person signs the application',
      le_type_other_basis: 'Type of other legal basis',
      documentAddedPreviously:
        "A document confirming the right is previously attached to this subject's file",
      forename: 'Name',
      surname: 'Surname',
      hasLatvianIdentityNumber:
        'The person has been assigned a personal identity number of the Republic of Latvia',
      latvianIdentityNumber: 'Personal identity number',
      birthDate: 'Date of birth',
      le_title: 'Identity document data',
      type: 'Type of document',
      otherDocumentType: 'Other type of document',
      number: 'Document number',
      issuedOn: 'Date of issue',
      country: 'Issuing State',
      issuedBy: 'Institution',
      le_title_address: 'Address',
      addressCountry: 'Country',
      documentRightToRepresentForeignMerchant:
        'A document certifying the right to represent a foreign merchant',
      uploadedDocumentAttetsingToRights: 'Attach a document attesting to rights',
      address: 'Enter the address',
      cityOrRegionAbroad: 'City or region',
      otherAddressElementsAbroad: 'Other address elements',
      zipCodeAbroad: 'Postcode',
    },
    SOMETHING_WRONG: 'Application data entry is not available',
    SHARED_DOCUMENTS_INFO_TEXT:
      'Sending a link will provide access to draft documents. Access is provided to users who have the link, regardless of whether they receive the link directly from you or are forwarded from someone else. The user is personally responsible for transferring the link only to those persons who need access to these documents, as well as for the risks associated with the transfer and use of the link. The Register of Enterprises is not responsible for the content and transfer of the link to other parties, but provides the link and access only as a service to users on your behalf. The link will not be valid immediately after submitting the application to the Register of Enterprises.\n' +
      '\n',
    AUTOSAVE_MSG: 'Autosave form...',
    CITY_OR_REGION: 'City or region',
    OTHER_ADDRESS: 'Other address elements',
    POSTCODE: 'Postcode',
    CADASTRE: 'Cadastre designation',
    FONT_SIZE: 'Font size',
    CONTRAST: 'Contrast',
    WARNING_MODAL_TITLE:
      'Data entry issues have been identified in the application. Do you wish to continue?',
    WARNING_MODAL_TEXT:
      'The application was found to contain the data entry issues mentioned above.\nDo you want to continue processing the application?',
    REVIEW_DATA_TITLE: 'Application data',
    CHANGE_ACCOUNT: 'Change account',
    EXTERNAL_MODAL_TITLE: 'Would you like to sing-in like an external user?',
    EXTERNAL_USER_SELECT: 'External organization',
    COMMUNICATION: {
      TITLE: 'Communication',
      ITEM_RECEIVED: 'Received',
      ITEM_SENT: 'Sent',
      ITEM_BLANKS: 'Draft',
      ITEM_DELETED: 'Deleted',
      APP_NUMBER: 'Application number',
      SUBJECT: 'Subject',
      DATE_SENDING: 'Date of sending',
      SENDER: 'Sender',
      RECIPIENT: 'Recipient',
      TOPIC: 'Topic',
      CONTENT: 'Content',
      MESSAGE_SUBJECT: 'Message subject',
      LEGAL_ENTITY: 'Legal entity',
      NOTARY: 'Notary',
      CREATE: 'Add new letter',
      LETTER_TITLE: 'Letter',
      MAINTAIN: 'Maintain',
      SEND_TO: 'Send to',
      APPENDICES: 'Appendices',
      VIEW_TITLE: 'Communication',
      APPLICATION: 'Application',
      SUBJECTS: 'Subject',
      REPLY: 'Reply',
      REPLY_MODAL_TITLE: 'New message',
      CANCEL: 'Cancel',
      SUBMIT: 'Send',
      RECEIVED: 'Received messages',
      SENT: 'Sent messages',
      APP_COMMUNICATION_TABLE: {
        send_time: 'Date',
        sender_uuid: 'Sender',
        message_subject: 'Subject',
        attachments: 'Attachments',
        documents: 'Documents',
        NO_DATA: 'No messages',
      },
    },
  },
};

export default en;
