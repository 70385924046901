import { createStore } from 'effector';
import { fetchPaymentsFX, fetchEntityPaymentFX } from './effects';
import { createQueryParamsEV } from './events';
import { PaymentStore } from './interfaces';

export const $paymentStore = createStore<PaymentStore>({
  list: [],
  total: 0,
  queryParams: undefined,
});

$paymentStore
  .on(fetchPaymentsFX.doneData, (state, payload) => ({
    ...state,
    list: payload.data.list,
    total: payload.data.total,
  }))
  .on(fetchEntityPaymentFX.doneData, (state, payload) => ({
    ...state,
    list: payload.data.list,
    total: payload.data.total,
  }))
  // queryParams
  .on(createQueryParamsEV, (state, payload) => ({ ...state, queryParams: payload }));
