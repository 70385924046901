import ReactDOM from 'react-dom';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CookiesProvider } from 'react-cookie';
import reportWebVitals from './reportWebVitals';
import App from './App';
import lv from './translations/lv';
import en from './translations/en';
import 'assets/style.scss';
import { LOCAL_STORAGE } from './constants/app';

const language = localStorage.getItem(LOCAL_STORAGE.LANG);

i18n.use(initReactI18next).init({
  lng: language || 'lv',
  resources: {
    lv,
    en,
  },
  fallbackLng: 'en',
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_CDN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: isNaN(parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || ''))
    ? 1.0
    : parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || ''),
  enabled: process.env.NODE_ENV !== 'development',
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </I18nextProvider>,
  document.querySelector('#root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
