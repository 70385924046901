import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { generatePath, useHistory } from 'react-router';
import { useCookies } from 'react-cookie';
import { STATIC_PAGE } from '../../routes/constants';

const CookiesAttention = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [cookie, setCookie, removeCookie] = useCookies(['web_epak', '_ga', '_gid', '_gat']);

  useEffect(() => {
    setTimeout(() => {
      if (!cookie.web_epak) {
        setOpen(true);
      }

      if (cookie._ga) {
        removeCookie('_ga', { path: '/', domain: '.ur.gov.lv' });
      }

      if (cookie._gid) {
        removeCookie('_gid', { path: '/', domain: '.ur.gov.lv' });
      }

      if (cookie._gat) {
        removeCookie('_ga', { path: '/', domain: '.ur.gov.lv' });
      }
    }, 1000);
  }, [cookie]);

  return (
    <div className={classNames('web-epak-notification', { active: open })}>
      <div className="web-epak-notification-wrap">
        <p style={{ whiteSpace: 'pre-line' }}>{t('cookies.text')}</p>
        <button
          className="btn active"
          type="button"
          onClick={() => {
            setCookie('web_epak', true, {
              domain: process.env.REACT_APP_COOKIES_DOMAIN,
              path: '/',
            });

            setOpen(false);
          }}
        >
          {t('CLOSE')}
        </button>
        <button
          className="btn active"
          type="button"
          onClick={() => history.push(generatePath(STATIC_PAGE, { slug: 'cookies' }))}
        >
          {t('READ_MORE')}
        </button>
      </div>
    </div>
  );
};

export default CookiesAttention;
