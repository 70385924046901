import { useRef, useEffect } from 'react';
import { ScrollHeader } from './scroll-header/ScrollHeader';
import { MainHeader } from './main-header/MainHeader';

const Header = () => {
  const refHeader = useRef<HTMLDivElement | null>(null);
  const refMiniHeader = useRef<HTMLDivElement | null>(null);

  const scrollMain = () => {
    const scrolled = window.pageYOffset || document.documentElement.scrollTop;

    if (scrolled > 50) {
      if (refHeader && refHeader.current) {
        refHeader.current.classList.add('active');
      }

      if (refMiniHeader && refMiniHeader.current) {
        refMiniHeader.current.classList.add('active');
      }
    } else {
      if (refHeader && refHeader.current) {
        refHeader.current.classList.remove('active');
      }

      if (refMiniHeader && refMiniHeader.current) {
        refMiniHeader.current.classList.remove('active');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollMain, true);

    return () => {
      window.removeEventListener('scroll', scrollMain);
    };
  }, []);

  return (
    <>
      <MainHeader refHeader={refHeader} />
      <ScrollHeader refHeader={refMiniHeader} />
    </>
  );
};

export default Header;
