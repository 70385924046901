import { LOCAL_STORAGE } from 'constants/app';
import cogoToast from 'cogo-toast';
import { createStore } from 'effector';
import { fetchEntityProfileFX } from 'store/entities/effects';
import {
  accessDeniedlogoutUserFX,
  authHelpDeskNumberFX,
  authenticateUserFX,
  deleteUserContactFX,
  fetchExternalOrganizationsFX,
  fetchUserProfileFX,
  fetchUserTermsConsentFX,
  logoutUserFX,
  patchUserProfileFX,
  updateUserContactsFX,
  validateUserContactFX,
} from './effects';
import { changeUserSize, setAccountUuidEV, setUserProfile } from './events';
import { UserStore } from './interfaces';

export const $userStore = createStore<UserStore>(null);

$userStore
  .on(logoutUserFX.doneData, () => {
    // sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('show_info', 'show');
    window.location.replace('/');
    // window.location.href = process.env.REACT_APP_CURRENT_DOMAIN || payload.data.redirect_url;
  })
  .on(logoutUserFX.failData, () => {
    // sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('show_info', 'show');
    window.location.replace('/');
  })
  .on(accessDeniedlogoutUserFX.doneData, () => {
    // sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('show_info', 'show');
    window.location.replace('/');
  })
  .on(accessDeniedlogoutUserFX.failData, () => {
    // sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('show_info', 'show');
    window.location.replace('/');
  })
  .on(authenticateUserFX.failData, () => {
    window.location.replace('/');
  })
  .on(authHelpDeskNumberFX.failData, () => {
    const { hide } = cogoToast.warn('Lietotājs ar šādu personas kodu nav atrasts', {
      hideAfter: 0,
      onClick: () => {
        hide?.();
      },
    });
  })
  .on(fetchUserProfileFX.doneData, (_, payload) => ({
    ...payload.data,
    accounts: {
      ...payload.data.accounts,
      selected_uuid: payload.data.accounts.natural_person.uuid,
    },
  }))
  .on(fetchUserTermsConsentFX.doneData, (_, payload) => ({
    ...payload.data,
    accounts: {
      ...payload.data.accounts,
      selected_uuid: payload.data.accounts.natural_person.uuid,
    },
  }))
  .on(fetchEntityProfileFX.doneData, (state, payload) => {
    const selected_uuid = payload.data.legal_entity_uuid;
    const legal_entities = payload.data.accounts.legal_entities;

    localStorage.setItem(LOCAL_STORAGE.UUID, selected_uuid);

    return state
      ? {
          ...state,
          accounts: {
            ...state!.accounts,
            selected_uuid,
            legal_entities,
          },
        }
      : state;
  })
  .on(changeUserSize, (state, payload) => {
    localStorage.getItem('size');

    return state
      ? {
          ...state,
          size: payload,
        }
      : state;
  })
  .on(setUserProfile, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(updateUserContactsFX.doneData, (state, payload) => ({
    ...state,
    ...payload.data,
  }))
  .on(patchUserProfileFX.doneData, (state, payload) => ({
    ...state,
    ...payload.data,
  }))
  .on(validateUserContactFX.doneData, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(deleteUserContactFX.doneData, (state, payload) => ({
    ...state,
    ...payload.data,
  }))
  .on(fetchExternalOrganizationsFX.doneData, (state, payload) =>
    state
      ? {
          ...state,
          external_organizations: payload?.data?.map((item: any) => ({
            label: item.organization_name,
            value: item.uuid,
          })),
        }
      : state
  )
  .on(setAccountUuidEV, (state, payload) => {
    localStorage.setItem(LOCAL_STORAGE.UUID, payload);

    return state
      ? {
          ...state,
          accounts: {
            ...state!.accounts,
            selected_uuid: payload,
          },
        }
      : state;
  });
