import { CatalogGroupService } from 'store/catalog/interfaces';

export interface BasketItem {
  uuid: string;
  service_version_uuid: string;
  service_code: string;
  service_name: string;
  quantity: number;
  service: CatalogGroupService | null;
  type: string;
  created_at: string;
  updated_at: string;
  description: string | null;
  may_be_multiple: boolean;
}

export enum BasketSubmissionType {
  NATURAL_PERSON = 'NATURAL_PERSON',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  ON_BEHALF_OF_BRANCH = 'ON_BEHALF_OF_BRANCH',
  ON_BEHALF_OF_ENTITY = 'ON_BEHALF_OF_ENTITY',
}

export interface BasketStore {
  uuid: string | null;
  total_price: string | null;
  created_at: string | null;
  updated_at: string | null;
  items_count: number;
  items: BasketItem[];
  service_group: string | null;
  submission_type: BasketSubmissionType | null;
}
