import { Router } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import { useTranslation } from 'react-i18next';
import Routes from 'routes/Routes';
import Header from 'components/header';
import Footer from 'components/footer/Footer';
import { history } from '@history';
import { MobileMenu } from 'components/mobile-menu/MobileMenu';
import { Visible } from 'react-grid-system';
import { LogoutModal } from 'components/logout-modal/LogoutModal';
import { HelpDeskModal } from 'components/helpdesk-modal/HelpDeskModal';
import { ErrorBoundary } from 'components/error-boundary/ErrorBoundary';
import { SessionExpireModal } from 'components/session-expire-modal/SessionExpireModal';
import { WithAuthWaiting } from 'components/with-auth-waiting/WithAuthWaiting';
import { PortalNotifications } from 'components/portal-notifications/PortalNotifications';
import CookiesAttention from './components/CookiesAttention';
import { ConsentToTerms } from './components/consent-to-terms/ConsentToTerms';
import { RenewalRequest } from './components/renewal-request/RenewalRequest';
import { ExternalUserModal } from './components/external-user/ExternalUserModal';

const App = () => {
  const { t, i18n } = useTranslation();
  const meta = {
    title: t('PUBLIC_SERVICE_PORTAL'),
  };

  localStorage.setItem('language', i18n.language);

  return (
    <ErrorBoundary>
      <Router history={history}>
        <DocumentMeta {...meta}>
          <Header />
        </DocumentMeta>
        <PortalNotifications />
        <Routes />
        <CookiesAttention />
        <Footer />
        <ConsentToTerms />
        <RenewalRequest />
        <LogoutModal />
        <ExternalUserModal />
        <HelpDeskModal />
        <Visible xs sm md>
          <MobileMenu />
        </Visible>
        <SessionExpireModal />
        <WithAuthWaiting />
      </Router>
    </ErrorBoundary>
  );
};

export default App;
