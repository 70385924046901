import { createEffect } from 'effector';
import { SignInit, SubmitState, SubmitValues } from 'pages/applications/submit/Submit';
import api from 'services/api';
import { EffectCallbacks } from 'store/shared/effect-callbacks';
import { getClassifiersFX } from '../../classifiers/effects';

const PATH = 'api-applications/api/v2/applications';
const PATHV2 = 'api-applications/api/v2/applications';
const Callbacks = new EffectCallbacks(PATH);
const CallbacksV2 = new EffectCallbacks(PATHV2);
const PATHV1 = 'api-applications/api/v1/applications';
const CallbacksV1 = new EffectCallbacks(PATHV1);

// PERSON

export const fetchApplicationFX = createEffect(Callbacks.fetchSingle);

export const fetchApplicationV2FX = createEffect(CallbacksV2.fetchSingle);

export const fetchApplicationsFX = createEffect(
  async ({ entity_id, ...params }: { entity_id: string }) => {
    const response = await api.get('/api-applications/api/v1/applications', { params });

    getClassifiersFX({ types: 'application_status,application_services_types' });

    return response.data;
  }
);

export const getClassifiersAppFX = createEffect(async (params = {}) => {
  const response = await api.get('api-applications/api/v2/classifiers', { params });

  return response.data;
});

export const getSharedLinkSubmitFormFX = createEffect(
  async ({ application_uuid }: { application_uuid: string }) => {
    const response =
      await api.get(`/api-applications/api/v2/applications/${application_uuid}/shared_payment
`);

    return response.data;
  }
);

export const getPaymentInfoInSharedLinkFX = createEffect(async ({ url }: { url: string }) => {
  const response = await api.get(`/api-applications/api/v2${url}
`);

  return response.data;
});

export const fetchApplicationsSubmitFormFX = createEffect(
  async ({ application_uuid }: { application_uuid: string }) => {
    const response = await api.get(`/api-applications/api/v2/applications/${application_uuid}`);

    return response.data;
  }
);

export const createApplicationFX = createEffect(Callbacks.create);

export const deleteApplicationFX = createEffect(CallbacksV1.delete);

// BEHALF_OF

export const fetchBehalfOfApplicationsFX = createEffect(async () => {
  const response = await api.get('/api-applications/api/v1/applications');

  return response.data;
});

export const createBehalfOfApplicationFX = createEffect(
  async ({ behalf_of_uuid }: { behalf_of_uuid: string }) => {
    const response = await api.post(`/api-applications/api/v2/applications/${behalf_of_uuid}`);

    return response.data;
  }
);

export const confirmLegalEntityApplicationFX = createEffect(async (entity_id: string) => {
  const response = await api.post(
    `/api-profile/api/v1/legal_entities/${entity_id}/profile/confirm`,
    {
      need_profile_review: false,
    }
  );

  return response.data;
});

// LEGAL ENTITY
export const fetchLegalEntityApplicationsFX = createEffect(
  async ({ entity_id, ...params }: { entity_id: string }) => {
    const response = await api.get(
      `/api-applications/api/v1/legal_entities/${entity_id}/applications`,
      { params }
    );

    return response.data;
  }
);

export const createLegalEntityApplicationFX = createEffect(
  async ({ entity_id }: { entity_id: string }) => {
    const response = await api.post(
      `/api-applications/api/v2/legal_entities/${entity_id}/applications`
    );

    return response.data;
  }
);

// DEPENDENT LEGAL ENTITY

export const fetchDependentApplicationFX = createEffect(
  async ({ entity_id }: { entity_id: string; behalf_of_uuid: string }) => {
    const response = await api.get(
      `/api-applications/api/v1/legal_entities/${entity_id}/applications`
    );

    return response.data;
  }
);

export const fetchDependentApplicationsFX = createEffect(
  async ({ entity_id }: { entity_id: string; behalf_of_uuid: string }) => {
    const response = await api.get(
      `/api-applications/api/v1/legal_entities/${entity_id}/applications`
    );

    return response.data;
  }
);

export const createDependentApplicationFX = createEffect(
  async ({ entity_id, behalf_of_uuid }: { entity_id: string; behalf_of_uuid: string }) => {
    const response = await api.post(
      `/api-applications/api/v2/legal_entities/${entity_id}/applications/behalf_of/${behalf_of_uuid}`
    );

    return response.data;
  }
);

// COMMON

export const reviewApplicationFX = createEffect(async ({ uuid, ...rest }: { uuid: string }) => {
  const response = await api.post(`/api-applications/api/v1/applications/${uuid}/review`, rest);

  return response.data;
});

export const submitApplicationFX = createEffect(async ({ uuid }: { uuid: string }) => {
  const response = await api.post(`/api-applications/api/v1/applications/${uuid}/submit`);

  return response.data;
});

export const generateDocumentsFX = createEffect(async ({ uuid, ...rest }: { uuid: string }) => {
  const response = await api.post(`/api-applications/api/v2/applications/${uuid}/documents`, rest);

  return response.data;
});

export const postApplicationSignInit = createEffect(
  async ({ application_uuid, document_uuid, ...rest }: Partial<SignInit>) => {
    const response = await api.post(
      `/api-applications/api/v2/applications/${application_uuid}/documents/${document_uuid}/sign/init`,
      rest
    );

    return response;
  }
);

export const postApplicationSignDone = createEffect(
  async ({ application_uuid, document_uuid, ...rest }: Partial<SignInit>) => {
    const response = await api.post(
      `/api-applications/api/v2/applications/${application_uuid}/documents/${document_uuid}/sign/done`,
      rest
    );

    return response;
  }
);

export const patchApplicationFX = createEffect(
  async ({ uuid, withRedirect, ...rest }: Partial<SubmitValues>) => {
    const response = await api.patch(`/api-applications/api/v2/applications/${uuid}`, rest);
    const data = { ...response.data.data, withRedirect };

    delete data.additional_options;

    return data;
  }
);

export const getApplicationShareDocumentsFX = createEffect(
  async ({ uuid }: Partial<SubmitValues>) => {
    const response = await api.get(`/api-applications/api/v2/applications/${uuid}/share_documents`);
    const data = { ...response.data.data };

    return data;
  }
);

export const getPaymentInfoFX = createEffect(
  async ({ payment_request_id, payment_pending }: any) => {
    const response = await api.get(
      payment_pending
        ? `/api-applications/api/v2/payments/check-status/${payment_request_id}/payment_pending`
        : `/api-applications/api/v2/payments/check-status/${payment_request_id}`
    );
    const data = { ...response.data.data };

    return data;
  }
);

export const markPaymentPendingFX = createEffect(async ({ payment_request_id }: any) => {
  const response = await api.get(
    `/api-applications/api/v2/payments/check-status-guest/${payment_request_id}`
  );
  const data = { ...response.data.data };

  return data;
});

export const getSubmissionsShareDocumentsFX = createEffect(
  async ({ uuid }: Partial<SubmitValues>) => {
    const response = await api.get(`/api-applications/api/v2/${uuid}`);
    const data = { ...response.data.data };

    return data;
  }
);

export const patchApplicationShareDocumentsFX = createEffect(
  async ({ uuid, ...rest }: Partial<SubmitValues>) => {
    const response = await api.patch(
      `/api-applications/api/v2/applications/${uuid}/share_documents`,
      rest
    );
    const data = { ...response.data.data };

    return data;
  }
);

export const checkRulesApplicationFX = createEffect(
  async ({ uuid, withRedirect, ...rest }: Partial<SubmitValues>) => {
    const response = await api.post(
      `/api-applications/api/v2/applications/${uuid}/rules/check`,
      rest
    );
    const data = { ...response.data.data, withRedirect };

    delete data.additional_options;

    return data;
  }
);

export const postApplicationState = createEffect(
  async ({ uuid, withRedirect, state }: Partial<SubmitState>) => {
    const response = await api.post(`/api-applications/api/v2/applications/${uuid}/state/${state}`);
    const data = { ...response.data.data, withRedirect };

    delete data.additional_options;

    return data;
  }
);

export const checkCompanyNameFX = createEffect(async (params: { entity_name: string }) => {
  const response = await api.get('/api-applications/api/v1/check_name', { params });

  return response.data;
});

export const fetchAdditionalOptionsFX = createEffect(async ({ uuid }: { uuid: string }) => {
  const response = await api.get(
    `/api-applications/api/v2/applications/${uuid}/additional_options`
  );

  return response.data;
});

export const setApplicationRatingFX = createEffect(
  async ({ uuid, score, notes }: { uuid: string; score: number; notes?: string }) => {
    const response = await api.post(`/api-applications/api/v2/applications/${uuid}/user_rating`, {
      score,
      notes,
    });

    return response.data;
  }
);

export const verificateApplicationEmailFX = createEffect(
  async ({ uuid, value }: { uuid: string; value: string }) => {
    const response = await api.post(
      `/api-applications/api/v2/applications/${uuid}/email_verification`,
      {
        email: value,
      }
    );

    return response.data;
  }
);

export const validateApplicationEmailFX = createEffect(
  async ({ code, key }: { code: string; key: string }) => {
    const response = await api.post(`/api-profile/api/v1/contact_validate/${key}/${code}`);

    return response.data;
  }
);

// export const UploadFileFormFieldsFX = createEffect(async ({ file }: { file: File }) => {
//   const formData = new FormData();
//   formData.append('file', file);
//
//   const response = await api.post('/api-applications/api/v1/files', formData);
//
//   return response.data;
// });

export const reneitiateApplicationFX = createEffect(
  async ({ uuid, withRedirect, ...rest }: Partial<SubmitValues>) => {
    const response = await api.patch(
      `/api-applications/api/v2/applications/${uuid}/reneitiate`,
      rest
    );
    const data = { ...response.data.data, withRedirect };

    delete data.additional_options;

    return data;
  }
);

export const getNotaryListFX = createEffect(async () => {
  const response = await api.get('/api-applications/api/v2/notaries');

  return response.data;
});
