import { createBrowserHistory } from 'history';
import { clearSelectedBehalfEV } from 'store/shared/events';

export const history = createBrowserHistory();

const onBehalfExceptionUrlRegex = /^(?!.*success).*(catalog|applications)/;

history.listen((route: { pathname: string }) => {
  if (!onBehalfExceptionUrlRegex.test(route.pathname)) {
    clearSelectedBehalfEV();
  }
});
