import * as Sentry from '@sentry/react';
import { FallbackRender } from '@sentry/react/dist/errorboundary';
import { Component } from 'react';

interface IProps {
  fallbackComponent?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | FallbackRender
    | undefined;
}

const FallbackContent = () => (
  <div style={{ textAlign: 'center' }}>
    <p>An error has occurred</p>
    <p>
      <a href="/">Reload page</a>
    </p>
  </div>
);

export class ErrorBoundary extends Component<IProps, any> {
  isProduction = process.env.NODE_ENV !== 'development';

  componentDidCatch(error: Error) {
    if (this.isProduction) {
      this.setState({ error });
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.isProduction) {
      return (
        <Sentry.ErrorBoundary fallback={this.props.fallbackComponent || FallbackContent}>
          {this.props.children}
        </Sentry.ErrorBoundary>
      );
    }

    return this.props.children;
  }
}
