import { lazy } from 'react';

export const Auth = lazy(() => import('../pages/auth/Auth'));

export const EntityList = lazy(() => import('../pages/entities/list/EntitiesList'));

export const Profile = lazy(() => import('../pages/profile/Profile'));

export const Maintenance = lazy(() => import('../pages/maintenance/Maintenance'));

export const SigningDocument = lazy(() => import('../pages/signing-document/SigningDocument'));

export const NotFoundPage = lazy(() => import('../pages/not-found/NotFound'));

export const Delegations = lazy(() => import('../pages/delegations/Delegations'));

export const Catalog = lazy(() => import('../pages/catalog/Catalog'));

export const Applications = lazy(() => import('../pages/applications/Applications'));

export const ApplicationsSubmit2 = lazy(() => import('../pages/applications/submit/Submit'));

export const ApplicationsSubmit = lazy(
  () => import('../pages/applications/submit-form/SubmitForm')
);

export const Submissions = lazy(() => import('../pages/submissions/Submissions'));

export const ApplicationsSubmitSuccess = lazy(
  () => import('../pages/applications/submit-success/SubmitSuccess')
);

export const ApplicationsPayment = lazy(() => import('../pages/applications/payment/Payment'));

export const ApplicationsSharedPayment = lazy(
  () => import('../pages/applications/payment/SharedPayment')
);

export const ApplicationsPaymentPending = lazy(
  () => import('../pages/applications/payment/PaymentPending')
);

export const ApplicationsPaymentRequest = lazy(
  () => import('../pages/applications/payment/PaymentRequest')
);

export const ApplicationsView = lazy(() => import('../pages/applications/view/ApplicationView'));

export const StaticPage = lazy(() => import('../pages/static/Static'));

export const FeedbackPage = lazy(() => import('../pages/helpdesk/Feedback'));

export const DependentsPage = lazy(() => import('../pages/dependents/Dependents'));

export const ContractsPage = lazy(() => import('../pages/contracts/Contracts'));

export const ContractsViewPage = lazy(() => import('../pages/contracts/view/ContractView'));

export const HistoryPage = lazy(() => import('../pages/history/History'));

export const PaymentPage = lazy(() => import('../pages/payment/PaymentPage'));

export const CommunicationPage = lazy(() => import('../pages/communication/Communication'));
