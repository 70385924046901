import { useEffect, useState } from 'react';
import cn from 'classnames';

export const Theme = () => {
  const body = document.querySelector('body');
  const themeName: string = localStorage.getItem('theme') || 'contrast-wp';
  const helpDeskSerialNumber = localStorage.getItem('helpdesk_user_serial_number');
  const [theme, setTheme] = useState(themeName);
  const authProvider = localStorage.getItem('auth_provider') || '';

  const isHelpDeskMode =
    ['help_desk_ldap', 'help_desk_dummy_ldap'].includes(authProvider) && helpDeskSerialNumber;

  useEffect(() => {
    localStorage.setItem('theme', theme);

    if (body && theme) {
      body.className = '';
      body.classList.add(theme);
      isHelpDeskMode && body.classList.add('helpDeskMode');
    }
  }, [theme]);

  return (
    <div className="bx--tooltip__footer">
      {['contrast-wp', 'contrast-bw', 'contrast-yb', 'contrast-by', 'contrast-wb'].map((item) => (
        <button
          key={item}
          className={cn(`theme ${item}`, { active: theme === item })}
          type="button"
          aria-expanded="false"
          aria-label={item}
          onClick={() => setTheme(item)}
          title={item}
        />
      ))}
    </div>
  );
};
