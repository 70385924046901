import { createEvent } from 'effector';
import { ProfileTypeEnum } from '../interface';

export const setProfileTypeEV = createEvent<ProfileTypeEnum>();

export const updateLastActivityEV = createEvent();

export const updateActiveServicesKeyEV = createEvent<any>();

export const setApplicationsUuidEV = createEvent<any>();

export const updateTabLinksEV = createEvent<any>();

export const updateGenerateDocumentsEV = createEvent<any>();
