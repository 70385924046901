import { FC } from 'react';
import * as constants from './constants';
import * as components from './components';

export interface BreadcrumbProps {
  title: string;
  path: string;
}

const createRoute = (
  path: string,
  component: FC,
  breadcrumbs: BreadcrumbProps[] = [],
  exact = true
) => ({
  component,
  exact,
  path,
  breadcrumbs,
});

export const routes = [
  createRoute('/', components.Auth),
  createRoute(constants.ENTITIES.LIST, components.EntityList, [
    { title: 'Entities', path: constants.ENTITIES.LIST },
  ]),
  createRoute(constants.USER_PROFILE, components.Profile, [{ title: 'Profile', path: '' }]),
  createRoute(constants.MAINTENANCE, components.Maintenance, [{ title: 'Maintenance', path: '' }]),
  createRoute(constants.SIGNING_DOCUMENT, components.SigningDocument, [
    { title: 'SigningDocument', path: '' },
  ]),
  createRoute(constants.CATALOG.LIST, components.Catalog, [
    { title: 'Service Catalogue', path: '' },
  ]),
  createRoute(constants.DELEGATIONS.LIST, components.Delegations, [
    { title: 'Delegations', path: '' },
  ]),
  createRoute(constants.APPLICATIONS.LIST, components.Applications, [
    { title: 'Applications', path: '' },
  ]),
  createRoute(constants.APPLICATIONS.SUBMIT_SUCCESS, components.ApplicationsSubmitSuccess, [
    { title: 'Success', path: '' },
  ]),
  createRoute(constants.APPLICATIONS.PAYMENT, components.ApplicationsPayment, [
    { title: 'Fill & Upload', path: '' },
  ]),
  createRoute(constants.APPLICATIONS.SHARED_PAYMENT, components.ApplicationsSharedPayment, [
    { title: 'Fill & Upload', path: '' },
  ]),
  createRoute(constants.APPLICATIONS.PAYMENT_PENDING, components.ApplicationsPaymentPending, [
    { title: 'Fill & Upload', path: '' },
  ]),
  createRoute(constants.APPLICATIONS.PAYMENT_REQUEST, components.ApplicationsPaymentRequest, [
    { title: 'Fill & Upload', path: '' },
  ]),
  createRoute(constants.APPLICATIONS.SUBMIT, components.ApplicationsSubmit, [
    { title: 'Fill & Upload', path: '' },
  ]),
  createRoute(constants.SUBMISSIONS.VIEW, components.Submissions, [
    { title: 'Submissions', path: constants.SUBMISSIONS.VIEW },
  ]),
  createRoute(constants.APPLICATIONS.SUBMIT_FORM, components.ApplicationsSubmit2, [
    { title: 'Fill & Upload', path: '' },
  ]),
  createRoute(constants.APPLICATIONS.VIEW, components.ApplicationsView, [
    { title: 'Review & Submit', path: '' },
  ]),
  createRoute(constants.STATIC_PAGE, components.StaticPage),
  createRoute(constants.HELPDESK_PAGE, components.FeedbackPage),
  createRoute(constants.DEPENDENTS, components.DependentsPage),

  createRoute(constants.CONTRACTS.LIST, components.ContractsPage, [
    { title: 'Contracts', path: constants.CONTRACTS.LIST },
  ]),
  createRoute(constants.CONTRACTS.VIEW, components.ContractsViewPage, [
    { title: 'Contract', path: '' },
  ]),
  createRoute(constants.HISTORY.LIST, components.HistoryPage, [{ title: 'History', path: '' }]),
  createRoute(constants.PAYMENT.LIST, components.PaymentPage, [{ title: 'Payments', path: '' }]),
  createRoute(constants.COMMUNICATION.LIST, components.CommunicationPage, [
    { title: 'Communication', path: constants.COMMUNICATION.LIST },
  ]),
];
