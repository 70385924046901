import { createEffect } from 'effector';
import api from 'services/api';
import { RequestParams } from '@types';
import { fetchUserProfileFX } from 'store/user/effects';
import { fetchEntityProfileFX } from 'store/entities/effects';
import { getClassifiersFX } from '../../classifiers/effects';

const DELIGATIONS_PERSON_PATH = 'api-profile/api/v1/delegations';
const DELIGATIONS_LEGAL_PATH = 'api-profile/api/v1/legal_entities';

export const fetchDelegationsFX = createEffect(async ({ entity_id, ...params }: RequestParams) => {
  const response = await api.get(DELIGATIONS_PERSON_PATH, {
    params,
  });

  getClassifiersFX({ types: 'delegation_status,delegation_permission' });

  return response.data;
});

export const revokeDeligationAccessFX = createEffect(async ({ uuid }: any) => {
  const response = await api.patch(`${DELIGATIONS_PERSON_PATH}/${uuid}/cancel`);

  fetchUserProfileFX({});

  return response.data.data;
});

export const fetchLegalDelegationsFX = createEffect(
  async ({ entity_id, ...params }: RequestParams) => {
    const response = await api.get(`${DELIGATIONS_LEGAL_PATH}/${entity_id}/delegations`, {
      params,
    });

    return response.data;
  }
);

export const createLegalDeligationFX = createEffect(
  async ({ entity_id, ...payload }: RequestParams) => {
    const response = await api.post(`${DELIGATIONS_LEGAL_PATH}/${entity_id}/delegations`, payload);

    return response.data.data;
  }
);

export const updateLegalDeligationFX = createEffect(
  async ({ entity_id, uuid, ...payload }: RequestParams) => {
    const response = await api.patch(
      `${DELIGATIONS_LEGAL_PATH}/${entity_id}/delegations/${uuid}`,
      payload
    );

    return response.data.data;
  }
);

export const revokeLegalDeligationAccessFX = createEffect(
  async ({ entity_id, uuid }: RequestParams) => {
    const response = await api.patch(
      `${DELIGATIONS_LEGAL_PATH}/${entity_id}/delegations/${uuid}/cancel`
    );

    fetchEntityProfileFX(entity_id!);

    return response.data.data;
  }
);
