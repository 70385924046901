import { createStore } from 'effector';
import { uploadFileFX } from '../shared/effects';
import {
  fetchApplicationsFX,
  fetchApplicationFX,
  reviewApplicationFX,
  submitApplicationFX,
  fetchLegalEntityApplicationsFX,
  deleteApplicationFX,
  patchApplicationFX,
  fetchAdditionalOptionsFX,
  fetchBehalfOfApplicationsFX,
  reneitiateApplicationFX,
  createApplicationFX,
  fetchApplicationV2FX,
  getPaymentInfoFX,
} from './effects';
import {
  createQueryParamsEV,
  setApplicationsStateEV,
  setApplicationsTotalPriceEV,
  clearApplicationsQueryParamsEV,
} from './events';
import { ApplicationsStore } from './interfaces';

const initialState = {
  list: [],
  total: 0,
  submitData: null,
  successSubmitData: null,
  displayDocEP119status: false,
  additional_options: {
    supporting_services: [],
    fast_processing: [],
    available_reliefs: [],
  },
  displayDocsExternallyModal: false,
  queryParams: undefined,
};

export const $applicationsStore = createStore<ApplicationsStore>(initialState)
  .on(fetchApplicationFX.doneData, (state, payload) => ({
    ...state,
    submitData: payload.data,
  }))
  .on(fetchApplicationV2FX.doneData, (state, payload) => ({
    ...state,
    submitData: payload.data,
  }))
  .on(getPaymentInfoFX.doneData, (state, payload) => ({
    ...state,
    submitData: payload,
  }))
  .on(createApplicationFX.doneData, (state, payload) => ({
    ...state,
    submitData: payload.data,
  }))

  .on(fetchBehalfOfApplicationsFX.doneData, (state, payload) => ({
    ...state,
    submitData: payload.data,
  }))
  // .on(fetchApplicationsSubmitFormFX.doneData, (state, payload) => ({
  //   ...state,
  //   submitData: payload.data,
  // }))
  .on(fetchApplicationsFX.doneData, (state, payload) => ({
    ...state,
    ...payload.data,
  }))
  .on(fetchLegalEntityApplicationsFX.doneData, (state, payload) => ({
    ...state,
    ...payload.data,
  }))
  .on(setApplicationsStateEV, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(patchApplicationFX.doneData, (state, payload) => ({
    ...state,
    submitData: {
      ...state.submitData,
      ...payload,
    },
  }))
  .on(uploadFileFX.doneData, (state, payload) => ({
    ...state,
    submitData: {
      ...state.submitData,
      ...payload,
    },
  }))
  .on(reneitiateApplicationFX.doneData, (state, payload) => ({
    ...state,
    submitData: {
      ...state.submitData,
      ...payload,
    },
  }))
  .on(deleteApplicationFX.doneData, (state, payload) => ({
    ...state,
    list: state.list.filter((item) => item.uuid !== payload),
    total: state.total - 1,
  }))
  .on(submitApplicationFX.doneData, (state, payload) => ({
    ...state,
    successSubmitData: payload.data,
  }))
  .on(
    setApplicationsTotalPriceEV,
    (state, payload) =>
      ({
        ...state,
        submitData: {
          ...state.submitData,
          total_price: payload,
        },
      } as any)
  )
  .on(fetchAdditionalOptionsFX.doneData, (state, payload) => ({
    ...state,
    additional_options: payload.data,
  }))
  .on(reviewApplicationFX.doneData, (state, payload) => ({
    ...state,
    submitData: payload.data,
  }))
  // queryParams
  .on(createQueryParamsEV, (state, payload) => ({ ...state, queryParams: payload }))
  .on(clearApplicationsQueryParamsEV, (state, payload) => ({ ...state, queryParams: payload }));
