import { FC, useEffect } from 'react';
import { useStore } from 'effector-react';
import { InlineNotification } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import { loadNotificationsEV } from './store/events';
import { $portalNotificationsStore, Notification } from './store';

const getNotificationTranslation = (
  notification: Notification,
  lang: string
): string | undefined => {
  if (lang === 'lv') {
    return notification.content;
  }

  return notification.translations?.find((t) => t[lang])?.[lang];
};

export const PortalNotifications: FC<any> = () => {
  const { i18n } = useTranslation();
  const { notifications } = useStore($portalNotificationsStore);
  const closedNotifications = JSON.parse(localStorage.getItem('closedNotifications') || '[]');

  const handleClose = (code: string) => {
    localStorage.setItem(
      'closedNotifications',
      JSON.stringify([...JSON.parse(localStorage.getItem('closedNotifications') || '[]'), code])
    );
  };

  useEffect(() => {
    api
      .get('/api-profile/api/v1/notifications')
      .then((response) => {
        loadNotificationsEV(response.data?.data);
      })
      .catch(() => {});
  }, []);

  if (!notifications?.length) {
    return <></>;
  }

  return (
    <div className="portal-notifications">
      {notifications.map(
        (notification) =>
          !closedNotifications.includes(notification.code) &&
          getNotificationTranslation(notification, i18n.language) && (
            <InlineNotification
              id="notification"
              key={notification.code}
              kind={notification.level}
              lowContrast={true}
              role="banner"
              title={''}
              subtitle={
                <div
                  dangerouslySetInnerHTML={{
                    __html: getNotificationTranslation(notification, i18n.language) || '',
                  }}
                />
              }
              iconDescription="describes the close button"
              statusIconDescription="describes the status icon"
              hideCloseButton={false}
              onCloseButtonClick={() => {
                handleClose(notification.code);
              }}
              className="web-epak__profile-notification"
            />
          )
      )}
    </div>
  );
};
