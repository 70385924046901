import { createStore } from 'effector';
import moment from 'moment';
import { fetchEntityProfileFX } from 'store/entities/effects';
import { refreshTokenEV } from 'store/user/events';
import {
  setProfileTypeEV,
  updateActiveServicesKeyEV,
  updateLastActivityEV,
  setApplicationsUuidEV,
  updateTabLinksEV,
  updateGenerateDocumentsEV,
} from './events';
import { ProfileTypeEnum } from './interface';

const initialState = {
  profile_type: ProfileTypeEnum.PERSON,
  lastActivity: moment(),
  activeServicesKey: 'services',
  applications_uuid: null,
  generate_documents: false,
  tabLinks: {
    services: 'active',
    data_entry: 'inactive',
    documents: 'inactive',
    overview: 'inactive',
    // payment: 'inactive',
    success: 'inactive',
  } as any,
};

export const $mainStore = createStore(initialState)
  .on(setProfileTypeEV, (state, payload) => ({
    ...state,
    profile_type: payload,
  }))
  .on(updateActiveServicesKeyEV, (state, payload) => ({
    ...state,
    activeServicesKey: payload,
  }))
  .on(updateTabLinksEV, (state, payload) => ({
    ...state,
    tabLinks: payload,
  }))
  .on(updateGenerateDocumentsEV, (state, payload) => ({
    ...state,
    generate_documents: payload,
  }))
  .on(setApplicationsUuidEV, (state, payload) => ({
    ...state,
    applications_uuid: payload,
  }))
  .on(fetchEntityProfileFX.doneData, (state) => ({
    ...state,
    profile_type: ProfileTypeEnum.LEGAL_ENTITY,
  }))
  .on(updateLastActivityEV, (state) => ({
    ...state,
    lastActivity: moment(),
  }))
  .on(refreshTokenEV, (state) => ({
    ...state,
    lastActivity: moment(),
  }));
