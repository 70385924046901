export const requestStaticPage = async (endpoint: string) => {
  const browserLang = navigator.language;
  const language = localStorage.getItem('language');

  const fetchObj: RequestInit = {
    method: 'GET',
    credentials: 'omit',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      language: language || browserLang,
    }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_STATIC_URL!}/api/v1/blocks?` +
        new URLSearchParams({ id: endpoint, lang: language || browserLang }),
      fetchObj
    );

    if (response.ok) {
      return { success: await response.json() };
    } else if (response.status >= 400 && response.status < 500) {
      return { warning: await response.json() };
    }

    return { error: await response.json() };
  } catch (error) {
    return { error };
  }
};
