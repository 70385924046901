import { RequestParams } from '@types';
import * as callbacks from './callbacks';

export class EffectCallbacks {
  path: string;

  constructor(path: string) {
    this.path = path;
  }

  fetchList = (params: RequestParams) => callbacks.fetchListCallback(this.path, params);

  fetchSingle = (params: RequestParams) => callbacks.fetchSingleCallback(this.path, params);

  update = <Module>(payload: Module) => callbacks.updateCallback(this.path, payload);

  create = <Module>(payload: Module) => callbacks.createCallback(this.path, payload);

  delete = ({ uuid }: { uuid: string }) => callbacks.deleteCallback(this.path, { uuid });

  patch = <Module>(payload: Module) => callbacks.patchCallback(this.path, payload);
}
