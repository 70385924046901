import { useTranslation } from 'react-i18next';
import { HeaderNavigation, HeaderMenuItem } from 'carbon-components-react';
import { useHistory } from 'react-router';
import {
  APPLICATIONS,
  CATALOG,
  COMMUNICATION,
  CONTRACTS,
  DELEGATIONS,
  DEPENDENTS,
  ENTITIES,
  HISTORY,
  PAYMENT,
  USER_PROFILE,
} from 'routes/constants';
import './styles.scss';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { Emitter } from 'utils/event-emitter';
import { SHOW_HELPDESK_MODAL, SHOW_LOGOUT_MODAL } from 'utils/event-emitter/constants';
import { EE_HIDE_MOBILE_NAV } from 'components/mobile-menu/consts';
import { useProfile } from 'hooks/useProfile';
import { useStore } from 'effector-react';
import { $communicationStore } from 'store/communication';
import { $historyStore } from '../../store/history';
import { $applicationsStore } from '../../store/applications';
import { $delegationsStore } from '../../store/delegations';
import { $userStore } from '../../store/user';
import { $paymentStore } from '../../store/payment';

export const Navigation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [activeItem, setActiveItem] = useState('');
  const isMobile = useMedia('(max-width: 991px)');
  const { data, isPerson } = useProfile();
  const HistoryQueryParams = useStore($historyStore).queryParams;
  const PaymentQueryParams = useStore($paymentStore).queryParams;
  const ApplicationQueryParams = useStore($applicationsStore).queryParams;
  const DelegationsQueryParams = useStore($delegationsStore).queryParams;
  const CommunicationQueryParams = useStore($communicationStore).queryParams;
  const authProvider = localStorage.getItem('auth_provider');
  const userSerialNumber = localStorage.getItem('helpdesk_user_serial_number');
  const user = useStore($userStore);
  const person = user?.accounts?.natural_person;
  const selectedEntityId = user?.accounts?.selected_uuid;
  const isPersonSelected = selectedEntityId === person?.uuid;
  const isInternalUser = data?.mode === 'internal';
  const isExternalUser = data?.mode === 'external';
  const isCommunticationVisible = process.env.REACT_APP_COMMUNICATION_VISIBLE === 'true';

  const filter = (value?: any) => {
    let obj = {};

    if (value) {
      for (const key in value) {
        if (key.indexOf('select_') === -1) {
          if (value[key]) {
            const prop = JSON.parse(`{"${key}": "${value[key].replace(/"/g, "'")}"}`);
            obj = { ...obj, ...prop };
          }
        }
      }
    }

    return obj;
  };

  useEffect(() => {
    history.listen((h) => setActiveItem(h.pathname));
  }, []);

  const menuItems = [
    ...(!isInternalUser
      ? [
          {
            label: t('ENTITIES'),
            condition:
              (isPerson && data?.accounts?.legal_entities_count > 0) ||
              (isPersonSelected &&
                user?.accounts?.legal_entities_count &&
                user?.accounts?.legal_entities_count > 0),
            url: ENTITIES.LIST,
            isActive: activeItem.includes(ENTITIES.LIST),
          },
        ]
      : []),
    ...(!isInternalUser
      ? [
          {
            label: t('PROFILE'),
            condition: true,
            url: USER_PROFILE,
            isActive: activeItem.includes(USER_PROFILE),
          },
        ]
      : []),
    {
      label: t('SERVICE_CATALOG'),
      condition: !data?.need_profile_submit,
      url: CATALOG.LIST,
      isActive: activeItem.includes(CATALOG.LIST),
    },
    {
      label: t('SUBMISSIONS'),
      condition: !data?.need_profile_submit,
      url: APPLICATIONS.LIST,
      isActive: activeItem.includes(APPLICATIONS.LIST),
      extraClass: 'allways-clickable',
      queryParams: filter(ApplicationQueryParams),
    },
    ...(!isInternalUser
      ? [
          {
            label: t('CONTRACTS'),
            condition: !data?.need_profile_submit && data.has_legal_facts,
            url: CONTRACTS.LIST,
            isActive: activeItem.includes(CONTRACTS.LIST),
          },
        ]
      : []),
    ...(!isInternalUser
      ? [
          {
            label: t('DEPENDENTS'),
            condition: !isPerson && !data?.need_profile_submit && data.has_dependants,
            url: DEPENDENTS,
            isActive: activeItem.includes(DEPENDENTS),
          },
        ]
      : []),
    ...(!isInternalUser && !isExternalUser
      ? [
          {
            label: t('PAYMENTS'),
            condition: !data?.need_profile_submit,
            url: PAYMENT.LIST,
            isActive: activeItem.includes(PAYMENT.LIST),
            queryParams: filter(PaymentQueryParams),
          },
        ]
      : []),
    {
      label: t('HISTORY'),
      condition: !data?.need_profile_submit,
      url: HISTORY.LIST,
      isActive: activeItem.includes(HISTORY.LIST),
      queryParams: filter(HistoryQueryParams),
    },
    ...(!isInternalUser
      ? [
          {
            label: t('DELEGATIONS'),
            condition: !data?.need_profile_submit,
            url: DELEGATIONS.LIST,
            isActive: activeItem.includes(DELEGATIONS.LIST),
            queryParams: filter(DelegationsQueryParams),
          },
        ]
      : []),
    ...(!isInternalUser && isCommunticationVisible
      ? [
          {
            label: t('COMMUNICATION.TITLE'),
            condition: !data?.need_profile_submit,
            url: COMMUNICATION.LIST,
            isActive: activeItem.includes(COMMUNICATION.LIST),
            queryParams: filter(CommunicationQueryParams),
          },
        ]
      : []),
    ...(isMobile && (authProvider === 'help_desk_ldap' || authProvider === 'help_desk_dummy_ldap')
      ? [
          {
            label: userSerialNumber || '',
            condition: true,
            url: '',
            isActive: false,
            onClick: () => Emitter.emit(SHOW_HELPDESK_MODAL),
          },
        ]
      : []),
    ...(isMobile
      ? [
          {
            label: t('SIGN-OUT'),
            condition: true,
            url: '',
            isActive: false,
            onClick: () => Emitter.emit(SHOW_LOGOUT_MODAL),
          },
        ]
      : []),
  ].filter((route: any) => !!route?.condition);

  const handleClick = (item: any) => {
    if (item.onClick) {
      item.onClick();
    } else {
      history.push(item.url);
      Emitter.emit(EE_HIDE_MOBILE_NAV);
    }
  };

  return (
    <HeaderNavigation aria-label="UR menu" className="navigation">
      {menuItems.map((item: any) => (
        <HeaderMenuItem
          key={item.url}
          className={cn(
            'web-epak-dashboard__item-menu',
            {
              active: item.isActive,
            },
            item?.extraClass
          )}
          onClick={() => handleClick(item)}
          onKeyDown={(e: { keyCode: number }) => {
            if (e.keyCode === 13) {
              handleClick(item);
            }
          }}
        >
          {item.label}
        </HeaderMenuItem>
      ))}
    </HeaderNavigation>
  );
};
