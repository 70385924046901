import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const langs = ['lv', 'en'];

export const Languages = () => {
  const { i18n } = useTranslation();

  return (
    <div className="web-epak-header__languages">
      {langs.map((lang) => (
        <button
          id={`language_${lang}`}
          key={lang}
          onClick={() => i18n.changeLanguage(lang)}
          className={cn('language', {
            active: i18n.language === lang,
          })}
        >
          {lang}
        </button>
      ))}
    </div>
  );
};
