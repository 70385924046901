import { history } from '@history';
import { createEvent } from 'effector';
import { CATALOG, USER_PROFILE } from 'routes/constants';
import { fetchEntityProfileFX, validateEntityContactFX } from '../effects';

export const setEntityProfile = createEvent<any>();

export const fetchEntityProfile = createEvent<any>();

export const validateEntityContact = createEvent<any>();

fetchEntityProfile.watch((payload) => {
  fetchEntityProfileFX(payload).then((res) => {
    res.data.need_profile_submit || res.data.need_profile_review
      ? history.push(USER_PROFILE)
      : history.push(CATALOG.LIST);
  });
});

validateEntityContact.watch(validateEntityContactFX);
