import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useState, FC, useRef, useEffect } from 'react';
import { Tooltip } from 'carbon-components-react';
import { ENTITIES } from 'routes/constants';
import { useStore } from 'effector-react';
import { $userStore } from 'store/user';
import { Close16, Contrast24, Home24 } from '@carbon/icons-react';
import { Hidden } from 'react-grid-system';
import { TopNavbar } from 'components/top-navbar/TopNavbar';
import { useTranslation } from 'react-i18next';
import { useProfile } from 'hooks/useProfile';
import { useHistory } from 'react-router';
import { clearApplicationsQueryParamsEV } from 'store/applications/events';
import { createQueryParamsEV, clearCommunicationQueryParamsEV } from 'store/communication/events';
import { clearDelegationsQueryParamsEV } from 'store/delegations/events';
import { clearHistoryQueryParamsEV } from 'store/history/events';
import { setAccountUuidEV } from 'store/user/events';
import { setProfileTypeEV } from '../../../store/main/events';
import { ProfileTypeEnum } from '../../../store/main/interface';
import { FontSize } from './components/font-size/FontSize';
import { Theme } from './components/theme/Theme';
import { Languages } from './components/languages/Languages';
import { Logout } from './components/logout/Logout';
import { Logo } from './components/logo/Logo';

interface MainHeaderProps {
  refHeader: React.MutableRefObject<HTMLDivElement | null>;
}

export const MainHeader: FC<MainHeaderProps> = ({ refHeader }) => {
  const { t } = useTranslation();
  const user = useStore($userStore);
  const [themesState, setThemesState] = useState(false);
  const firstRun = useRef(true);
  const body = document.querySelector('body');
  const themeName: string = localStorage.getItem('theme') || 'contrast-wp';
  const helpDeskSerialNumber = localStorage.getItem('helpdesk_user_serial_number');
  const { data } = useProfile();
  const history = useHistory();
  const authProvider = localStorage.getItem('auth_provider') || '';

  const isHelpDeskMode =
    ['help_desk_ldap', 'help_desk_dummy_ldap'].includes(authProvider) && helpDeskSerialNumber;
  const isInternalUser = data?.mode === 'internal';

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;

      if (body) {
        body.className = '';
        body.classList.add(themeName);
        isHelpDeskMode && body.classList.add('helpDeskMode');
      }
    }
  });

  useEffect(() => {
    const size = localStorage.getItem('size');
    const html = document.querySelector('html');

    if (html && size) {
      html.dataset.size = size;
    }
  }, []);

  const handleHomeClick = () => {
    history.block()();

    if (localStorage.getItem('token')) {
      setAccountUuidEV(user?.accounts?.natural_person?.uuid || '');
      setProfileTypeEV(ProfileTypeEnum.PERSON);
      createQueryParamsEV(undefined);
      clearApplicationsQueryParamsEV(undefined);
      clearHistoryQueryParamsEV(undefined);
      clearDelegationsQueryParamsEV(undefined);
      clearCommunicationQueryParamsEV(undefined);
      history.push(ENTITIES.LIST);
    } else {
      history.push('/');
    }
  };

  const elemTooltip = document.querySelector('.bx--tooltip__label');
  elemTooltip?.removeAttribute('role');
  elemTooltip?.removeAttribute('tabindex');

  const btn_contrast = document.querySelector('#btn_contrast');
  btn_contrast?.addEventListener('keydown', (e: any) => {
    if (e.keyCode === 13) {
      setThemesState(!themesState);
    }
  });

  return (
    <header className="web-epak-header" ref={refHeader}>
      <div className="web-epak-header__wrap">
        {user && !isInternalUser && (
          <Link
            className="web-epak-header__house"
            onClick={handleHomeClick}
            to={ENTITIES.LIST}
            onFocus={() => window.scrollTo(0, 0)}
          >
            <Home24 />
          </Link>
        )}
        <Logo redirect={handleHomeClick} isInternalUser={isInternalUser} />
        <div className="web-epak-header__right">
          <Hidden xs>
            <div className="web-epak-header__right-top">
              <Tooltip
                className="web-epak-header__themes-container"
                direction="bottom"
                // tabIndex={-1}
                focusTrap={true}
                open={themesState}
                showIcon={false}
                align="end"
                // onChange={() => setThemesState(false)}
                triggerText={
                  <button
                    id="btn_contrast"
                    type="button"
                    aria-expanded="false"
                    aria-label="contrast"
                    className={classNames('web-epak-header__themes', { black: themesState })}
                    onClick={() => setThemesState(!themesState)}
                    title="contrast"
                  >
                    <Contrast24 className="icon" id="contrast" />
                  </button>
                }
              >
                <button
                  className="theme_close"
                  id="close"
                  type="button"
                  aria-expanded="false"
                  aria-label="close"
                  onClick={() => setThemesState(false)}
                  title="close"
                >
                  <Close16 />
                </button>
                <FontSize />
                <div className="bx--tooltip__title">{t('CONTRAST')}</div>
                <Theme />
              </Tooltip>

              <Hidden xs sm md>
                <Languages />
              </Hidden>
              {user && <Logout user={user} />}
            </div>
          </Hidden>
        </div>
      </div>

      <TopNavbar />
      {process.env.REACT_APP_ENVIRONMENT_MODE !== 'Production' && (
        <div className={classNames('environment')}>{process.env.REACT_APP_ENVIRONMENT_MODE}</div>
      )}
    </header>
  );
};
