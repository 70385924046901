const lv = {
  translation: {
    PUBLIC_SERVICE_PORTAL: 'Publisko pakalpojumu portāls',
    footer: {
      text: 'Atbilstoši likuma “Par Latvijas Republikas Uzņēmumu reģistru” 4.11 pantam tīmekļvietnē info.ur.gov.lv publicētā informācija ir patiesa un ticama, un atbilst Latvijas Republikas Uzņēmumu reģistra vesto reģistru ierakstiem un citām reģistrētajām ziņām.',
      span: 'Izmantoti Valsts adrešu reģistra informācijas sistēmas dati, {{year}}.gads',
    },
    'Enterprise Register': 'Uzņēmumu reģistrs',
    'Enterprise Register home page': 'Uzņēmumu reģistra mājas lapa',
    MANYAL: 'Rokasgrāmata',
    PROCESSING_PERSONAL_DATA: 'Personas datu apstrāde',
    'Terms of use': 'Lietošanas noteikumi',
    'User survey': 'Lietotāju aptauja',
    REPORT_ERROR: 'Ziņot par kļūdu',

    // auth
    CHOOSE_AUTH:
      '<p>Laipni lūgti Latvijas Republikas Uzņēmumu reģistra pakalpojumu portālā\n</p>' +
      '<p>Kā vēlaties autorizēties?</p>',

    // login
    login: {
      text: 'Information on the processing of personal data by the Enterprise Register is available',
      fill_form: 'Ievadiet pieteikšanās datus',
      form_helpdesk: 'Ievadiet savu lietotājvārdu un paroli',
      first_name: 'Vārds',
      last_name: 'Uzvārds',
      login: 'Pievienoties',
      password: 'Parole',
      username: 'Lietotājvārds',
    },
    HelpDeskNumber: 'Ievadiet klienta personas kodu',
    HelpDeskWhy: 'Kāpēc jūs savienojaties?',
    OtherReason: 'Cits',
    here: 'šeit',
    Continue: 'Turpināt',
    'SIGN-OUT': 'IZIET',

    // notification cookies
    cookies: {
      text: 'Mēs izmantojam savas un trešo pušu sīkdatnes, lai apkopotu informāciju par apmeklētāju pieredzi un pielāgotu tīmekļa vietnes darbību apmeklētāju vajadzībām\n\nMēs izmantojam tehniskās  (nodrošina vietnes korektu darbību) un personalizētas (atbild par pakalpojumu, ko tiešā veidā izvēlās apmeklētājs, piem. valoda) sīkdatnes. Lai uzzinātu vairāk par mūsu sīkdatņu politiku, lūdzam nospiest “Lasīt vairāk”.',
    },
    'Read more': 'Lasīt vairāk',
    ACCEPT: 'Apstiprinu',
    CLOSE: 'Aizvērt',
    'Register of Enterprises of the Republic of Latvia': 'Latvijas Republikas Uzņēmumu reģistrs',
    'Public service portal': 'Publisko pakalpojumu portāls',

    // menu
    SERVICE_CATALOG: 'Pakalpojumu katalogs',
    Applications: 'Pieteikumi',

    // form
    'Default user interface language': 'Noklusējuma lietotāja interfeisa valoda',
    'Default communication langage': 'Noklusējuma saziņas valoda',
    CODE: 'Kods',
    'Contact address': 'Kontaktadrese',
    'Receive email notifications': 'Saņemt e -pasta paziņojumus',
    'Receive SMS notifications': 'Saņemt SMS paziņojumus',
    SAVE: 'Saglabāt',
    ASSIGN: 'Piešķirt',
    NAME: 'Vārds, uzvārds',
    IDENTIFICATION_NUMBER: 'Personas kods',
    LAST_NAME: 'Uzvārds',
    PHONE_NUMBER: 'Telefona numurs',
    EMAIL: 'E-pasts',
    DEADLINE_EMAIL_TEXT: 'Ja ir iekļāvies termiņā, nav jāmaksā valsts nodeva atkārtoti',
    EMAIL_TEXT:
      'Fiziskas personas e-pasts, kurai tiek piešķirts deleģējums. Uz minēto e-pastu sistēma nosūtīs informatīvu paziņojumu par tiesībām darboties elektroniskā vidē.',

    text_validate_code_email:
      'Mēs nosūtām verifikācijas kodu uz e-pastu {{value}}. Lūdzu, zemāk ievadiet validācijas kodu un noklikšķiniet uz Apstiprināt kodu.',
    text_validate_code_phone:
      'Mēs nosūtām verifikācijas kodu uz telefonu {{value}}. Lūdzu, zemāk ievadiet validācijas kodu un noklikšķiniet uz Apstiprināt kodu.',
    'Validate code': 'Apstipriniet kodu',
    'Please enter validation code': 'Lūdzu, ievadiet validācijas kodu',
    'Re-verification of the phone number will be possible in a moment':
      'Atkārtoti verificēt tālruņa numuru būs iespējams pēc brīža',
    'Re-verification of the email will be possible in a moment':
      'Atkārtoti verificēt e-pastu būs iespējams pēc brīža',
    CODE_VALID_UNTIL: 'Derīgs vēl {{min}} minūtes',
    'Wrong code': 'Nepareizs kods',
    'Time is over': 'Laiks ir beidzies',
    'Time is up': 'Laiks ir beidzies',

    'Resend code': 'Nosūtīt kodu atkārtoti',
    'Validation code': 'Validācijas kods',
    'Add one more': 'Pievienot vēl vienu',
    'Do you want to log out?': 'Vai vēlaties beigt darbu?',
    Yes: 'Jā',
    No: 'Nē',
    text_after_user_logout:
      'Paldies, ka izmantojat Uzņēmumu reģistra informācijas sistēmu! Drošības apsvērumu dēļ, lūdzu, aizveriet pārlūkprogrammu!',
    'Your work session has ended.': 'Jūsu darba sesija ir beigusies.',

    'Tokin`s effect is over!': 'Nepieciešams autorizēties.',
    'Go through the authorization process again.': 'Nepieciešams atkārtot autorizācijas procesu.',
    'Do you want to delete a contact': 'Vai vēlaties dzēst kontaktu',
    Latvian: 'Latviski',
    English: 'Angliski',
    Cookies: 'Sīkdatnes',

    EMAIL_ADDRESS_TO_CONTACT:
      'E-pasts tiks izmantots saziņai ar Uzņēmumu Reģistru un, lai saņemtu atbildes iesniegtajiem pieteikumiem, ja e-adrese nav aktivizēta',
    'Mobile phone number for communication with Enterprise register':
      'Mobilā tālruņa numurs saziņai ar UR',

    'Data saved successfully': 'Dati saglabāti',
    'An warning has occurred. No data saved': 'Brīdinājums. Dati nav saglabāti',
    WARNING: 'brīdinājums',
    'An error has occurred. No data saved': 'Notikusi kļūme. Dati nav saglabāti',
    ERROR: 'kļūda',

    contact_already_exists: '{{contac}} jau ir reģistrēts',
    'Verification error': 'Verifikācijas kļūme',
    'Select an authorized person': 'Izvēlieties autorizētu personu',
    SEARCH: 'Meklēt',
    STATUS: 'Statuss',
    'Authorized person': 'Autorizētā persona',
    ROLE: 'Loma',
    'Valid from': 'Derīgs no',
    'Expiration date': 'Derīguma termiņš',
    ACTIVE: 'Aktīvs',
    REVOKED: 'Atsaukts (autors)',
    RECEIVER_CANCELED: 'Atsaukts (lietotājs)',
    SUSPENDED: 'Apturēts',
    EXPIRED: 'Beidzies termiņš',
    DRAFT: 'Sagatave',
    SENT: 'Nosūtīts',
    PENDING: 'Procesā...',
    DOCS_SUBMIT_SUCCESS: 'Dokumenti iesniegti!',
    ID_NUMBER: 'ID numurs',
    POTENTIAL_RESPONSE_DATE: 'Plānotais atbildes datums',
    'Active from': 'Aktīvs no',
    'Name of Issuer': 'Izsniedzēja nosaukums',
    'Registration number of Issuer': 'Izsniedzēja reģistrācijas numurs',
    Author: 'Autors',
    'Name of authorized person': 'Autorizētās personas vārds',
    'Lastname of authorized person': 'Autorizētās personas uzvārds',
    'Identifications number': 'Personas kods',
    PERMISSIONS: 'Atļaujas',
    PERMISSIONS_TEXT:
      'Deleģējumu sadaļā ir nodrošināta iespēja pievienot/noņemt lietotājus (deleģēta persona), kas darbosies elektroniskā vidē juridiskas personas vārdā. Deleģējums nav tas pats, kas pilnvarojums. Paraksttiesīga persona var deleģēt lietotāju ar šādām tiesībām:',
    PERMISSIONS_TEXT_RESTRICTED:
      'Ierobežota piekļuve - deleģētai personai ir pieejamas visas sadaļas, kas ir juridiskas personas profilā. Deleģēta persona redz visus pieteikumus, kas ir izveidoti juridiskas personas profilā (t.sk. var labot pieteikumus, kā arī iesniegt pieteikumus UR). Ierobežojumi – nevar deleģēt citu personu.',
    PERMISSIONS_TEXT_FULL:
      'Pilna piekļuve – deleģētai personai ir pieejamas visas sadaļas, kas ir juridiskas personas profilā. Deleģēta persona redz visus pieteikumus, kas ir izveidoti juridiskas personas profilā (t.sk. var labot pieteikumus, kā arī iesniegt pieteikumus UR). Deleģēta persona var deleģēt arī citu personu.',
    LEVEL: 'Līmenis',
    CANCEL: 'Atcelt',
    'Send invitation': 'Nosūtīt uzacinājumu',
    LOGIN: 'Pieslēgties',
    'Invalid email format': 'Nepareizs e-pasta adreses formāts',
    'Edit delegation': 'Deleģējuma labošana',
    'All legal entities': 'Visi subjekti',
    'Edit profile': 'Profila rediģēšana',
    create_profile: 'Profila izveide',
    'Create profile': 'Izveidot profilu',
    'View Delegation': 'Atvērt deleģējumu',
    Refuse: 'Atteikties',
    PHONE: 'Tālruņa numurs',
    ACTIONS: 'Darbības',
    CONTACT_PERSON: 'Kontaktpersona',
    'New delegation': 'Izveidot jaunu deleģējumu',
    'DELEGATED PERSON': 'Deleģētā persona',
    'Name and Surname': 'Vārds un uzvārds',
    'Revoke access': 'Atsaukt piekļuvi',
    modal_refuse_confirmation: 'Vai tiešām vēlaties atteikties no deleģējuma?',
    COMPANY: 'Uzņēmums',
    NAME_ENTERPRISE: 'Nosaukums',
    REQUIRED: 'Obligāts',
    ONE_OF_MARKED_IS_REQUIRED: 'Obligāti jāaizpilda viens no atzīmētajiem laukiem',
    'My delegation': 'Mani deleģējumi',
    'Assign date': 'Pievienošanas datums',
    'Expiry date': 'Beigu datums',
    'Never expire': 'Beztermiņa',
    'We’ll send a welcome email': 'Mēs Jums nosūtīsim uzaicinājuma e-pastu',
    'We’ll send a confirmation email to': 'Mēs Jums nosūtīsim apstiprinājuma e-pastu uz',
    level_1: 'Ierobežota piekļuve',
    level_2: 'Daļēja piekļuve',
    level_3: 'Pilna piekļuve',
    administrator: 'Administrators',
    employee: 'Darbinieks',
    'Contact Person': 'Kontaktpersona',
    'Rights of representation': 'Pārstāvības tiesības',
    'Crete profile': 'Izveidot profilu',
    receive_email_notifications:
      'E-pasta adrese ir paredzēta saziņai ar Uzņēmumu reģistru. Ja dokumenta adresātam nav aktivizēta e-adrese, tad izsniedzamie dokumenti tiks nosūtīti uz norādīto e-pastu, kā arī būs pieejami portālā (pieteikumu pārskatā).',
    receive_sms_notifications:
      'SMS ziņojumu nosūtīšana tiek nodrošināta tikai Latvijas Republikas mobilo sakaru operatoru klientiem.',

    'My contact info': 'Mana kontaktinformācija',
    'Contact info': 'Kontaktinformācija',
    INDIVIDUALLY: 'Atsevišķi',
    WITH_AT_LEAST: 'Kopā ar',
    WITH_ALL: 'Kopā ar visiem',
    WITH_CHAIR: 'Kopā ar priekšsēdētāju',
    Notifications: 'Paziņojumi',
    'Update information': 'Aktualizēt datus',
    'Add an E-mail': 'Pievienot e-pastu',
    'Add a Phone': 'Pievienot tālruni',
    'to create your profile': 'profila izveidošanai',
    ACCESSIBILITY_STATEMENT: 'Piekļūstamības paziņojums',
    Terms: 'Noteikumi',
    Contacts: 'Kontakti',
    'Personal identification number': 'Personas kods',
    'Interface language': 'Saskarnes valoda',
    'Review your information': 'Pārbaudi savus datus',
    'Review information': 'Pārbaudi datus',
    'Confirm updated profile': 'Apstiprināt profila datus',
    'Please verify and update Your contact information.':
      'Lūdzam Jūs pārbaudīt un aktualizēt savu kontaktinformāciju.',
    'Please verify your email': 'Lūdzu apstiprini e-pastu',
    'You can change it at any time': 'Jūs vēlāk varēsiet to mainīt',
    'Your company profile has been created.': 'Uzņēmuma profils ir izveidots.',
    'E-mail has been verified.': 'E-pasts ir apstiprināts.',
    'Phone number has been verified.': 'Telefona numurs ir apstiprināts.',
    'Welcome to your profile!': 'Laipni lūgti Jūsu profilā!',
    'Please fill out your contact information to access other sections of your profile.':
      'Aizpildiet kontaktinformāciju, lai būtu pieejamas arī citas profila sadaļas.',
    'Please enter valid code': 'Lūdzu, ievadiet kodu vēlreiz.',
    REQUIREDS: '(Obligāts)',
    E_MAIL: 'E-pasts',
    SUMMARY: 'Kopsavilkums',
    SUMMARY_FREE: 'Kopsavilkums (valsts nodeva apmaksai)',
    FIND_A_SERVICE: 'Sameklēt pakalpojumu',
    LOAD_MORE: 'Ielādēt vēl',
    ENTITIES: 'Pārstāvētās personas',
    EDIT_DELEGATION: 'Deleģējums',
    NEW_DELEGATION: 'Izveidot jaunu deleģējumu',
    PERMISSION_EXPIRY: 'Termiņš',
    REVOKE_ACCESS: 'Atsaukt',
    AUTHORIZED_PERSON: 'Autorizētā persona',
    DELEGATION_SEARCH: {
      INDENT_NUMBER: 'Personas kods',
      REG_NUMBER: 'Reģistrācijas numurs',
    },
    SERVICE_BASKET: 'Pakalpojumu grozs',
    CONTINUE_TO_DOCUMENTS: 'Turpināt uz dokumentiem',
    CURRENT_COSTS: 'Summa',
    OVERRIDE_MY_BASKET: 'Dzēst grozu',
    OVERRIDE_THE_BASKET: 'Pārrakstīt grozu?',
    OVERRIDE_THE_BASKET_TEXT_ONE: 'Pakalpojumus no dažādām kategorijām nevar iesniegt kopā.\n',
    OVERRIDE_THE_BASKET_TEXT_TWO:
      'Izvēloties šo pakalpojumu, jūsu grozs tiks iztīrīts un tā vietā tiks pievienots izvēlētais pakalpojums.',
    OVERRIDE_THE_BASKET_TEXT_THREE: 'Vai vēlaties turpināt?',
    ENTER_REGISTRATION_NUMBER: 'Lūdzu ievadiet reģistrācijas numuru',
    REGISTRATION_NUMBER_NOT_FOUND: 'Reģistrācijas numurs nav atrasts',
    ERRORS: {
      REQUIRED: 'Obligāts lauks nedrīkst būt tukšs',
      NUMBER: 'Lūdzu ievadīt tikai ciparus',
      MAX_CHARS: 'Ne vairāk kā {{number_of_characters}} simbolu',
      EMAIL: 'Lūdzu, ievadiet derīgu e-pastu',
      EMAIL_VALIDATE: 'Nepieciešams "Pārbaudīt" e-pastu',
    },
    ASSIGN_DATE: 'Sākuma datums',
    EXPIRY_DATE: 'Beigu datums',
    NEVER_EXPIRE: 'Nekad nebeidzas',
    REQUIRED_INFORMATION: 'Nepieciešamā informācija',
    DOCUMENTS_OVERVIEW: 'Iesniedzamo dokumentu pārskats',
    ADDITIONAL_SUBMISSION_OPTIONS: 'Iesnieguma papildu iespējas',
    PAYMENT_INFORMATION: 'Apmaksas informācija',
    PAYMENT_SUMMARY: 'Apmaksas kopsavilkums',
    PAYMENT_FORM: 'Maksājums',
    PAYMENT_DETAILS: 'Maksājuma informācija',
    PAYMENT_WAS_MADE_BY: 'Maksājumu veica',
    REAL_PERSON: 'Fiziska persona',
    PAYMENT_DETAILS_CONTACTS: {
      RECEIVER: 'Kam',
      REG_NR: 'Reģistrācijas nr.',
      SWIFT: 'Kods',
      ACCOUNT: 'Konts',
      PAYMENT_REFERENCE: 'Maksājuma uzdevuma mērķis',
      STATE_FEE: 'Valsts nodeva + Nosaukums',
      REG_NR_TEXT: 'Reģistrācijas numurs (ja tāds ir)',
    },
    PAYMENTS_TAB: {
      APP_NUMBER: 'Pieteikuma numurs',
      PRICE: 'Aprēķinātā summa',
      AMOUNT_PAID: 'Apmaksātā summa',
      DATE: 'Datums',
      PAYER: 'Maksātājs',
    },
    REGISTRATION_NUMBER: 'Reģistrācijas numurs',
    ORGANIZATION_CODE: 'Organizācijas kods',
    LEGAL_ADDRESS: 'Juridiskā adrese',
    AMOUNT: 'Apmaksātā summa',
    DATE_OF_PAYMENT: 'Apmaksas datums',
    PAYMENT_REFERENCE_NUMBER: 'Maksājuma references numurs',
    PAYMENT_ORDER_NUMBER: 'Maksājuma uzdevuma mērķis',
    CONTINUE: 'Turpināt',
    VIEW: 'Apskatīt',
    APPLICATIONS: 'Pieteikumi',
    PERSONAL_IDN_NUMBER: 'Personas kods',
    DATE_OF_BIRTH: 'Dzimšanas datums',
    DOCUMENT_TYPES: {
      documents: 'Valsts nodeva:',
      fast_processing: 'Paātrinātā izskatīšana',
      discount: 'Atlaide',
      previous_payment: 'Iepriekšējais maksājums',
    },
    DOCUMENT_TYPES_CUSTOMER: {
      documents: 'Valsts nodeva (sistēmas aprēķinātā)',
      fast_processing: 'Paātrinātā izskatīšana',
      discount: 'Atlaide',
      previous_payment: 'Iepriekšējais maksājums',
    },
    SUBMIT_DOCUMENTS_DESCRIPTION:
      'Ir atļauts pievienot elektroniskos dokumentus, kas parakstīti ar drošu elektronisko parakstu un laika zīmogu (ieteicamie formāti - EDOC, ASICE, PDF), kas sevī ietver šādus datņu formātus - TXT, ODF, DOCX, XLSX, PDF, JPEG, TIFF, PNG',
    SUBMIT_PAYMENT_DETAILS_DESCRIPTION:
      '<b>Ja iespējams, lūdzu, veiciet maksājumu pirms pieteikuma iesniegšanas.</b>{{br}} Maksājuma infromācijas saņemšana var aizņemt līdz 3 darbadienām.',
    DEPENDENTS: 'Organizatoriskās vienības',
    CONTRACTS: 'Citi tiesību subjekti, juridiskie fakti',
    FIND_A_DEPENDENT: 'Meklēt subjektu',
    DEPENDENT_TYPES: {
      BRANCH_FIL: 'Filiāle',
    },
    FIND_A_CONTRACT: 'Meklēt subjektu, juridisko faktu',
    FIND_AN_ENTITY: 'Meklēt subjektu',
    FILTER_BY: 'Filtrēt pēc',
    CATEGORY: 'Kategorijas',
    PROFILE: 'Kontaktinformācija',
    SUBMISSIONS: 'Pieteikumi',
    SERVICES_SELECTED: 'Izvēlētie pakalpojumi',
    PERSON_SUBMISSIONS_HELP_TEXT: 'Visus jūsu iesniegumus skatīt šeit.',
    LEGAL_SUBMISSIONS_HELP_TEXT: 'Visus jūsu iesniegumus skatīt šeit.',
    ID: 'Numurs',
    TYPE: 'Tips',
    DATE_SUBMITTED: 'Iesniegts',
    DATE_SUBMITTED_FROM: 'Iesniegts no',
    DATE_SUBMITTED_TO: 'Iesniegts līdz',
    SUBMITTER: 'Iesniedzējs',
    SUBJECT: 'Subjekts',
    DATE_CREATED: 'Izveidots',
    DATE_CREATED_FROM: 'Izveidots no',
    DATE_CREATED_TO: 'Izveidots līdz',
    NOT_SUBMITTED: 'Nav iesniegts',
    SUBMISSION_STATUSES: {
      draft: 'Uzsākts',
      initiated: 'Uzsākts',
      received: 'Iesniegts',
      completed: 'Reģistrēts',
      reinitiated: 'Uzsākta labošana',
      resubmitted: 'Iesniegts atkārtoti',
      processing: 'Procesā',
      rejected: 'Atteikts',
      registered: 'Reģistrēts',
      postponed: 'Atlikts',
      waiting_for_information: 'Iesniegts',
    },
    NEW_SUBMISSION: 'Jauns pieteikums',
    VIEW_SUBMISSIONS: 'Skatīt pieteikumus',
    PERSON_DELEGATIONS_HELP_TEXT: 'Skatīt uzņēmumus, kas piešķīrušas tiesības rīkoties to vārdā.',
    LEGAL_DELEGATIONS_HELP_TEXT: 'Pārvaldīt personas, kas var rīkoties jūsu uzņēmuma vārdā.',
    BACK: 'Atpakaļ',
    SUBMIT: 'Iesniegt',
    CONTINUE_SUBMIT_AN_APPLICATION: 'Turpināt uz pieteikuma iesniegšanu',
    SIGN: 'Parakstīt',
    REVIEW_SIGNED: 'Lūdzu, pārbaudiet visus dokumentus un to elektronisko parakstus.',
    SHARE_PAYMENT_LINK: 'Koplietot apmaksai',
    SHARE_LINK_INFO: 'Maksāšanas saite ir nokopēta starpliktuvē\n',
    SHARE_PAYMENT_LINK_INFO:
      'Izmantojiet šo opciju, ja vēlaties, lai maksājumu veic cita persona. Lai to izdarītu ir jāspiež teksta poga "Koplietot apmaksai". Nokopēto saiti būs iespējams nodot (ārpus portāla) citai personai, kura varēs apmaksāt (t.sk. iesniegt) pieteikumu.',
    PAYMENT: 'Maksājums',
    PAYMENTS: 'Maksājumi',
    MAKE_PAYMENT: 'Maksāt',
    REVIEW_SUBMISSION: 'Pārlūkot pieteikumu',
    CONFIRM: 'Confirm',
    NO_DATA: 'Nav datu',
    OVERVIEW: 'Pārskats',
    ON_BEHALF_OF: 'Pārstāvot',
    REG_NUMBER: 'Reģistrācijas numurs',
    IDENT_NUMBER: 'Personas kods',
    SERVICE_CATALOGUE: 'Pakalpojumu katalogs',
    SUBMITTED_SUCCESSFULLY: 'Sekmīgi iesniegts',
    'FILL_&_UPLOAD': 'Aizpildīt un augšupielādēt ',
    SERIAL_NUMBER: 'Personas kods',
    CONTRACT_TYPES: {
      spousal_property_relations: 'Laulāto mantisko attiecību līgums',
      mass_media: 'Plašsaziņas līdzekļi',
    },
    ENTITIES_TYPES: {
      LIMITED_LIABILITY_COMPANY_SIA: 'Sabiedrība ar ierobežotu atbildību',
      ASSOCIATION_BDR: 'Biedrība',
    },
    HUSBAND: 'Vīrs',
    WIFE: 'Sieva',
    REGISTRATION_DATE: 'Reģistrācijas datums',
    TITLE: 'Nosaukums',
    RESTRICTED_ACCESS: 'Ierobežota piekļuve',
    FULL_ACCESS: 'Pilna piekļuve',
    EMPLOYEE: 'Darbinieks',
    ADMINISTRATOR: 'Administrators',
    SERVICES: 'Pakalpojumi',
    PLEASE_RATE_YOUR_EXPERIENCE: 'Lūdzu, novērtējiet pakalpojuma saņemšanas procesu',
    SEND_FEEDBACK: 'Nosūtīt vērtējumu',
    AVAILABLE_EXEMPTIONS: 'Pieejamie atbrīvojumi',
    NOT_AVAILABLE_EXEMPTIONS:
      'Izvēlaties "Paātrinātā izskatīšana" vai "Pieejamie atbrīvojumi". Nav atļauts izvēlēties vienlaicīgi abas iesnieguma papildu iespējas.',
    APPLY_EXEMPTION: 'Pielietot atbrīvojumu',
    DELEGATIONS: 'Deleģējumi',
    SELECT_SERVICE_TO_CONTINUE: 'Lai turpinātu, izvēlieties pakalpojumu',
    MORE_INFORMATION: 'Vairāk informācijas',
    Changes: 'Izmaiņas',
    Register: 'Reģistrācija',
    Liquidation: 'Likvidācija',
    Insolvency: 'Maksātnespēja',
    TEMPLATE: 'Sagatave',
    EXAMPLE: 'Paraugs',
    DOCUMENT_NAME: 'Dokumenta nosaukums',
    DOCUMENTS: 'Dokumenti',
    CHANGE_SELECTED_SERVICES: 'Mainīt izvēlētos pakalpojumus',
    ADD_OTHER_DOCUMENTS: 'Pievienot citus dokumentus',
    ADD_COMPONENT: 'Pievienot vēl',
    DELETE_COMPONENT: 'Dzēst komponentu',
    PRICE: 'Cena',
    FOR_LEGAL_ENTITIES: 'Juridiskajām personām',
    FOR_EVERYONE_ELSE: 'Visiem citiem',
    BASKET_NO_ITEMS: 'Jūsu izvēlētie pakalpojumi tiks attēloti šeit.',
    CHECK_AVAILABILITY: 'Pārbaudīt pieejamību',
    SEND_YOUR_SUBMISSION: 'Nosūtīt pieteikumu',
    ON: 'Ieslēgts',
    OFF: 'Izslēgts',
    HISTORY: 'Vēsture',
    ENTER_AMOUNT: 'Lūdzu, ievadīt apjomu',
    SELECT: 'Izvēlieties',
    DATE_TIME: 'Datums, laiks',
    DATA: 'Dati',
    EVENT_ACTION: 'Darbība (notikums)',
    SECTION: 'Sadaļa',
    NAME_OF_USER: 'Lietotāja vārds, uzvārds',
    company_name: 'Uzņēmuma nosaukums',
    COMPANY_NAME: 'Uzņēmuma nosaukums',
    wife_name: 'Sievas vārds, uzvārds',
    WIFE_NAME: 'Sievas vārds, uzvārds',
    husband_name: 'Vīra vārds, uzvārds',
    HUSBAND_NAME: 'Vīra vārds, uzvārds',
    email: 'E-pasts',
    FEEDBACK_SUBMIT_SUCCESS: 'Vērtējums saņemts. Paldies!',
    FEEDBACK_SUCCESS_TEXT: 'Jūsu vērtējums palīdz uzlabot mūsu pakalpojumus.',
    COMPANY_NAME_AVAILABLE: 'Nosaukums ir pieejams',
    COMPANY_NAME_TAKEN: 'Nosaukums ir aizņemts',
    CHECK_NAME: 'Pārbaudi nosaukumu',
    CHECK_NAME_LINK: 'https://info.ur.gov.lv/#/data-search',
    CONDITIONS_FOR_NAME: 'Nosaukuma veidošanas noteikumi',
    CONDITIONS_FOR_NAME_LINK: 'https://www.ur.gov.lv/lv/registre/',
    CONDITIONS_FOR_NAME_DESC:
      'Izvēlies tiesisko formu: Dibināšana - Reģistrācija Uzņēmumu Reģistrā - Pārbaudi nosaukumu',
    ADDITIONAL_SUBMISSION_OPTIONS_NAME: 'Pieteikuma papildu iespējas',
    NO: 'Nē',
    YES: 'Jā',
    SUBMIT_AN_APPLICATION: 'Iesniegt pieteikumu?',
    SUBMIT_AN_APPLICATION_TEXT_ONE: 'Jūs vairs nevarēsiet pieteikumā izdarīt izmaiņas.',
    SUBMIT_AN_APPLICATION_TEXT_TWO:
      'Ja par pakalpojumu ir paredzēta maksa, tad pēc sekmīgas maksājuma izpildes pieteikums automātiski tiks iesniegts Uzņēmumu reģistram.',
    SUBMIT_AN_APPLICATION_TEXT_THREE:
      'Informācija par pieteikumu un tā statusu ir pieejama profila sadaļā "Pieteikumi".',
    APPLICATION_FORM: 'Pieteikuma forma',
    THEME: 'Tēma',
    DESCRIPTION: 'Apraksts',
    ATTACH_FILE: 'Pievienojiet pielikumu',
    CONTACT_INFO: 'Kontaktinformācija',
    TOOLTIP_CONTACT_INFO:
      'Ja piekrītat, ka nepieciešamības gadījumā ar Jums var sazināties Uzņēmumu reģistra darbinieks, lūdzam norādīt e-pastu un/vai tālruņa numuru',
    YOUR_EMAIL_ADDRESS: 'Jūsu e-pasta adrese',
    PHONE_NUMBER_INFO: 'Tālruņa numurs',
    RESET_CODE: 'Atjaunot kodu',
    ENTER_THE_CODE: 'Lūdzu ievadiet zemāk redzamo kodu*',
    SEND: 'Nosūtīt',
    en: 'Angļu',
    lv: 'Latviešu',
    PROFILE_LANGUAGE_SWITCH: {
      en: 'English',
      lv: 'Latviešu',
    },
    HELPDESK: 'Ziņot par kļūdu',
    VERIFY: 'Pārbaudīt',
    'The person already has active delegation to legal entity.':
      'Šai personai jau ir aktīvs deleģējums uz šo uzņēmumu.',
    PAGINATION: {
      ITEMS_PER_PAGE: 'Vienā lappusē',
      OF: 'no',
      PAGE: ' lpp.',
      ITEMS: '',
    },
    SUBMIT_AN_ISSUE: 'Iesniedziet problēmu',
    TYPE_OF_ISSUE: 'Tēma',
    CONTINUE_SESSION: 'Turpināt sesiju',
    END_SESSION: 'Pabeigt darbu',
    SESSION_WILL_EXPIRE_IN: 'Jūsu sesija beigsies pēc',
    CONTINUE_ANYWAY: 'Turpināt',
    CHECK_DOCUMENTS: 'Pārbaudīt dokumentus',
    DOCS_MISS_SIGN_TITLE: 'Problēma ar dokumenta e-paraksta validāciju!',
    DOCS_MISS_SIGN_DESCRIPTION:
      '<p>Reģistrācijas pieteikumam un tam pievienojamiem dokumentiem obligāti jābūt katram atsevišķi parakstītam ar drošu elektronisko parakstu, kas satur laika zīmogu!</p><p>Pārbaudīt e-parakstu var <a target="_blank" href="https://www.ur.gov.lv/lv/kontakti/ka-iesniegt-dokumentus-elektroniski/e-dokumentu-parbaude/parbaudi-elektroniski-parakstitu-dokumentu/">šeit</a></p><p>Ja esat pārliecināts, ka dokuments parakstīts korekti, varat turpināt pieteikuma iesniegšanas procesu.</p>',
    DOC_MISSING_SIGNATURE: 'Dokuments nav e-parakstīts',
    DOC_SIGNATURE_VALID: 'Derīgs e-paraksts',
    APPLICATION_SUBMIT_MODAL_TEXT:
      '<p>Jūs vairs nevarēsiet pieteikumā izdarīt izmaiņas.</p><p>Informācija par pieteikumu un tā statusu ir pieejama profila sadaļā "Pieteikumi".</p>',
    HELPDESK_TYPE_PLACEHOLDER: 'Izvēlies vērtību...',
    'Technical issue': 'Tehniska kļūda',
    'Grammar mistake': 'Gramatikas kļūda',
    'Suggestion for improving the site': 'Ieteikums portāla uzlabošanai',
    PLEASE_SELECT_SERVICES: 'Lūdzu izvēlieties vismaz {{count}} pakalpojumu',
    PLEASE_SELECT_SERVICES_plural: 'Lūdzu izvēlieties vismaz {{count}} pakalpojumus',
    DOC_MISS_DIGIT_SIGN: 'Dokumentiem nav elektroniskā paraksta',
    GO_BACK_AND_UPLOAD_DOCS_OR_SUBMIT:
      'Atgriezieties un augšupielādējiet elektroniski parakstītus dokumentus vai turpiniet.',
    REQUIRED_IF_APPLICABLE: 'Nepieciešams, ja piemērojams',
    APPLICATION_DELETE_CONFIRMATION: 'Dzēst iesniegumu?',
    THIS_PHONE_ALREADY_ADDED: 'Šis telefona numurs jau ir pievienots.',
    THIS_EMAIL_ALREADY_ADDED: 'Šī e-pasta adrese jau ir pievienota.',
    CLOSE_MENU: 'Aizvērt izvēlni',
    OPEN_MENU: 'Atvērt izvēlni',
    YES_BEFORE: 'Jā, iekļāvos',
    NO_AFTER: 'Nē, neiekļāvos',
    AM_I_SUBMITING_BEFORE:
      'Vai iekļāvies termiņā, kas notāra lēmumā bija paredzēts trūkumu novēršanai?',
    TEXT_SEND_SUCCESS: 'Dati ir veiksmīgi nosūtīti',
    OTHERWISE: 'Citādi',
    MAINTENANCE_HEADER: 'Notiek apkopes darbi',
    MAINTENANCE_TEXT: 'Notiek plānoti apkopes darbi. Pēc brīža, lūdzu, mēģiniet vēlreiz.',
    REVOKED_DELEGATION: 'Deleģējums {{status}}',
    DELEGATION: 'Deleģējums',
    OPTIONAL_REQUIRED: '(Obligāts pēc izvēles)',
    NO_SERVICES_SELECTED: 'Nav izvēlēts neviens pakalpojums',
    INITIATE_CHANGES: 'Iesniegt atkārtoti',
    DELEGATION_FOUND: 'Atrasts deleģējums',
    EMAIL_PHONE_INFO_TO_HELPDESK:
      'Ja piekrītat, ka nepieciešamības gadījumā ar Jums var sazināties Uzņēmumu reģistra darbinieks, lūdzam norādīt e-pastu un/vai tālruņa numuru',
    YOU_HAVE_DELEGATION_IN_ENTITY:
      'Jums ir deleģētas tiesības rīkoties šīs juridiskās personas profilā.',
    WOULD_YOU_LIKE_SWITCH_OR_CONTINUE: 'Vai vēlaties pārslēgties vai turpināsiet savā vārdā?',
    CONTINUE_AS_MYSELF: 'Turpināt savā vārdā',
    CONTINUE_AS_ENTITY: 'Pārslēgties uz juridiskās personas profilu',
    SELECT_A_LEGAL_ENTITY: 'Izvēlieties juridisko personu',
    SELECT_A_LEGAL_ENTITY_OR_PERSON: 'Izvēlieties juridisko vai fizisko personu',
    ILLEGAL_CHARACTERS: 'Datnes nosaukums satur neatļautus simbolus',
    RENAME_FILE: 'Pārsauciet datni un mēģiniet vēlreiz',
    THERE_NO_DATA: 'Nav datu',
    SERVICE_UNAVAILABLE: 'Atvainojiet, pakalpojums nav pieejams. Lūdzu, vēlāk mēģiniet vēlreiz.',
    LOADING: 'Notiek ielāde...',
    SUBMISSION_OPTIONS_TEXT:
      '<p style="padding-right: 0%">Valsts nodevu maksā trīskāršā apmērā, ja pieteicējs vēlas, lai reģistrācijas pieteikums tiktu izskatīts vienas darbdienas laikā (dokumentu izskatīšanas termiņu ietekmē arī pieteikuma iesniegšanas laiks un Latvijas svētku dienas).</p>\n' +
      '<p style="padding-right: 0%">Valsts nodevu maksā norādītajā apmērā, ja pieteicējs vēlas, lai pieteikums individuālā komersanta vai viena dibinātāja kapitālsabiedrības ierakstīšanai komercreģistrā vai izslēgšanai no komercreģistra uz iesnieguma pamata tiek izskatīts vienas darbdienas laikā (jāpievieno iesniegums).</p>\n' +
      '<p style="padding-right: 0%">Valsts nodevu maksā trīskāršā apmērā par pakalpojumu “Jaunas prokūras reģistrācija”, kad tas tiek pieteikts kopā ar pakalpojumiem “Jauna IK reģistrācija 1 dienā”, “Jaunas SIA reģistrācija (1 dibinātājs) 1 dienā”, “Jaunas mazkapitāla SIA reģistrācija (1 dibinātājs) 1 dienā”, “Jaunas AS reģistrācija (1 dibinātājs) 1 dienā”.</p>',
    SUBMISSION_OPTIONS_TEXT_AVAILABLE:
      '<p style="padding-right: 0%">Pastāv šādi atbrīvojumu veidi (atkarībā no grozā ielasītajiem pakalpojumiem):\n</p><p style="padding-right: 0%">100% atbrīvojumi – uz šādu atbrīvojumu pamata tiek piešķirta 100% atlaide visiem grozā ielasītajiem pakalpojumiem.\n</p><p style="padding-right: 0%">No valsts nodevas atbrīvotas:</p><ul style="list-style: disc; padding-left: 20px"><li>biedrības/arodbiedrības, kurās apvienoti invalīdi, bāreņi vai daudzbērnu ģimenes.</li><li>biedrības/arodbiedrības, kuru mērķis (vai viens no mērķiem) ir bērnu un skolēnu sporta pasākumu organizēšana.</li></ul><p style="padding-right: 0%">Daļēji atbrīvojumi – 100% atlaide tiek piešķirta tikai izvēlētajiem pakalpojumiem. Par pārējiem grozā ievietotajiem pakalpojumiem tiks pieprasīta maksa atbilstoši UR pakalpojumu cenrādim.</p>',
    ACTIVE_VERSION_DESCRIPTION: 'Aktīvā versija {{version}}',
    VERSION: 'Versija',
    RESOLUTION_FILE_AVAILABLE: 'Lēmums jau ir pieņemts un pēc brīža būs pieejams portālā!',
    APPLICATION_HAS_PENDING:
      'Maksājums ir apstrādē. Papildu informācija ir pieejama pieteikuma autoram portāla sadaļā “Pieteikumi”.\n',
    STATE_FREE_CUSTOMER: 'Valsts nodeva (klienta ievadītā)',
    DOWNLOAD_FILE: 'Lejupielādēt datni',
    DELETE_FILE: 'Dzēst datni',
    DOWNLOAD: 'Lejupielādēt',
    MAKE_CHANGES: 'Veikt izmaiņas',
    READ_MORE: 'Lasīt vairāk',
    OK: 'Apstiprinu',
    CLEAR_SEARCH: 'Notīrīt meklēšanas kritērijus',
    DATE_FROM: 'Datums no',
    DATE_TO: 'Datums līdz',
    POSITION_MEMBER_BOARD: 'Valdes locekļa amats',
    RIGHTS_REPRESENT: 'Tiesības pārstāvēt sabiedrību',
    PERSONAL_DATA: 'Personas dati',
    NAME_BOARD: 'Vārds',
    SURNAME_BOARD: 'Uzvārds',
    PERSON_NUMBER: 'Personai ir piešķirts Latvijas Republikas personas kods',
    PERSONAL_IDENTITY_NUMBER: 'Personas kods',
    TYPE_OF_DOCUMENT: 'Dokumenta veids',
    DOCUMENT_NUMBER: 'Dokumenta numurs',
    DATE_OF_ISSUE: 'Izdošanas datums',
    ISSUING_STATE: 'Izdevēja valsts',
    INSTITUTION: 'Institūcija',
    ADDRESS: 'Adrese',
    COUNTRY: 'Valsts',
    ENTER_ADDRESS: 'Ievadiet adresi',
    ENTRYWITHFUTUREDATERADIO:
      'Vai ir nepieciešams ierakstu par amatpersonu izdarīt ar nākotnes datumu?',
    DATE_MAKING_ENTRY: 'Ieraksta izdarīšanas datums',
    CONFIRMATION: 'Apliecinājums',
    LABEL_DOCUMENT: 'Personu apliecinoša dokumenta dati',
    APP_PRICE: 'Pieteikuma cena',
    PAID_AMOUNT: 'Samaksātā summa',
    ACTUAL_PAYMENT: 'Faktiskais maksājums',
    WAIT_DOCUMENT: 'Lūdzu, uzgaidiet! Notiek dokumentu ģenerēšana.',
    GENERATE_INFO_DOCUMENT:
      'Sistēma saģenerēja dokumentu (-us) un pievienoja pie attiecīgā dokumenta veida. Lūdzu, pārbaudi vai dokumenta saturs ir atbilstošs. Nepieciešamības gadījumā aizstāj sistēmas ģenerēto dokumentu ar savu.',
    AFTER_THE_PAYMENT: 'Pieteikums tiks iesniegs pēc maksājuma sekmīgas izpildes',
    DATA_ENTRY: 'Datu ievade',
    SUCCESS: 'Izpildīts',
    ERROR_MAX_SIZE: 'Faila izmērs nedrīkst pārsniegt 50 MB',
    GENERATE_DOCUMENTS_FORMS: 'Vai ģenerēt dokumentus?',
    WE_CAN_GENERATE:
      'Šobrīd dokumentu ģenerēšana no aizpildītajiem datu laukiem ir iespējama tikai dažiem pakalpojumiem, bet mēs strādājam, lai šo procesu uzlabotu.',
    UPLOAD_OWN_DOCUMENTS:
      'Portāla lietotājam nākamajā solī ir nodrošināta iespēja pievienot savus dokumentus (t.sk. aizstāt sistēmas ģenerēto dokumentu ar savu).',
    SELECT_AN_OPTION:
      '!!! Atceries, ģenerējot dokumentus atkārtoti, tiks izveidoti jauni dokumenti, kurus nāksies parakstīt atkārtoti.',
    HAVE_DOCUMENTS_PREPARED: 'Turpināt bez dokumentu ģenerēšanas',
    NEXT: 'Nākamais',
    GENERATE_DOCUMENTS: 'Ģenerēt dokumentus',
    SIGN_A_DOCUMENT: 'Parakstīt dokumentu?',
    SIGN_MODAL_HEADING: 'Izvēlies parakstīšanas veidu',
    SIGN_SMARTPHONE: 'eParaksts viedtālrunī',
    SIGN_CARD: 'eID karte un eParaksts karte',
    SHARE_DOCUMENT_EXTERNALLY: 'Nosūtīt saiti dokumentu parakstīšanai',
    DOCUMENT_TO_BE_SIGNED: 'Dokumenti parakstīšanai',
    DOCUMENT_FOR_SIGNING_ONLINE: 'Dokumenti parakstīšanai tiešsaistē',
    DOCUMENT_TO_BE_SIGNED_INFO:
      'Izmanto šo opciju, lai pārsūtītu dokumentus parakstīšanai citām personām.\n' +
      'Persona, kas saņem saiti, var parakstīt dokumentus:\n' +
      '1.Tiešsaistē. Izmantojot šo parakstīšanas opciju, paraksti automātiski parādīsies portālā (nav nepieciešams papildus pārsūtīt dokumentus pieteikuma autoram). Nepieciešamības gadījumā pieteikuma autors var pievienot vispārīgo komentāru dokumentu parakstītājiem.\n' +
      '2.Ārpus portāla. Ja personai nav rīku, lai parakstītu dokumentus tiešsaistē (piemēram, ārvalsts personām), tad dokumentus ir iespējams lejupielādēt savā datorā un parakstīt ar drošu elektronisko parakstu. Šādos gadījumos personai jāsūta dokumenti pieteikuma autoram, izmantojot citus saziņas līdzekļus, piemēram, e-pastu (tiešsaistē to nebūs iespējams izdarīt). Nepieciešamības gadījumā pieteikuma autors var pievienot komentāru pie katra dokumenta (piemēram, uzskaitot personas, kurām attiecīgais dokuments jāparaksta).',
    ADD_A_COMMENT_FOR_DOCUMENTS:
      'Nepieciešamības gadījumā pievieno vispārīgo komentāru dokumentu parakstītājiem.',
    DOCUMENT_TO_BE_SIGNED_OUTSIDE: 'Dokumenti parakstīšanai ārpus sistēmas',
    EACH_DOCUMENT:
      'Nepieciešamības gadījumā pievieno komentāru pie katra dokumenta (piemēram, uzskaitot personas, kurām attiecīgais dokuments jāparaksta).\n',
    COMMENTS: 'Komentāri',
    SHARE_THIS_LINK: 'Koplietot dokumentu piekļuves saiti',
    SHARE_THIS_LINK_INFO:
      'Nokopē saiti un pārsūti to dokumentu parakstītājiem (saite jāpārsūta ārpus portāla, izmantojot citus saziņas līdzekļus, piemēram, e-pastu).\n',
    SAVE_COMMENTS: 'Saglabāt komentārus un kopēt saiti',
    SIGN_DOCUMENT_WITH_YOUR_SIGNATURE:
      'Jūs saņēmāt piekļuvi dokumentiem, jo nepieciešams Jūsu elektroniskais paraksts\n',
    SIGN_DOCUMENT_WITH_YOUR_SIGNATURE_INFO:
      'Dokumentus ir iespējams parakstīt:\n' +
      '1.Tiešsaistē. Izmantojot šo parakstīšanas opciju, paraksti automātiski parādīsies portālā (nav nepieciešams papildus pārsūtīt dokumentus pieteikuma autoram).\n' +
      '2.Ārpus portāla. Ja personai nav rīku, lai parakstītu dokumentus tiešsaistē (piemēram, ārvalsts personām), tad dokumentus ir iespējams lejupielādēt savā datorā un parakstīt ar drošu elektronisko parakstu. Šādos gadījumos personai jāsūta dokumenti pieteikuma autoram, izmantojot citus saziņas līdzekļus, piemēram, e-pastu (tiešsaistē to nebūs iespējams izdarīt).',
    YOU_HAVE_BEEN:
      'Lūdzu, izvēlieties dokumentu parakstīšanas veidu un parakstiet dokumentus ar drošu elektronisko parakstu.',
    SIGN_DOCUMENT_SECURELY: 'Parakstiet dokumentus tiešsaistē',
    BY_ACCESSING_YOUR_PROFILE:
      'Jūs varat parakstīt dokumentus tiešsaistē (portālā). Izmantojot šo parakstīšanas opciju, paraksti automātiski parādīsies portālā (nav nepieciešams papildus pārsūtīt dokumentus pieteikuma autoram).',
    SIGN_DOCUMENT_ONLINE: 'Parakstiet dokumentus tiešsaistē',
    SIGN_DOCUMENT_ON_YOUR_COMPUTER: 'Parakstiet dokumentus savā datorā',
    DOWNLOAD_EACH_DOCUMENT:
      'Jūs varat parakstīt dokumentus ārpus portāla. Izmantojot šo parakstīšanas opciju, dokumenti pieteikuma autoram būs jāsūta, izmantojot citus saziņas līdzekļus, piemēram, e-pastu (tiešsaistē to nebūs iespējams izdarīt).',
    SIGN_DOCUMENT_FORM_FORBIDDEN:
      'Saite nav derīga. Saite paliek nederīga uzreiz pēc pieteikuma iesniegšanas Uzņēmumu reģistram vai pēc saites derīguma termiņa beigām',
    NO_ACCESS_PAY:
      'Minētais pieteikums ir apmaksas procesā vai jau ir apmaksāts. Atkārtoti uzsākt apmaksu nav iespējams. Jautājumu gadījumā lūdzam sazināties ar pieteikuma autoru.',
    NATURAL_PERSON: {
      countryOfResidence: 'Valsts',
      cityOrRegionAbroad: 'Pilsēta vai reģions',
      otherAddressElementsAbroad: 'Citi adreses elementi',
      zipCodeAbroad: 'Pasta indekss',
      chooseOption: 'Izvēlaties opciju',
      enterAddress: 'Ievadiet adresi',
      address: 'Adrese',
      identityDocumentType: 'Dokumenta tips',
      identityDocumentCountry: 'Izdošanas valsts',
      otherDocumentType: 'Cits dokumenta veids',
      identityDocumentNumber: 'Dokumenta numurs',
      identityDocumentIssuedBy: 'Izdevēja institūcija',
      identityDocumentDate: 'Izdošanas datums',
      labelDocument: 'Personu apliecinoša dokumenta dati',
      forname: 'Vārds',
      surname: 'Uzvārds',
      latvianIdentityNumber: 'Personas kods',
      birthDate: 'Dzimšanas datums',
      personalData: 'Personas dati',
      personNumber: 'Personai ir piešķirts Latvijas Republikas personas kods',
      documentAddedPreviously:
        'Dekuments, kas apliecina tiesības, tika pievienots šīs personas failam',
      uploadedDocumentAttestingToRights: 'Pievienojiet dokumentu, kas apliecina tiesības',
      legalBasis: 'Norādiet, uz kāda juridiskā pamata persona paraksta iesniegumu',
      otherTypeOfLegalBasis: 'Cita veida juridiskais pamats',
    },
    LEGAL_ENTITY: {
      defaultCheckboxTitle: 'Iespēja 1',
      title: 'Juridiska persona',
      country: 'Valsts, kurā uzņēmums reģistrēts',
      registrationNumber: 'Reģistrācijas numurs',
      name: 'Nosaukums',
      titleLegalAddress: 'Juridiskā adrese',
      address: 'Ievadiet adresi',
      cityOrRegionAbroad: 'Pilsēta vai reģions',
      otherAddressElementsAbroad: 'Citi adreses elementi',
      zipCodeAbroad: 'Pasta indekss',
    },
    SIGNER_PERSON: {
      leSigner: 'Persona, kura parakstīs dokumentus',
      position: 'Pozīcija',
      otherPosition: 'Norādiet pozīciju',
      leTtypeOfLegalJustification:
        'Atzīmējiet, uz kāda juridiskā pamata persona paraksta iesniegumu',
      le_type_other_basis: 'Cita juridiskā pamata veids',
      documentAddedPreviously:
        'Dokuments, kas apliecina tiesības, iepriekš ir pievienots šī subjekta failam',
      forename: 'Vārds',
      surname: 'Uzvārds',
      hasLatvianIdentityNumber: 'Personai ir piešķirts Latvijas Republikas personas kods',
      latvianIdentityNumber: 'Personas kods',
      birthDate: 'Dzimšanas datums',
      le_title: 'Personu apliecinoša dokumenta dati',
      type: 'Dokumenta veids',
      otherDocumentType: 'Cita veida dokuments',
      number: 'Dokumenta numurs',
      issuedOn: 'Izdošanas datums',
      country: 'Izdevēja valsts',
      issuedBy: 'Iestāde',
      le_title_address: 'Adrese',
      addressCountry: 'Valsts',
      documentRightToRepresentForeignMerchant:
        'Dokuments, kas apliecina tiesības pārstāvēt ārvalstu komersantu',
      uploadedDocumentAttetsingToRights: 'Pievienojiet dokumentu, kas apliecina tiesības',
      address: 'Ievadiet adresi',
      cityOrRegionAbroad: 'Pilsēta vai reģions',
      otherAddressElementsAbroad: 'Citi adreses elementi',
      zipCodeAbroad: 'Pasta indekss',
    },
    SOMETHING_WRONG: 'Pieteikuma datu ievade nav pieejama',
    SHARED_DOCUMENTS_INFO_TEXT:
      'Nosūtot saiti, tiks nodrošināta piekļuve dokumentu projektiem. Piekļuve tiek nodrošināta lietotājiem, kuriem ir saite, neatkarīgi no tā, vai tie saņem saiti tieši no jums vai tā tiek pārsūtīta no kādas citas personas. Lietotājs ir personiski atbildīgs par saites nodošanu tikai tām personām, kam nepieciešama piekļuve šiem dokumentiem, kā arī riskiem, kas saistīti ar saites nodošanu un lietošanu. Uzņēmumu reģistrs nav atbildīgs par saturu un saites nodošanu citām personām, bet nodrošina saiti un pieeju tikai kā pakalpojumu lietotājiem jūsu uzdevumā. Saite nebūs derīga uzreiz pēc pieteikuma iesniegšanas Uzņēmumu reģistram.',
    AUTOSAVE_MSG: 'Automātiskās saglabāšanas forma...',
    CITY_OR_REGION: 'Pilsēta vai reģions',
    OTHER_ADDRESS: 'Citi adreses elementi',
    POSTCODE: 'Pasta indekss',
    CADASTRE: 'Kadastra apzīmējums',
    FONT_SIZE: 'Fonta lielums',
    CONTRAST: 'Kontrasts',
    WARNING_MODAL_TITLE: 'Pieteikumā konstatētas datu ievades kļūdas. Vai vēlaties turpināt?',
    WARNING_MODAL_TEXT:
      'Pieteikumā tika konstatētas augstāk minētās datu ievades kļūdas.\nVai vēlaties turpināt pieteikuma apstrādi?',
    REVIEW_DATA_TITLE: 'Pieteikuma dati',
    CHANGE_ACCOUNT: 'Mainīt kontu',
    EXTERNAL_MODAL_TITLE: 'Vai vēlaties pieslēgties pārstāvot ārēju organizāciju?',
    EXTERNAL_USER_SELECT: 'Ārējā organizācija',
    COMMUNICATION: {
      TITLE: 'Saziņa',
      APP_NUMBER: 'Pieteikuma numurs',
      ITEM_RECEIVED: 'Saņemtie',
      ITEM_SENT: 'Nosūtītie',
      ITEM_BLANKS: 'Sagataves',
      ITEM_DELETED: 'Izdzēstie',
      SUBJECT: 'Subjekts',
      DATE_SENDING: 'Nosūtīšanas datums',
      SENDER: 'Nosūtītājs',
      RECIPIENT: 'Saņēmējs',
      TOPIC: 'Tēma',
      CONTENT: 'Saturs',
      MESSAGE_SUBJECT: 'Ziņas tēma',
      LEGAL_ENTITY: 'Subjekts',
      NOTARY: 'Notārs',
      CREATE: 'Pievienot jaunu burtu',
      LETTER_TITLE: 'Vēstule',
      MAINTAIN: 'Saglabāt',
      SEND_TO: 'Nosūtīt',
      APPENDICES: 'Pielikumi',
      VIEW_TITLE: 'Saziņa',
      APPLICATION: 'Pieteikums',
      SUBJECTS: 'Subjekts',
      REPLY: 'Atbildēt',
      REPLY_MODAL_TITLE: 'Jauna vēstule',
      CANCEL: 'Atcelt',
      SUBMIT: 'Sūtīt',
      RECEIVED: 'Saņemtās ziņas',
      SENT: 'Nosūtītās ziņas',
      APP_COMMUNICATION_TABLE: {
        send_time: 'Datums',
        sender_uuid: 'Sūtītājs',
        message_subject: 'Temats',
        attachments: 'Pielikums',
        documents: 'Dokumenti',
        NO_DATA: 'Nav ziņu',
      },
    },
  },
};

export default lv;
