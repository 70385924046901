import { InlineLoading, Modal } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Emitter } from 'utils/event-emitter';
import { SHOW_LOGOUT_MODAL } from 'utils/event-emitter/constants';
import { useCookies } from 'react-cookie';
import { useStore } from 'effector-react';
import { logout } from '../../utils/logout';
import { logoutUserFX } from '../../store/user/effects';

export const LogoutModal = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [, , removeCookie] = useCookies(['web_epak']);
  const isLoading = useStore(logoutUserFX.pending);
  const body = document.querySelector('body');

  useEffect(() => {
    Emitter.on(SHOW_LOGOUT_MODAL, () => setOpenModal(true));
  }, []);

  if (!openModal) {
    return null;
  }

  const handleLogout = () => {
    logout(removeCookie);
    body?.classList.remove('helpDeskMode');
    setOpenModal(false);
  };

  return (
    <Modal
      size={'sm'}
      modalHeading={t('Do you want to log out?')}
      onRequestSubmit={handleLogout}
      primaryButtonText={
        isLoading ? <InlineLoading status="active" className="search-loader" /> : t('YES')
      }
      secondaryButtonText={t('NO')}
      onSecondarySubmit={() => setOpenModal(false)}
      open
      onRequestClose={() => setOpenModal(false)}
      className="web-epak__modal"
    >
      <p style={{ marginBottom: '1rem' }}></p>
    </Modal>
  );
};
