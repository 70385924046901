import { Modal } from 'carbon-components-react';
import PortalModal from 'components/PortalModal';
import { useStore } from 'effector-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useTime, useTimer } from 'react-timer-hook';
import { $mainStore } from 'store/main';
import { $userStore } from 'store/user';
import { refreshTokenFX } from 'store/user/effects';
import { refreshTokenEV } from 'store/user/events';
import { addZeroToNumberBefore } from 'utils/addZeroToNumberBefore';
import { useProfile } from '../../hooks/useProfile';
import { logout } from '../../utils/logout';

export const SessionExpireModal = () => {
  const { session_expired } = useProfile().data;
  const timerTime = 5; // minutes;
  const maxNotActivityTime = (session_expired || 20) - timerTime - 1; // aftex maxNotActivityTime mins show popup "continue session"
  const { lastActivity } = useStore($mainStore);
  const { t } = useTranslation();
  const time = useTime({ format: '12-hour' });
  const expiryTimestamp = moment().add(timerTime, 'minutes').toDate();
  const [openModal, setOpenModal] = useState(false);
  const user = useStore($userStore);
  const isRefreshing = useStore(refreshTokenFX.pending);
  const [, , removeCookie] = useCookies(['web_epak']);

  const handleCloseModal = () => setOpenModal(false);

  const handleLogout = () => {
    logout(removeCookie);
    handleCloseModal();
  };

  const handleSubmit = () => {
    refreshTokenEV();
    pause();
  };

  const { seconds, minutes, restart, pause } = useTimer({
    expiryTimestamp,
    onExpire: handleLogout,
  });

  useEffect(() => {
    pause();
    refreshTokenFX.doneData.watch(handleCloseModal);
  }, []);

  useEffect(() => {
    if (user) {
      const now = moment();
      const diff = now.diff(lastActivity, 'seconds');
      const newExpiryTimestamp = now.add(timerTime, 'minutes').toDate();

      if (diff === maxNotActivityTime * 60) {
        console.log('show session ends modal', new Date());

        setOpenModal(true);
        restart(newExpiryTimestamp);
      }
    }
  }, [time.seconds]);

  return (
    <>
      {openModal && (
        <PortalModal>
          <Modal
            size="sm"
            modalLabel=""
            primaryButtonText={t('CONTINUE_SESSION')}
            primaryButtonDisabled={isRefreshing}
            secondaryButtonText={t('END_SESSION')}
            open={openModal}
            onRequestSubmit={handleSubmit}
            onRequestClose={handleLogout}
            className="web-epak__modal"
          >
            <div className="web-epak__modal-blok">
              <div className="web-epak__modal-timer">
                {t('SESSION_WILL_EXPIRE_IN')}:{' '}
                <span>
                  {minutes}:{addZeroToNumberBefore(seconds)}
                </span>
              </div>
            </div>
          </Modal>
        </PortalModal>
      )}
    </>
  );
};
