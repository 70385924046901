import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { changeUserSize } from '../../../../../store/user/events';

export const FontSize = () => {
  const html = document.querySelector('html');
  const sizeValue: string = localStorage.getItem('size') || '';
  const { t } = useTranslation();
  const [size, setSize] = useState(sizeValue);

  useEffect(() => {
    localStorage.setItem('size', size);
    changeUserSize(size);

    if (html && size) {
      html.dataset.size = size;
    }
  }, [size]);

  return (
    <>
      <div className="bx--tooltip__title">{t('FONT_SIZE')}</div>
      <div className="bx--tooltip__header">
        {[{ name: '100%' }, { name: '150%' }, { name: '200%' }].map((item) => (
          <button
            key={item.name}
            className={cn('size', { active: size === item.name })}
            onClick={() => setSize(item.name)}
          >
            {item.name}
          </button>
        ))}
      </div>
    </>
  );
};
