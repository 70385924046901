import { Close20 } from '@carbon/icons-react';
import { Languages } from 'components/header/main-header/components/languages/Languages';
import { Logo } from 'components/header/main-header/components/logo/Logo';
import { Theme } from 'components/header/main-header/components/theme/Theme';
import { Navigation } from 'components/navigation/Navigation';
import { useEffect, useRef, useState } from 'react';
import { Emitter } from 'utils/event-emitter';
import { useClickAway } from 'react-use';
import './styles.scss';
import { useStore } from 'effector-react/effector-react.cjs';
import { $userStore } from '../../store/user';
import { EE_HIDE_MOBILE_NAV, EE_SHOW_MOBILE_NAV } from './consts';

export const MobileMenu = () => {
  const user = useStore($userStore);
  const containerRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  const handleCloseIconClick = () => {
    setVisible(false);
  };

  useClickAway(containerRef, () => {
    setVisible(false);
  });

  useEffect(() => {
    Emitter.on(EE_SHOW_MOBILE_NAV, () => setVisible(true));
    Emitter.on(EE_HIDE_MOBILE_NAV, () => setVisible(false));
  }, []);

  return (
    <>
      {visible && (
        <div className="mobile-menu">
          <div className="mobile-menu-container" ref={containerRef}>
            <div className="mobile-menu-header">
              <button
                onClick={handleCloseIconClick}
                className="mobile-menu-close-btn"
                type="button"
                title="close"
              >
                <Close20 />
              </button>
              <Logo />
            </div>
            <div className="mobile-menu-content">{user && <Navigation />}</div>
            <div className="mobile-menu-footer">
              <Languages />
              <div className="web-epak-header__themes-container">
                <Theme />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
