import { RequestParams } from '@types';
import api from '../../../services/api';

export const fetchListCallback = async (path: string, params = {}) => {
  const response = await api.get(`/${path}`, {
    params,
  });

  return response.data;
};

export const fetchSingleCallback = async (path: string, params: RequestParams) => {
  const response = await api.get(`/${path}/${params.uuid}`);

  return response.data;
};

export const updateCallback = async <Module extends { uuid?: string }>(
  path: string,
  payload: Module
) => {
  const response = await api.put(`/${path}/${payload.uuid}/`, payload);

  return response.data;
};

export const createCallback = async <Module>(path: string, payload: Module) => {
  const response = await api.post(`/${path}`, payload);

  return response.data;
};

export const deleteCallback = async (path: string, { uuid }: { uuid: string }) => {
  await api.delete(`/${path}/${uuid}`);

  return uuid;
};

export const acceptCallback = async <Module>(path: string, payload: Module) => {
  const response = await api.post(`/${path}`, payload);

  return response.data;
};

export const patchCallback = async <Module>(path: string, payload: Module) => {
  const response = await api.patch(`/${path}`, payload);

  return response.data;
};
