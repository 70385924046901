export const ENTITIES = {
  LIST: '/entities', // EntitiesPage
  INFO_PAGE: '/entities/:entityId/info/:slug', // StaticPage
};

export const STATIC_PAGE = '/pages/:slug';

export const HELPDESK_PAGE = '/helpdesk';

export const DELEGATIONS = {
  LIST: '/delegations',
};

export const CONTRACTS = {
  LIST: '/contacts',
  VIEW: '/contracts/:contactId',
};

export const PAYMENT = {
  LIST: '/payments',
};

export const HISTORY = {
  LIST: '/history',
};

export const AUTH = '/auth';

export const USER_PROFILE = '/profile'; // UserProfilePage

export const MAINTENANCE = '/maintenance';

export const SIGNING_DOCUMENT = '/signing-document/:application_uuid/:document_uuid';

export const CATALOG = {
  LIST: '/catalog',
  SUMMARY: '/catalog/:id/summary',
};

export const APPLICATIONS = {
  LIST: '/applications',
  VIEW: '/applications/:uuid',
  SUBMIT: '/applications/:uuid/submit',
  SUBMIT_SUCCESS: '/applications/success',
  SUBMIT_FORM: '/applications/:uuid/submit-form',
  PAYMENT: '/applications/:uuid/payment',
  PAYMENT_PENDING: '/applications/:uuid/payment-pending',
  SHARED_PAYMENT: '/applications/:uuid/show_shared_payment/:payment_id',
  // PAYMENT_REQUEST: '/payment-completed/:payment_id',
  PAYMENT_REQUEST: '/payment-completed',
};

export const SUBMISSIONS = {
  LIST: '/submissions',
  VIEW: '/submissions/:uuid',
};

export const NOT_FOUND = '/404'; // NotFoundPage

export const DEPENDENTS = '/dependents';

export const COMMUNICATION = {
  LIST: '/communication',
  CREATE: '/communication/create',
  EDIT: '/communication/edit/:message_uuid',
};
