import { LOCAL_STORAGE } from 'constants/app';
import { history } from '@history';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import cogoToast from 'cogo-toast';
import { updateLastActivityEV } from 'store/main/events';
import { parseJwt } from 'utils';
import { accessDeniedlogoutUserFX } from 'store/user/effects';

const api_auth = axios.create({
  baseURL: process.env.REACT_APP_API_AUTH_ROUTE,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-type': 'application/json;charset=utf-8',
  },
  timeout: 120000,
});

api_auth.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const token_key = localStorage.getItem(LOCAL_STORAGE.TOKEN_KEY);
  const language = localStorage.getItem(LOCAL_STORAGE.LANG);
  const userIp = localStorage.getItem(LOCAL_STORAGE.USER_IP);
  const parsedToken = token ? parseJwt(token) : null;

  return {
    ...config,
    headers: {
      ...config.headers,
      ...(parsedToken && token_key
        ? { 'App-Name': parsedToken.app_name, Token: token, 'Token-Key': token_key }
        : {}),
      ...(language && { language }),
      ...(userIp && { 'User-Ip': userIp }),
    },
  };
});

api_auth.interceptors.response.use(
  (data: AxiosResponse) => {
    updateLastActivityEV();

    return data;
  },
  (error: AxiosError) => {
    if (error.response?.status === 400) {
      return Promise.reject(error.response);
    }

    if (error.response?.status === 401) {
      accessDeniedlogoutUserFX();
    }

    if (
      error.response?.status === 403 &&
      !error.response?.config?.url?.includes('v2/submissions/') &&
      !error.response?.config?.url?.includes('/show_shared_payment/')
    ) {
      if (
        ['help_desk_ldap', 'help_desk_dummy_ldap'].includes(
          localStorage.getItem('auth_provider') || ''
        )
      ) {
        const { hide } = cogoToast.warn('Help desk lietotājam nav atļauts veikt šādas darbības', {
          hideAfter: 0,
          onClick: () => {
            hide?.();
          },
        });
      } else {
        const { hide } = cogoToast.warn(`403. ${error.response.data?.message}`, {
          hideAfter: 0,
          onClick: () => {
            hide?.();
          },
        });
      }
    }

    if (
      error.response?.config.url === '/api-profile/api/v1/profile' &&
      error.response?.status === 500
    ) {
      history.push('/maintenance');
    }

    return Promise.reject(error.response);
  }
);

export default api_auth;
