import { createEvent } from 'effector';
import { RequestParams } from '../../../@types';
import { DropdownType } from '../../classifiers/interfaces';
import { ApplicationsStore } from '../interfaces';

export const setApplicationsStateEV = createEvent<Partial<ApplicationsStore>>();

export const setApplicationsTotalPriceEV = createEvent<string>();

export interface NewRequestParams extends RequestParams {
  select_type?: DropdownType;
  select_status?: DropdownType;

  code?: string;
  type?: string;
  date_created_from?: string;
  date_created_to?: string;
  date_submitted_from?: string;
  date_submitted_to?: string;
  subject?: string;
  status?: string;
  submitted_by?: string;
}

export const createQueryParamsEV = createEvent<NewRequestParams | undefined>();

export const clearApplicationsQueryParamsEV = createEvent<NewRequestParams | undefined>();
