import { Modal } from 'carbon-components-react';
import { useStore } from 'effector-react';
import { useProfile } from 'hooks/useProfile';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { $userStore } from '../../store/user';
import { fetchUserTermsConsentFX } from '../../store/user/effects';
import { requestTermsPage } from '../../utils';
import { logout } from '../../utils/logout';
import PortalModal from '../PortalModal';

type TextType = {
  body: string;
  error: boolean;
  from: string;
  lang: string;
  title: string;
  version: string;
};

export const ConsentToTerms = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const userProfile = useStore($userStore);
  const { data } = useProfile();
  const [text, setText] = useState<TextType>();
  const [, , removeCookie] = useCookies(['web_epak']);
  const isInternalUser = data?.mode === 'internal';

  const handleConsent = () => {
    fetchUserTermsConsentFX({
      terms_consent: JSON.stringify(text),
    });
    setOpenModal(false);
  };

  const handleRefusal = () => {
    logout(removeCookie);
    setOpenModal(false);
  };

  useEffect(() => {
    if (userProfile?.need_terms_consent && !isInternalUser) {
      (async () => {
        const respTerms = await requestTermsPage();

        if (respTerms.success) {
          setText(respTerms.success);
          setOpenModal(true);
        }
      })();
    }
  }, [userProfile]);

  return (
    <>
      {openModal && (
        <PortalModal>
          <Modal
            id="consent-to-terms"
            size="xs"
            primaryButtonText={t('ACCEPT')}
            onRequestSubmit={handleConsent}
            secondaryButtonText={t('CANCEL')}
            onSecondarySubmit={handleRefusal}
            open
            onRequestClose={handleRefusal}
            className="web-epak__modal"
          >
            <div dangerouslySetInnerHTML={{ __html: text?.body || '' }} />
          </Modal>
        </PortalModal>
      )}
    </>
  );
};
