import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserProfile } from 'store/user/interfaces';
import { Emitter } from 'utils/event-emitter';
import { SHOW_LOGOUT_MODAL, SHOW_HELPDESK_MODAL } from 'utils/event-emitter/constants';

interface Props {
  user: UserProfile;
}

export const Logout: FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const authProvider = localStorage.getItem('auth_provider');
  const userSerialNumber = localStorage.getItem('helpdesk_user_serial_number');

  const handleClick = () => Emitter.emit(SHOW_LOGOUT_MODAL);
  const handleHelpDeskClick = () => Emitter.emit(SHOW_HELPDESK_MODAL);

  return (
    <>
      <div className="web-epak-header__block">
        <span>{user?.name}</span>
        {(authProvider === 'help_desk_ldap' || authProvider === 'help_desk_dummy_ldap') && (
          <button
            onClick={handleHelpDeskClick}
            className="web-epak-header__block-btn"
            style={{ marginRight: '18px' }}
          >
            {userSerialNumber}
          </button>
        )}
        <button onClick={handleClick} className="web-epak-header__block-btn">
          {t('SIGN-OUT')}
        </button>
      </div>
    </>
  );
};
