import { APP_NAME, LOCAL_STORAGE } from 'constants/app';
import axios from 'axios';
import { createEffect } from 'effector';
import api from 'services/api';
import api_auth from 'services/api_auth';
import { PatchContactPayload } from 'store/shared/types';
import { Emitter } from 'utils/event-emitter';
import { SHOW_HELPDESK_MODAL } from 'utils/event-emitter/constants';
import { AnyObject } from 'yup/lib/types';
import { UpdateContact, UserProfile } from '../interfaces';
import { EffectCallbacks } from '../../shared/effect-callbacks';
import { parseJwt } from '../../../utils';
import { fetchEntityProfileFX } from '../../entities/effects';

export const fetchUserProfileFX = createEffect(async (params = {}) => {
  const response = await api.get('/api-profile/api/v1/profile', {
    params,
  });
  const submit_form = window.location.pathname.includes('/submit-form');
  const payment_completed = window.location.pathname.includes('/payment-completed');
  const signing_document = window.location.pathname.includes('/signing-document');
  !submit_form &&
    !payment_completed &&
    !signing_document &&
    localStorage.setItem(LOCAL_STORAGE.UUID, response.data.data.accounts.natural_person.uuid);

  return response.data;
});

export const patchUserProfileFX = createEffect(async (payload: Partial<UserProfile>) => {
  const response = await api.patch('/api-profile/api/v1/profile', payload);

  return response.data;
});

export const fetchUserTermsConsentFX = createEffect(async (terms_consent: any) => {
  const response = await api.post('/api-profile/api/v1/profile/terms_consent', terms_consent);

  return response.data;
});

export const confirmUserTermsConsentFX = createEffect(async () => {
  const response = await api.post('/api-profile/api/v1/profile/confirm', {
    need_profile_review: false,
  });

  return response.data;
});

export const authenticateUserFX = createEffect(async (auth_session_id: string) => {
  const authResponse = await api_auth.post('/api-auth/api/v1/auth/authorization', {
    app_name: APP_NAME,
    auth_session_id,
  });
  const { x_token, x_token_key, redirect_url } = authResponse.data.data;

  localStorage.setItem(LOCAL_STORAGE.TOKEN, x_token);
  localStorage.setItem(LOCAL_STORAGE.TOKEN_KEY, x_token_key);

  if (redirect_url !== '') {
    window.location = redirect_url;
  }

  if (
    ['help_desk_dummy_ldap', 'help_desk_ldap'].includes(localStorage.getItem('auth_provider') || '')
  ) {
    Emitter.emit(SHOW_HELPDESK_MODAL);
  } else {
    fetchUserProfileFX({});
  }
});

export const authHelpDeskNumberFX = createEffect(async (userData: AnyObject) => {
  const authResponse = await api.patch(
    '/api-profile/api/v1/profile/change-serial-number',
    userData
  );

  const { x_token, x_token_key, redirect_url } = authResponse.data.data;

  localStorage.setItem(LOCAL_STORAGE.TOKEN, x_token);
  localStorage.setItem(LOCAL_STORAGE.TOKEN_KEY, x_token_key);

  if (redirect_url !== '') {
    window.location = redirect_url;
  }

  return fetchUserProfileFX({});
});

export const logoutUserFX = createEffect(async () => {
  const resp = await api_auth.delete('/api-auth/api/v1/auth/logout');

  return resp.data;
});

// logout on error 401, not on click "logout" btn.
export const accessDeniedlogoutUserFX = createEffect(async () => {
  const resp = await api_auth.delete('/api-auth/api/v1/auth/logout');

  return resp.data;
});

export const deleteUserContactFX = createEffect(async (payload: PatchContactPayload) => {
  const resp = await api.delete('/api-profile/api/v1/contacts', { params: payload });

  return resp.data;
});

export const authDummyFX = createEffect(
  async ({ slug, userData }: { slug: string; userData: any }) => {
    try {
      const response = await api_auth.post(`/api-auth/authhandler/${slug}`, userData);

      return response.data.data.redirect_url;
    } catch (error: any) {
      return { error: error.data.errorMessage };
    }
  }
);

export const fetchIDPFX = createEffect(async () => {
  const response = await api_auth.get('/api-auth/api/v1/idp');

  return response.data.data;
});

export const searchEntitiesFX = createEffect(
  async ({ search, type = 'legal_entity' }: { search: string; type?: string }) => {
    const response = await api.get('/api-profile/api/v1/entities', {
      params: {
        search,
        type,
      },
    });

    return response.data.data;
  }
);

export const redirectToAuthServiceFX = createEffect(
  async ({ slug, lang, isMobile = false }: { slug: string; lang: string; isMobile?: boolean }) => {
    const resp = await api_auth.get(`/api-auth/api/v1/auth/redirect/${slug}/${lang}/${isMobile}`);

    return resp.data.data.redirect_url;
  }
);

export const updateUserContactsFX = createEffect(async (payload: UpdateContact) => {
  const response = await api.patch('/api-profile/api/v1/contacts', payload);

  return response.data;
});

export const verifyUserContactFX = createEffect(
  async ({ type, value }: Omit<UpdateContact, 'communication_enabled'>) => {
    const response = await api.post(`/api-profile/api/v1/contact_verification/${type}`, { value });

    return response.data;
  }
);

export const validateUserContactFX = createEffect(
  async ({ code, key, type }: { code: string; key: string; type: string }) => {
    const response = await api.post(`/api-profile/api/v1/contact_validate/${key}/${code}`);

    return {
      ...response.data.data,
      type,
    };
  }
);

export const refreshTokenFX = createEffect(async () => {
  const x_token_key = localStorage.getItem(LOCAL_STORAGE.TOKEN_KEY);
  api_auth.patch('/api-auth/api/v1/auth/keep-alive-token', { x_token_key });
});

export const getHelpDeskReasonsFX = createEffect(async () => {
  const response = await api.get('api-profile/api/v1/helpdesk/user_login_reasons');

  return response.data;
});

export const fetchClientIp = createEffect(async () => {
  try {
    const res = await axios.get('https://geolocation-db.com/json/');

    localStorage.setItem(LOCAL_STORAGE.USER_IP, res.data.IPv4);

    return res;
  } catch (err) {
    localStorage.removeItem(LOCAL_STORAGE.USER_IP);

    return Promise.resolve();
  }
});

const CallbackOrgs = new EffectCallbacks('api-profile/api/v1/profile/organizations');
export const fetchExternalOrganizationsFX = createEffect(CallbackOrgs.fetchList);

export const fetchExternalSetOrganizationFX = createEffect(async ({ uuid }: { uuid: string }) => {
  const response = await api.patch('api-profile/api/v1/profile/set-organization', {
    organization_uuid: uuid,
  });

  const { x_token, x_token_key } = response.data.data;

  localStorage.setItem(LOCAL_STORAGE.TOKEN, x_token);
  localStorage.setItem(LOCAL_STORAGE.TOKEN_KEY, x_token_key);

  const tokenInf = parseJwt(x_token);

  tokenInf?.legal_entity_uuid && fetchEntityProfileFX(tokenInf.legal_entity_uuid);

  return response.data;
});
