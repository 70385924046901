import { createEvent } from 'effector';
import { CatalogStore } from '../interfaces';

export const clearCatalogEV = createEvent();

export const updateCatalogStoreEV = createEvent<Partial<CatalogStore>>();

export const resetModalServicesEV = createEvent();

export const updateSelectedServicesEV = createEvent<CatalogStore['selectedServices']>();

export const fetchCatalogSuccessEV = createEvent();

export const fetchCatalogEV = createEvent<any>();
