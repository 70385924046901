import { createEvent } from 'effector';
import { refreshTokenFX, validateUserContactFX } from '../effects';

export const setAccountUuidEV = createEvent<string>();

export const setUserProfile = createEvent<any>();

export const validateUserContact = createEvent<any>();

export const refreshTokenEV = createEvent();

export const changeUserSize = createEvent<any>();

validateUserContact.watch(validateUserContactFX);

refreshTokenEV.watch(refreshTokenFX);
