import { LOCAL_STORAGE } from 'constants/app';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { clearCommunicationQueryParamsEV } from 'store/communication/events';
import { useStore } from 'effector-react';
import { $userStore } from 'store/user';
import { parseJwt } from 'utils';
import { Logotype } from '../../Logotype';
import { CATALOG, ENTITIES, USER_PROFILE } from '../../../../../routes/constants';
import { createQueryParamsEV } from '../../../../../store/payment/events';
import { clearApplicationsQueryParamsEV } from '../../../../../store/applications/events';
import { clearHistoryQueryParamsEV } from '../../../../../store/history/events';
import { clearDelegationsQueryParamsEV } from '../../../../../store/delegations/events';

interface Props {
  redirect?: any;
  isInternalUser?: boolean;
}

export const Logo: FC<Props> = ({ redirect, isInternalUser }) => {
  const { t } = useTranslation();
  const user = useStore($userStore);
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const tokenInf = parseJwt(token || '');

  const clearFiltersQueryParams = () => {
    createQueryParamsEV(undefined);
    clearApplicationsQueryParamsEV(undefined);
    clearHistoryQueryParamsEV(undefined);
    clearDelegationsQueryParamsEV(undefined);
    clearCommunicationQueryParamsEV(undefined);
    redirect();
  };

  const redirectTo = useMemo(() => {
    if (tokenInf?.legal_entity_uuid) {
      return USER_PROFILE;
    }

    if (user?.accounts?.legal_entities_count && user?.accounts?.legal_entities_count > 0) {
      if (user.need_profile_submit || user.need_profile_review) {
        return USER_PROFILE;
      } else {
        return ENTITIES.LIST;
      }
    } else {
      return CATALOG.LIST;
    }
  }, [user]);

  return (
    <Link
      onClick={clearFiltersQueryParams}
      to={redirectTo}
      style={isInternalUser ? { pointerEvents: 'none' } : {}}
      className={`web-epak-header__logo ${!redirect ? 'logoMobile' : null}`}
    >
      {process.env.REACT_APP_ENVIRONMENT_HEADER_NAME && (
        <div>{process.env.REACT_APP_ENVIRONMENT_HEADER_NAME}</div>
      )}
      <Logotype />
      <div style={{ textAlign: 'center', fontSize: '1rem' }}>
        {t('Register of Enterprises of the Republic of Latvia')}
      </div>
    </Link>
  );
};
