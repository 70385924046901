import { createEvent } from 'effector';
import { RequestParams } from '../../../@types';
import { DropdownType } from '../../classifiers/interfaces';
import { HistoryStore } from '../interfaces';

export const setHistoryStateEV = createEvent<Partial<HistoryStore>>();

export const setApplicationsTotalPriceEV = createEvent<string>();

export interface NewRequestParams extends RequestParams {
  select_action?: DropdownType;
  select_section?: DropdownType;

  created_from?: string;
  created_to?: string;
  action?: string;
  section?: string;
  user_name?: string;
  application_data?: string;
}

export const createQueryParamsEV = createEvent<NewRequestParams | undefined>();

export const clearHistoryQueryParamsEV = createEvent<NewRequestParams | undefined>();
