import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HELPDESK_PAGE, STATIC_PAGE } from 'routes/constants';
import { generatePath } from 'react-router';
import { Wikis24 } from '@carbon/icons-react';
import { useEffect, useState } from 'react';

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const html = document.querySelector('html');
  const [versionState, setVersionState] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      const version = html?.getAttribute('version');
      version && setVersionState(version);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <footer className="footer">
      <div className="container footer__wrap">
        <nav>
          <a href={`https://www.ur.gov.lv/${i18n.language}/`} className="footer-enterprise">
            <Wikis24 className="icon" />
            <span style={{ marginLeft: '0.5rem' }}>{t('Enterprise Register home page')}</span>
          </a>
          <a
            href={`${process.env.REACT_APP_STATIC_URL!}${i18n.language === 'en' ? '/en' : ''}`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <span>{t('MANYAL')}</span>
          </a>
          <Link to={generatePath(STATIC_PAGE, { slug: 'privacy' })}>
            <span>{t('PROCESSING_PERSONAL_DATA')}</span>
          </Link>
          <Link to={generatePath(STATIC_PAGE, { slug: 'about' })}>
            <span>{t('ACCESSIBILITY_STATEMENT')}</span>
          </Link>
          <Link to={generatePath(STATIC_PAGE, { slug: 'terms' })}>
            <span>{t('Terms of use')}</span>
          </Link>
          <Link to={generatePath(HELPDESK_PAGE)}>
            <span>{t('HELPDESK')}</span>
          </Link>
          <Link to={generatePath(STATIC_PAGE, { slug: 'contacts' })}>
            <span>{t('Contacts')}</span>
          </Link>
          <Link to={generatePath(STATIC_PAGE, { slug: 'cookies' })}>
            <span>{t('Cookies')}</span>
          </Link>
        </nav>
        <div>
          <span className="footer-desc">
            {t('footer.span', { year: new Date().getFullYear() })}{' '}
          </span>
          <span>
            © {t('Enterprise Register')}, {new Date().getFullYear()}.v.{versionState}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
