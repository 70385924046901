import { createEffect } from 'effector';
import api from 'services/api';
import { RequestParams } from '../../../@types';
import { getClassifiersFX } from '../../classifiers/effects';

const HISTORY_PATH = '/api-profile/api/v1';

export const fetchHistoryFX = createEffect(async (params = {}) => {
  const response = await api.get(`${HISTORY_PATH}/user_actions_history`, { params });

  getClassifiersFX({ types: 'history_action_legal_entity,history_section' });

  return response.data;
});

export const fetchEntityHistoryFX = createEffect(
  async ({ entity_id, ...params }: RequestParams) => {
    const response = await api.get(
      `${HISTORY_PATH}/legal_entities/${entity_id}/user_actions_history`,
      {
        params,
      }
    );

    getClassifiersFX({ types: 'history_section,history_action' });

    return response.data;
  }
);
