import { Modal, SelectSkeleton } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router';
import {
  fetchExternalOrganizationsFX,
  fetchExternalSetOrganizationFX,
} from '../../store/user/effects';
import PortalModal from '../PortalModal';
import { RHFSelect } from '../rhf-fields/select/RHFSelect';
import { $userStore } from '../../store/user';
import { DropdownType } from '../../store/classifiers/interfaces';
import { parseJwt } from '../../utils';
import { USER_PROFILE } from '../../routes/constants';

export const ExternalUserModal = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [stateSelect, setStateSelect] = useState<DropdownType>();
  const isLoading = useStore(fetchExternalOrganizationsFX.pending);
  const token = localStorage.getItem('token');
  const tokenInf = token && parseJwt(token);
  const user = useStore($userStore);
  const history = useHistory();

  useEffect(() => {
    if (token && user?.has_partner_organizations && tokenInf?.mode !== 'external') {
      fetchExternalOrganizationsFX({});
      setOpenModal(true);
    }
  }, [token]);

  if (!openModal) {
    return null;
  }

  const handleYes = () => {
    stateSelect?.value && fetchExternalSetOrganizationFX({ uuid: stateSelect.value });
    setOpenModal(false);
    history.push(USER_PROFILE);
  };

  return (
    <PortalModal>
      <Modal
        size={'sm'}
        modalHeading={t('EXTERNAL_MODAL_TITLE')}
        onRequestSubmit={handleYes}
        primaryButtonText={t('YES')}
        primaryButtonDisabled={!stateSelect}
        secondaryButtonText={t('NO')}
        onSecondarySubmit={() => setOpenModal(false)}
        open
        onRequestClose={() => setOpenModal(false)}
        className="web-epak__modal organization"
      >
        {isLoading ? (
          <SelectSkeleton />
        ) : (
          <RHFSelect
            id="external_user_select"
            field={{ value: stateSelect, onChange: (e: any) => setStateSelect(e) }}
            label={<span>{t('EXTERNAL_USER_SELECT')}</span>}
            options={user?.external_organizations || []}
          />
        )}
      </Modal>
    </PortalModal>
  );
};
