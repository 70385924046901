import { createEvent } from 'effector';
import { RequestParams } from '../../../@types';
import { DropdownType } from '../../classifiers/interfaces';
import { DelegationsStore } from '../interfaces';

export const setDelegationsStateEV = createEvent<Partial<DelegationsStore>>();

export const setApplicationsTotalPriceEV = createEvent<string>();

export interface NewRequestParams extends RequestParams {
  select_permission?: DropdownType;
  select_status?: DropdownType;

  code?: string;
  permission_key?: string;
  valid_from?: string;
  valid_to?: string;
  autorized_person?: string;
  legal_name?: string;
  status?: string;
  reg_number?: string;
  identity_number?: string;
  email?: string;
  name?: string;
  valid_never_expire?: string;
}

export const createQueryParamsEV = createEvent<NewRequestParams | undefined>();

export const clearDelegationsQueryParamsEV = createEvent<NewRequestParams | undefined>();
