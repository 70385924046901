import { RequestParams } from '@types';
import { createEvent } from 'effector';

export interface NewRequestParams extends RequestParams {
  code?: string;
  permission_key?: string;
  valid_from?: string;
  valid_to?: string;
  autorized_person?: string;
  legal_name?: string;
  status?: string;
  reg_number?: string;
  identity_number?: string;
  email?: string;
  name?: string;
  valid_never_expire?: string;
}

export const createQueryParamsEV = createEvent<NewRequestParams | undefined>();

export const clearCommunicationQueryParamsEV = createEvent<NewRequestParams | undefined>();

export const clearCommunicationEV = createEvent<any>();
