import { ReactNode } from 'react';
import { FieldValues } from 'react-hook-form';
import Select, { Props } from 'react-select';

interface RHFSelectProps extends Props {
  field: FieldValues;
  id: string;
  label?: ReactNode | string;
}

export const RHFSelect: React.FC<RHFSelectProps> = ({ field, id, label, ...rest }) => (
  <div className="rhfs_wrap">
    {label && (
      <label htmlFor={id} className="bx--label">
        {label}
      </label>
    )}
    <Select
      id={id}
      {...rest}
      classNamePrefix="rhfs"
      value={field.value}
      onChange={field.onChange}
      placeholder={''}
      isClearable
    />
  </div>
);
