export const LOCAL_STORAGE = {
  LANG: 'language',
  TOKEN: 'token',
  TOKEN_KEY: 'token_key',
  UUID: 'uuid',
  USER_IP: 'User-Ip',
};

export const APP_NAME = 'web-epak';

export const defaultUrlParams = {
  sort_order: 'DESC',
  sort_field: 'id',
  limit: 10,
  page: 1,
};

export const tabsServices = [
  { key: 'services', title: 'SERVICES' },
  { key: 'data_entry', title: 'DATA_ENTRY' },
  { key: 'documents', title: 'DOCUMENTS' },
  { key: 'overview', title: 'OVERVIEW' },
  // { key: 'payment', title: 'PAYMENT' },
  { key: 'success', title: 'SUCCESS' },
];

export const AUTOSAVE_DEBOUNCE = 500;

export const MAX_CHILDS_DEPTH = 5;
