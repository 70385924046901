import { createEvent } from 'effector';
import { RequestParams } from '../../../@types';
import { DropdownType } from '../../classifiers/interfaces';
import { PaymentStore } from '../interfaces';

export const setPaymentStateEV = createEvent<Partial<PaymentStore>>();

export const setApplicationsTotalPriceEV = createEvent<string>();

export interface NewRequestParams extends RequestParams {
  application_number?: string;
  application_price?: string;
  payer_amount?: string;
  payment_date?: string;
  payer?: string;
  status?: string;
  select_status?: DropdownType;
}

export const createQueryParamsEV = createEvent<NewRequestParams | undefined>();
