import { createEffect, sample } from 'effector';
import api from 'services/api';
import { BasketSubmissionType } from 'store/basket/interfaces';
import { fetchCatalogEV } from '../events';

const PATH = 'api-profile/api/v1/catalog';

export const fetchCatalogFX = createEffect(async (params: any = {}) => {
  const { submission_type, entity_id, behalf_of_uuid, ...rest } = params;

  switch (submission_type) {
    case BasketSubmissionType.LEGAL_ENTITY:
      const responseLE = await api.get(`/api-profile/api/v1/legal_entities/${entity_id}/catalog`, {
        params: rest,
      });

      return { data: responseLE.data.data, params };

    case BasketSubmissionType.ON_BEHALF_OF_BRANCH:
      // const onBehafResponse = await api.get(`/${PATH}/${behalf_of_uuid}`, {
      //   params: rest,
      // });
      const onBehafResponse = await api.get(
        `/api-profile/api/v1/legal_entities/${entity_id}/catalog/${behalf_of_uuid}`,
        {
          params: rest,
        }
      );

      return { data: onBehafResponse.data.data, params };
    case BasketSubmissionType.ON_BEHALF_OF_ENTITY:
      const behalfOfEntityResponse = await api.get(`/${PATH}/${behalf_of_uuid}`, {
        params: rest,
      });

      return { data: behalfOfEntityResponse.data.data, params };

    default:
      const personResponse = await api.get(`/${PATH}`, {
        params: rest,
      });

      return { data: personResponse.data.data, params };
  }
});

sample({
  clock: fetchCatalogEV,
  target: fetchCatalogFX,
});
