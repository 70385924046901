import { Menu24 } from '@carbon/icons-react';
import { EE_SHOW_MOBILE_NAV } from 'components/mobile-menu/consts';
import { Navigation } from 'components/navigation/Navigation';
import { SelectProfile } from 'components/select-profile/SelectProfile';
import { Visible } from 'react-grid-system';
import { Emitter } from 'utils/event-emitter';
import './styles.scss';
import { useStore } from 'effector-react/effector-react.cjs';
import { $userStore } from '../../store/user';
import { useProfile } from '../../hooks/useProfile';

export const TopNavbar = () => {
  const user = useStore($userStore);
  const { data } = useProfile();
  const isInternalUser = data?.mode === 'internal';
  const isExternalUser = data?.mode === 'external';

  const handleBtnClick = () => {
    Emitter.emit(EE_SHOW_MOBILE_NAV);
  };

  return (
    <div className={user ? 'top-navbar' : 'top-navbar-mobile'}>
      {user && (
        <Visible lg xl xxl>
          <Navigation />
        </Visible>
      )}
      <Visible xs sm md>
        <button type="button" onClick={handleBtnClick}>
          <Menu24 />
        </button>
      </Visible>
      {user && !isInternalUser && !isExternalUser && <SelectProfile />}
    </div>
  );
};
