import { RequestParams } from '@types';
import { CatalogGroup, CatalogStore } from '../interfaces';

export const normalizePayload = (
  state: CatalogStore,
  payload: { data: CatalogGroup[]; params: RequestParams }
) => {
  const active_group = payload.params.active_group || 'changes';
  const groupIndex = payload.data.findIndex((gr: CatalogGroup) => gr.group === active_group);
  const merge = +(payload.params.offset || 0) !== 0 && !!state.groups.length;
  const dataGroup = payload.data[groupIndex];
  const stateGroupList = state.groups[groupIndex]?.services.list || [];
  const list = merge ? [...stateGroupList, ...dataGroup?.services.list] : dataGroup?.services.list;
  const groups = payload.data.map((group, index) =>
    index === groupIndex
      ? {
          ...group,
          ...dataGroup,
          services: {
            ...dataGroup.services,
            list,
          },
        }
      : group
  );

  return {
    ...state,
    groups,
  };
};
