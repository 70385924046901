import moment from 'moment';
import 'moment/min/locales.min';

export const asCurrency = (value: number, locale: string, currency: string) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);

export const asDate = (date: Date, locale: string) =>
  date.toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

export const asTime = (date: Date, locale: string) =>
  date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });

export const asRelativeTime = (date: Date, locale: string) => {
  moment.locale(locale);

  return moment(date).fromNow();
};

export const asColoredText = (text: string, colored: string) =>
  text.replace(colored, `<span>${colored}</span>`);

export const validateEmail = (email: string) => {
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  return re.test(String(email));
};

export const formatNumber = (value: string) => {
  const identity_number = value.slice(0, 6) + '-' + value.slice(6, 11);

  return identity_number;
};

export const formatNumberWithDependency = (dependency: string, reg_number: string) => {
  switch (dependency) {
    case 'NATURAL_PERSON':
      return reg_number?.slice(0, 6) + '-' + reg_number?.slice(6, 11);
    default:
      return reg_number;
  }
};
