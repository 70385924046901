import classNames from 'classnames';
import { Home24, UserFilled24 } from '@carbon/icons-react';
import { useStore } from 'effector-react';
import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ENTITIES } from 'routes/constants';
import { $legalEntitiesStore } from 'store/entities';
import { $userStore } from 'store/user';
import { Hidden } from 'react-grid-system';
import { setProfileTypeEV } from 'store/main/events';
import { ProfileTypeEnum } from 'store/main/interface';
import { clearApplicationsQueryParamsEV } from 'store/applications/events';
import { createQueryParamsEV, clearCommunicationQueryParamsEV } from 'store/communication/events';
import { clearDelegationsQueryParamsEV } from 'store/delegations/events';
import { clearHistoryQueryParamsEV } from 'store/history/events';
import { setAccountUuidEV } from 'store/user/events';

interface ScrollHeaderProps {
  refHeader: React.MutableRefObject<HTMLDivElement | null>;
}

export const ScrollHeader: FC<ScrollHeaderProps> = ({ refHeader }) => {
  const user = useStore($userStore);
  const history = useHistory();
  const { profile } = useStore($legalEntitiesStore);
  const selected_uuid = user?.accounts?.selected_uuid;
  const isNaturalPerson = !selected_uuid || selected_uuid === user?.accounts?.natural_person?.uuid;
  const entity = isNaturalPerson
    ? user?.accounts?.natural_person
    : user?.accounts?.legal_entities?.find((en: any) => en.uuid === selected_uuid);
  const name = isNaturalPerson ? entity?.name : `${user?.name} (${profile?.legal_entity_name})`;

  const handleHomeClick = () => {
    history.block()();

    if (localStorage.getItem('token')) {
      setAccountUuidEV(user?.accounts?.natural_person?.uuid || '');
      setProfileTypeEV(ProfileTypeEnum.PERSON);
      createQueryParamsEV(undefined);
      clearApplicationsQueryParamsEV(undefined);
      clearHistoryQueryParamsEV(undefined);
      clearDelegationsQueryParamsEV(undefined);
      clearCommunicationQueryParamsEV(undefined);
      history.push(ENTITIES.LIST);
    } else {
      history.push('/');
    }
  };

  return (
    <nav className="web-epak-header-mini" ref={refHeader}>
      <div className="web-epak-header__wrap">
        <Hidden xs>
          <div className="web-epak-header-mini__home">
            <Link
              to={localStorage.getItem('token') ? ENTITIES.LIST : '/'}
              onClick={handleHomeClick}
            >
              <a>
                <Home24 />
              </a>
            </Link>
            {process.env.REACT_APP_ENVIRONMENT_MODE !== 'Production' && (
              <div className={classNames('environment')}>
                {process.env.REACT_APP_ENVIRONMENT_MODE}
              </div>
            )}
          </div>
          {user && (
            <div className="web-epak-header-mini__block">
              <span>
                <UserFilled24 />
                {name}
              </span>
            </div>
          )}
        </Hidden>
      </div>
    </nav>
  );
};
