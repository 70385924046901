import { LOCAL_STORAGE } from 'constants/app';
import { Loading } from 'carbon-components-react';
import PortalModal from 'components/PortalModal';
import { useStore } from 'effector-react';
import { useQueryParams } from 'hooks/useQueryParams';
import { FC, useLayoutEffect } from 'react';
import { APPLICATIONS, CATALOG, ENTITIES, USER_PROFILE } from 'routes/constants';
import { $userStore } from 'store/user';
import { authenticateUserFX, fetchUserProfileFX } from 'store/user/effects';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseJwt } from 'utils';
import { fetchEntityProfileFX } from 'store/entities/effects';

export const WithAuthWaiting: FC = () => {
  const user = useStore($userStore);
  const [{ authSessionId, notaryToken, notaryTokenKey }] = useQueryParams();
  const history = useHistory();
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const token_key = localStorage.getItem(LOCAL_STORAGE.TOKEN_KEY);
  const language = localStorage.getItem(LOCAL_STORAGE.LANG);
  const userProfileLoading = useStore(fetchUserProfileFX.pending);
  const authLoading = useStore(authenticateUserFX.pending);
  const hasTokens = (!!token && !!token_key) || (!!notaryToken && !!notaryTokenKey);
  const { i18n } = useTranslation();
  const shouldRequestUser = hasTokens && !user;
  const tokenInf = parseJwt(token || '');

  const profileLoading = userProfileLoading;
  const isLoading = authLoading || profileLoading;

  useLayoutEffect(() => {
    if (authSessionId && !isLoading && !user && !notaryToken) {
      authenticateUserFX(authSessionId);
    }

    if (!authSessionId && shouldRequestUser) {
      if (notaryToken && notaryTokenKey) {
        localStorage.setItem(LOCAL_STORAGE.TOKEN, notaryToken);
        localStorage.setItem(LOCAL_STORAGE.TOKEN_KEY, notaryTokenKey);
      }

      fetchUserProfileFX({}).then(() => {
        if (tokenInf?.legal_entity_uuid) {
          fetchEntityProfileFX(tokenInf.legal_entity_uuid);
        }
      });
    }
  }, [authSessionId]);

  useLayoutEffect(() => {
    if (!isLoading && user && !user.need_profile_submit && !tokenInf.legal_entity_uuid) {
      i18n.changeLanguage(user.ui_lang || language || 'lv');

      if (user.mode === 'internal') {
        history.push(CATALOG.LIST);

        return;
      }

      const submissions = window.location.pathname.includes('/submissions');
      const payment_completed = window.location.pathname.includes('/payment-completed');
      const submit_form = window.location.pathname.includes('/submit-form');
      const application_submit_uuid = localStorage.getItem('application_submit_uuid');
      const signing_document = window.location.pathname.includes('signing-document');
      const show_shared_payment = window.location.pathname.includes('/show_shared_payment');

      if (
        !submissions &&
        !payment_completed &&
        !submit_form &&
        !signing_document &&
        !show_shared_payment
      ) {
        if (application_submit_uuid) {
          history.push(generatePath(APPLICATIONS.SUBMIT_FORM, { uuid: application_submit_uuid }));
          localStorage.removeItem('application_submit_uuid');
        } else {
          if (user?.accounts?.legal_entities_count > 0) {
            if (user.need_profile_submit || user.need_profile_review) {
              history.push(USER_PROFILE);
            } else {
              history.push(ENTITIES.LIST);
            }
          } else {
            history.push(CATALOG.LIST);
          }
        }
      }
    }
  }, [profileLoading]);

  if (isLoading) {
    return (
      <PortalModal>
        <Loading active />
      </PortalModal>
    );
  }

  return null;
};
