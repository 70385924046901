import { createStore } from 'effector';
import {
  createLegalDeligationFX,
  fetchDelegationsFX,
  fetchLegalDelegationsFX,
  revokeDeligationAccessFX,
  revokeLegalDeligationAccessFX,
  updateLegalDeligationFX,
} from './effects';
import { clearDelegationsQueryParamsEV, createQueryParamsEV } from './events';
import { DelegationsStore } from './interfaces';

const initialState = {
  person: { list: [], total: 0 },
  legal_entity: { list: [], total: 0, permissions: { can_edit: false } },
  queryParams: undefined,
  filter: undefined,
};

export const $delegationsStore = createStore<DelegationsStore>(initialState)
  .on(fetchDelegationsFX.doneData, (state, payload) => ({
    ...state,
    person: payload.data,
  }))
  .on(revokeDeligationAccessFX.doneData, (state, payload) => ({
    ...state,
    person: {
      ...state.person,
      list: state.person.list.map((del) =>
        del.uuid === payload.uuid ? { ...del, ...payload } : del
      ),
    },
  }))
  .on(fetchLegalDelegationsFX.doneData, (state, payload) => ({
    ...state,
    legal_entity: payload.data,
  }))
  .on(revokeLegalDeligationAccessFX.doneData, (state, payload) => ({
    ...state,
    legal_entity: {
      ...state.legal_entity,
      list: state.legal_entity.list.map((del) =>
        del.uuid === payload.uuid ? { ...del, ...payload } : del
      ),
    },
  }))
  .on(createLegalDeligationFX.doneData, (state, payload) => ({
    ...state,
    legal_entity: {
      ...state.legal_entity,
      list: [payload, ...state.legal_entity.list],
      total: state.legal_entity.total + 1,
    },
  }))
  .on(updateLegalDeligationFX.doneData, (state, payload) => ({
    ...state,
    legal_entity: {
      ...state.legal_entity,
      list: state.legal_entity.list.map((del) =>
        del.uuid === payload.uuid ? { ...del, ...payload } : del
      ),
    },
  }))
  // queryParams
  .on(createQueryParamsEV, (state, payload) => ({ ...state, queryParams: payload }))
  .on(clearDelegationsQueryParamsEV, (state, payload) => ({ ...state, queryParams: payload }));
