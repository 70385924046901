import { RequestParams } from '@types';
import { parse, stringify } from 'query-string';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

export type UseQueryParams = (
  defaultParams?: RequestParams
) => [RequestParams, (p: RequestParams, m?: boolean) => void];

export const useQueryParams: UseQueryParams = (defaultParams: RequestParams = {}) => {
  const history = useHistory();
  const { search } = window.location;
  const query = useMemo(() => parse(search), [search]);

  const setQuery = (newParams: RequestParams, merge = true) => {
    const newQuery = merge ? { ...query, ...newParams } : newParams;
    const search = stringify(newQuery);

    history.push({ pathname: window.location.pathname, search, state: history.location.state });
  };

  useEffect(() => {
    if (defaultParams) {
      setQuery(defaultParams);
    }
  }, [JSON.stringify(defaultParams)]);

  return [query, setQuery];
};
