import { createEffect, createEvent } from 'effector';
import api from 'services/api';
import { RequestParams } from '@types';
import { Dependent } from '../interfaces';

const DEPENDENTS_PATH = 'api-profile/api/v1/legal_entities';

export const fetchDependentsFX = createEffect(async ({ entity_id, ...params }: RequestParams) => {
  const response = await api.get(`${DEPENDENTS_PATH}/${entity_id}/branches`, {
    params,
  });

  return response.data;
});

export const update = createEvent<Dependent>();
