import { createEffect } from 'effector';
import api from 'services/api';
import { EffectCallbacks } from 'store/shared/effect-callbacks';

const PATH_COMM = 'api-applications/api/v1/communications';
const PATH_APP = 'api-applications/api/v1/applications';

const CommunicationCallbacks = new EffectCallbacks(`${PATH_COMM}`);

export const getCommunicationsFX = createEffect(CommunicationCallbacks.fetchList);

export const getCommunicationFX = createEffect(async (msg_uuid: any) => {
  const response = await api.get(`${PATH_COMM}/${msg_uuid}`);

  return response.data;
});

export const createCommunicationFX = createEffect(
  async <Module>({ payload }: { payload: Module }) => {
    const response = await api.post(`${PATH_COMM}`, payload);

    return response.data;
  }
);

export const editCommunicationFX = createEffect(
  async <Module>({ message_uuid, payload }: { message_uuid: string; payload: Module }) => {
    const response = await api.patch(`${PATH_COMM}/${message_uuid}/save`, payload);

    return response.data;
  }
);

export const sendMessageFX = createEffect(
  async <Module>({ message_uuid, payload }: { message_uuid: string; payload: Module }) => {
    const response = await api.post(`${PATH_COMM}/send/${message_uuid}`, payload);

    return response.data;
  }
);

export const getRecipientsFX = createEffect(async ({ params }: { params?: any }) => {
  const response = await api.get(`${PATH_COMM}/recipients`, { params });

  return response.data;
});

export const getAppNumbersFX = createEffect(async ({ params }: { params?: any }) => {
  const response = await api.get(`${PATH_COMM}/application_numbers`, { params });

  return response.data;
});

export const getEntitiesFX = createEffect(async ({ params }: { params?: any }) => {
  const response = await api.get(`${PATH_COMM}/entities`, { params });

  return response.data;
});

export const getAppCommunicationFX = createEffect(async (application_uuid: string) => {
  const response = await api.get(`${PATH_COMM}/application_messages/${application_uuid}`);

  return response.data;
});

export const getDraftMessageFX = createEffect(async (application_uuid: string) => {
  const response = await api.get(`${PATH_COMM}/draft/${application_uuid}`);

  return response.data;
});

export const markAsReadFX = createEffect(async (message_uuid: string) => {
  const response = await api.post(`${PATH_COMM}/mark_as_read/${message_uuid}`);

  return response.data;
});

/* download additional file */
export const downloadAdditionalDocsFX = createEffect(
  async ({ app_uuid, file }: { app_uuid: string; file: any }) => {
    const resp = await api.get(`${PATH_APP}/${app_uuid}/additional_documents/${file.uuid}`, {
      responseType: 'blob',
    });

    if (resp.status === 200) {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.file_name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return 'success';
    } else {
      return resp.statusText;
    }
  }
);

// delete message attachment file
export const deleteMessageDocFX = createEffect(
  async ({
    app_uuid,
    file_uuid,
    message_uuid,
  }: {
    app_uuid: string;
    file_uuid: string;
    message_uuid: string;
  }) => {
    const resp = await api.delete(
      `${PATH_APP}/${app_uuid}/message_documents/${file_uuid}/${message_uuid}`
    );

    return resp.data?.data;
  }
);

// upload additional doc
export const uploadMessageDocsFX = createEffect(
  async ({
    app_uuid,
    file,
    message_uuid,
  }: {
    app_uuid: string;
    file: File;
    message_uuid?: string;
  }) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      `${PATH_APP}/${app_uuid}/message_documents${message_uuid ? '/' + message_uuid : ''}`,
      formData,
      {
        headers: {
          'Content-Type': file.type,
        },
      }
    );

    return response.data?.data;
  }
);
