import { createStore } from 'effector';
import {
  updateEntityContactFX,
  deleteEntityContactFX,
  fetchEntityProfileFX,
  fetchLegalEntitiesFX,
  validateEntityContactFX,
} from './effects';
import { setEntityProfile } from './events';
import { EntitiesStore } from './interfaces';

export const $legalEntitiesStore = createStore<EntitiesStore>({ list: [], profile: null });

$legalEntitiesStore
  .on(fetchLegalEntitiesFX.doneData, (_, payload) => ({
    list: payload.data,
    profile: null,
  }))
  .on(fetchEntityProfileFX.doneData, (state, payload) => ({
    ...state,
    profile: payload.data,
  }))
  .on(deleteEntityContactFX.doneData, (state, payload) => ({
    ...state,
    profile: {
      ...state.profile,
      ...payload.data,
    },
  }))
  .on(validateEntityContactFX.doneData, (state, payload) => ({
    ...state,
    profile: {
      ...state.profile,
      ...payload,
    },
  }))
  .on(updateEntityContactFX.doneData, (state, payload) => ({
    ...state,
    profile: {
      ...state.profile,
      ...payload.data,
    },
  }))
  .on(setEntityProfile, (state, payload) => ({
    ...state,
    profile: {
      ...state.profile,
      ...payload,
    },
  }));
