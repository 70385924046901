import { TextInput, TextInputProps } from 'carbon-components-react';
import { BaseSyntheticEvent, FC, Ref } from 'react';
import { FieldValues } from 'react-hook-form';

interface RHFInputProps extends TextInputProps {
  field: FieldValues;
  id: string;
  labelText: string | NonNullable<React.ReactNode>;
  inputRef?: Ref<HTMLInputElement>;
}

export const RHFInput: FC<RHFInputProps> = ({ field, id, pattern, inputRef, ...rest }) => {
  const handleChange = (e: BaseSyntheticEvent) => {
    const { value } = e.target;

    if (pattern && !!value) {
      const regex = new RegExp(pattern);

      if (regex.test(value)) {
        field.onChange(e);
      }
    } else {
      field.onChange(e);
    }
  };

  return (
    <TextInput
      id={id}
      pattern={pattern}
      {...rest}
      onChange={handleChange}
      value={field.value || ''}
      ref={inputRef}
      autoComplete="off"
    />
  );
};
