import { createStore } from 'effector';
import { Message } from 'types/communication/message';
import {
  clearCommunicationEV,
  clearCommunicationQueryParamsEV,
  createQueryParamsEV,
} from './events';
import {
  getAppNumbersFX,
  getCommunicationFX,
  getCommunicationsFX,
  getEntitiesFX,
  getRecipientsFX,
  getAppCommunicationFX,
  getDraftMessageFX,
  sendMessageFX,
  markAsReadFX,
  uploadMessageDocsFX,
  deleteMessageDocFX,
} from './effects';

export interface CommunicationStore {
  communications: any[];
  communicationsTotal: number;
  communicationDetail: any;
  app_numbers: any[];
  entities: any[];
  recipients: any[];
  appCommunication: any[];
  currentDraft: Message | null;
  queryParams?: any;
}

export const $communicationStore = createStore<CommunicationStore>({
  communications: [],
  communicationsTotal: 0,
  communicationDetail: {},
  app_numbers: [],
  entities: [],
  recipients: [],
  appCommunication: [],
  currentDraft: null,
})
  .on(getCommunicationsFX.doneData, (state, payload) => ({
    ...state,
    communications: payload.data?.list,
    communicationsTotal: payload.data?.total,
  }))
  .on(getCommunicationFX.doneData, (state, payload) => ({
    ...state,
    communicationDetail: payload.data,
  }))
  .on(clearCommunicationEV, (state) => ({
    ...state,
    communicationDetail: {},
  }))
  .on(getRecipientsFX.doneData, (state, payload) => ({
    ...state,
    recipients: payload.data,
  }))
  .on(getAppNumbersFX.doneData, (state, payload) => ({
    ...state,
    app_numbers: payload.data,
  }))
  .on(getEntitiesFX.doneData, (state, payload) => ({
    ...state,
    entities: payload.data,
  })) // queryParams
  .on(getAppCommunicationFX.doneData, (state, payload) => ({
    ...state,
    appCommunication: payload.data.list,
  }))
  .on(getDraftMessageFX.doneData, (state, payload) => ({
    ...state,
    currentDraft: payload.data,
  }))
  .on(markAsReadFX.doneData, (state, payload) => ({
    ...state,
    communications: state.communications.map((message: Message) =>
      message.uuid === payload.data.uuid ? payload.data : message
    ),
  }))
  .on(createQueryParamsEV, (state, payload) => ({ ...state, queryParams: payload }))
  .on(clearCommunicationQueryParamsEV, (state, payload) => ({ ...state, queryParams: payload }))
  .on(sendMessageFX.doneData, (state, payload) => ({
    ...state,
    currentDraft: null,
    communications: [...state.communications, payload.data],
  }))
  .on(uploadMessageDocsFX.doneData, (state, payload) => ({
    ...state,
    currentDraft: payload.currentDraft,
  }))
  .on(deleteMessageDocFX.doneData, (state, payload) => ({
    ...state,
    currentDraft: payload.currentDraft,
  }));
