import { UserAvatarFilled20 } from '@carbon/icons-react';
import { Dropdown } from 'carbon-components-react';
import { useStore } from 'effector-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CATALOG, ENTITIES, USER_PROFILE } from 'routes/constants';
import { fetchEntityProfileFX } from 'store/entities/effects';
import { setProfileTypeEV } from 'store/main/events';
import { ProfileTypeEnum } from 'store/main/interface';
import { $userStore } from 'store/user';
import { setAccountUuidEV } from 'store/user/events';
import { clearCommunicationQueryParamsEV } from 'store/communication/events';
import './styles.scss';
import { createQueryParamsEV } from '../../store/payment/events';
import { clearApplicationsQueryParamsEV } from '../../store/applications/events';
import { clearHistoryQueryParamsEV } from '../../store/history/events';
import { clearDelegationsQueryParamsEV } from '../../store/delegations/events';

export const SelectProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useStore($userStore);
  const accounts = user?.accounts;
  const selectedEntityId = user?.accounts?.selected_uuid;
  const person = user?.accounts?.natural_person;

  const [dropdownItems, dropdownActiveIndex] = useMemo(() => {
    if (accounts?.legal_entities_count === 0) {
      return [[], 0];
    }

    const options = [
      { name: person?.name, uuid: person?.uuid },
      ...(accounts?.legal_entities || []),
      { name: 'All legal entities', uuid: person?.uuid },
    ];
    const submit_form = window.location.pathname.includes('/submit-form');
    const payment_completed = window.location.pathname.includes('/payment-completed');
    const signing_document = window.location.pathname.includes('/signing-document');
    const LOCAL_STORAGE_UUID = localStorage.getItem('uuid');
    const personId = user?.accounts?.natural_person?.uuid;

    const selectedOptionIndex =
      submit_form || payment_completed || signing_document
        ? options.findIndex((op) => op.uuid === LOCAL_STORAGE_UUID)
        : selectedEntityId
        ? options.findIndex((op) => op.uuid === selectedEntityId)
        : 0;

    if ((submit_form || payment_completed || signing_document) && LOCAL_STORAGE_UUID) {
      setAccountUuidEV(LOCAL_STORAGE_UUID);
      setProfileTypeEV(ProfileTypeEnum.LEGAL_ENTITY);

      if (personId !== selectedEntityId) {
        fetchEntityProfileFX(LOCAL_STORAGE_UUID)
          .then((res) => {
            console.log('res =', res);
          })
          .catch((error) => {
            console.log('error =', error);
          });
      }
    }

    return [options, selectedOptionIndex];
  }, [accounts?.legal_entities_count, selectedEntityId]);

  const clearFiltersQueryParams = () => {
    createQueryParamsEV(undefined);
    clearApplicationsQueryParamsEV(undefined);
    clearHistoryQueryParamsEV(undefined);
    clearDelegationsQueryParamsEV(undefined);
    clearCommunicationQueryParamsEV(undefined);
  };

  const handleChangeAccount = ({
    selectedItem,
  }: {
    selectedItem: { name: string; uuid: string };
  }) => {
    setAccountUuidEV(selectedItem.uuid);

    if (person?.uuid === selectedItem.uuid) {
      history.block()();
      setProfileTypeEV(ProfileTypeEnum.PERSON);
      clearFiltersQueryParams();
      history.push(ENTITIES.LIST);
    } else {
      setProfileTypeEV(ProfileTypeEnum.LEGAL_ENTITY);
      clearFiltersQueryParams();
      fetchEntityProfileFX(selectedItem.uuid)
        .then((res) => {
          res.data.need_profile_submit || res.data.need_profile_review
            ? history.push(USER_PROFILE)
            : history.push(CATALOG.LIST);
        })
        .catch((error) => {
          console.log('error =', error);
        });
    }
  };

  if (!accounts?.legal_entities_count) {
    return null;
  }

  const listBoxMenuIconTranslationIds = {
    'close.menu': t('CLOSE_MENU'),
    'open.menu': t('OPEN_MENU'),
  };

  return (
    <div className="select-profile">
      <Dropdown
        id="select-profile"
        titleText={<UserAvatarFilled20 className="user_icon" />}
        label=""
        size="lg"
        type="inline"
        items={dropdownItems}
        selectedItem={dropdownItems[dropdownActiveIndex]}
        itemToString={(item) => t(item.name!)}
        onChange={handleChangeAccount}
        translateWithId={(id) => listBoxMenuIconTranslationIds[id]}
      />
    </div>
  );
};
