import { createStore } from 'effector';
import { fetchHistoryFX, fetchEntityHistoryFX } from './effects';
import { clearHistoryQueryParamsEV, createQueryParamsEV } from './events';
import { HistoryStore } from './interfaces';

export const $historyStore = createStore<HistoryStore>({
  list: [],
  total: 0,
  queryParams: undefined,
});

$historyStore
  .on(fetchHistoryFX.doneData, (state, payload) => ({
    ...state,
    list: payload.data.list,
    total: payload.data.total,
  }))
  .on(fetchEntityHistoryFX.doneData, (state, payload) => ({
    ...state,
    list: payload.data.list,
    total: payload.data.total,
  }))
  // queryParams
  .on(createQueryParamsEV, (state, payload) => ({ ...state, queryParams: payload }))
  .on(clearHistoryQueryParamsEV, (state, payload) => ({ ...state, queryParams: payload }));
