import { createStore } from 'effector';
import { clearSelectedBehalfEV } from 'store/shared/events';
import { fetchDependentsFX, update } from './effects';
import { DependentsStore } from './interfaces';

export const $dependentsStore = createStore<DependentsStore>({ list: [], single: undefined });

$dependentsStore
  .on(fetchDependentsFX.doneData, (state, payload) => ({
    ...state,
    list: payload.data,
  }))
  .on(update, (state, payload) => ({
    ...state,
    single: payload,
  }))
  .on(clearSelectedBehalfEV, (state) => ({ ...state, single: undefined }));
